@import './colors';
@import './size';
@import './fonts';

.normal-button {
	padding-top: 10px !important;
	padding-bottom: 10px !important;
	width: unset;
	height: unset;
	font: normal normal 600 16px $Roboto;
	border-radius: 8px !important;
	border: none !important;
	box-shadow: unset !important;
	@media (max-width: $breakpoint-md) {
		font-size: 12px !important;
		padding-top: 4px !important;
		padding-bottom: 4px !important;
	}
}

.primary-button {
	background-color: $yellow !important;
	color: $white !important;
	&:hover {
		background-color: $yellow;
	}
}

.disabled-button {
	background-color: $light-gray !important;
	color: $white !important;
}

.icon-button {
	font-size: 24px;
	@media (max-width: $breakpoint-md) {
		font-size: 16px !important;
	}
}