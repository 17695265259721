@import '../../../../styles/colors';
@import '../../../../styles/size';
@import '../../../../styles/fonts';

.my-orka-student-content-container {
	background: $sub-background-color;
	padding: 110px 5% 80px 5%;
	min-height: 100vh;

	.section-title {
		font-size: 18px;
		color: $main-text-color;
		font-weight: 600;
	}

	.courses-empty-container {
		background-color: $white;
		border-radius: 16px;
		padding: 16px;
		margin-top: 16px;

		.courses-empty-text {
			color: $light-gray;
			font-weight: 600;
		}
	}

	.course-item {
		background-color: $white;
		border-radius: 16px;
		padding: 16px;
		margin-top: 16px;
		cursor: pointer;
		user-select: none;

		.course-name {
			font-size: 18px;
			font-weight: 600;
			color: $light-gray;
		}

		.summary-information-container {
			display: flex;
			flex-wrap: wrap;
			column-gap: 16px;
		}

		.summary-title {
			color: $main-text-color;
		}

		.summary-value {
			color: $main-text-color;
			font-size: 18px;
			font-weight: 600;
		}
	}

	.course-item.selected {
		background-color: $background-color-green;

		.course-name {
			color: $gray-blue;
		}

		.summary-title {
			color: $white;
		}

		.summary-value {
			color: $white;
		}
	}

	.courses-button-container {
		display: flex;
		column-gap: 16px;
		margin-top: 16px;
	}

	.show-more-text {
		color: $main-text-color;
		cursor: pointer;
		user-select: none;
	}

	.course-summary-container {
		background-color: $white;
		border-radius: 16px;
		padding: 16px;
		margin-top: 16px;

		.hi-there {
			font-size: 18px;
			font-weight: 600;
			color: $sub-text-color;
		}
		
		.user-name {
			font-size: 48px;
			font-weight: 700;
			color: $main-text-color;
		}

		.map {
			width: 100%;
			aspect-ratio: 1571 / 685;
			border-radius: 8px;
		}

		.map-view {
			width: 100%;
			border-radius: 8px;
		}

		.course-information {
			margin-top: 16px;
		}

		.class-title {
			color: $yellow;
			font-size: 18px;
			font-weight: 600;
		}

		.class-description {
			color: $light-gray;
		}

		.class-summary {
			color: $gray-blue;
		}

		.enroll-title {
			color: $main-text-color;
		}

		.summary-number-container {
			display: flex;
			align-items: center;
			justify-content: center;
			height: 60px;
			border-radius: 8px;
			background-color: $sub-background-color;
		}

		.summary-number {
			color: $light-gray;
			font-size: 36px;
		}

		.summary-number-unit {
			color: $light-gray;
		}

		.summary-number-title {
			font-size: 14px;
			color: $light-gray;
		}

		.helper-document {
			display: flex;
			background-color: $sub-background-color;
			margin-top: 8px;
			padding: 8px;
			align-items: center;
			border-radius: 8px;
			cursor: pointer;
			user-select: none;

			&:hover {
				opacity: 0.8;
			}

			&:active {
				opacity: 0.6;
			}
		}

		.film {
			width: 100%;
			border-radius: 8px;
		}

		.file-name {
			text-decoration: underline;
		}
	}

	.course-schedule-container {
		.marked-date {
			background-color: $background-color-green;
		}

		.calendar-note {
			display: flex;
			align-items: center;
			column-gap: 8px;
		}

		.color-square {
			width: 10px;
			height: 10px;
			background-color: $background-color-green;
		}

		.note-text {
			font-size: 14px;
			color: $light-gray;
		}

		.classes-text {
			color: $main-text-color;
			margin-top: 16px;
			margin-left: 40px;
		}

		.no-classes-or-events-text {
			font-size: 14px;
			color: $light-gray;
			margin-left: 40px;
		}

		.booking-container {
			display: flex;
			align-items: center;
		}

		.icon-container {
			width: 40px;
			display: flex;
			align-items: center;
			justify-content: center;
		}

		.check-icon {
			color: green;
		}

		.booking-item {
			flex: 1;
			margin-top: 16px;
			border-radius: 8px;
			display: flex;
			padding: 16px;
			background-color: $sub-background-color;
			column-gap: 8px;
			align-items: center;
			cursor: pointer;
			user-select: none;

			.booking-item-date {
				display: flex;
				flex-direction: column;
				align-items: center;
			}
	
			.date-text {
				line-height: 1;
				color: $yellow;
			}
	
			.month-text {
				font-size: 14px;
				color: $main-text-color;
			}

			.lesson-title {
				color: $light-gray;
				word-break: break-word;
			}
		}

		.booking-item.selected {
			background-color: $background-color-green;

			.month-text {
				color: $gray-blue;
			}

			.lesson-title {
				color: $dark-gray;
			}
		}

		.booking-item-lesson-info {
			display: flex;
			flex-direction: column;
		}
	}

	.browse-class-text {
		color: $main-text-color;
		text-decoration: underline !important;
	}
}

@media (max-width: $breakpoint-md) {
	.my-orka-student-content-container {
		padding: 60px 5% 80px 5%;

		.section-title {
			font-size: 14px;
		}

		.courses-empty-container {
			border-radius: 8px;
			padding: 8px;
			margin-top: 8px;
		}

		.course-item {
			border-radius: 8px;
			padding: 8px;
			margin-top: 8px;

			.course-name {
				font-size: 14px;
			}

			.summary-information-container {
				column-gap: 8px;
			}

			.summary-value {
				font-size: 14px;
			}
		}

		.courses-button-container {
			column-gap: 8px;
			margin-top: 8px;
		}

		.course-summary-container {
			border-radius: 8px;
			padding: 8px;
			margin-top: 8px;

			.hi-there {
				font-size: 14px;
			}
	
			.user-name {
				font-size: 36px;
			}

			.course-information {
				margin-top: 12px;
			}

			.class-title {
				font-size: 14px;
			}

			.summary-number {
				font-size: 24px;
			}

			.summary-number-title {
				font-size: 10px;
			}
		}

		.course-schedule-container {
			.note-text {
				font-size: 10px;
			}

			.no-classes-or-events-text {
				font-size: 10px;
			}

			.month-text {
				font-size: 10px;
			}
		}
	}
}