@import '../../../../styles/colors';
@import '../../../../styles/size';
@import '../../../../styles/fonts';

.stripe-container {
	.stripe-content-container {
		background-color: $background-color-green;
		margin: 5vh 10vw 0;
		border-radius: 40px;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
		padding: 5vh 5vw;
		gap: 2.5vh;
	}

	.title {
		font-size: 40px !important;
		color: $green !important;
		font-family: $Montserrat !important;
		font-weight: 900;
	}

	.logo-image {
		width: 50%;
		color: rgba(255, 255, 255, 0.61);
	}

	.button-group {
		display: flex;
		flex-direction: column;
		.MuiButton-containedSizeMedium.create-stripe-account-button {
			background-color: $green;
			text-transform: none;
			color: $white;
		}
		.MuiButton-containedSizeMedium.maybe-later-button {
			background-color: transparent;
			color: $green;
			text-transform: none;
			text-decoration: underline;
		}
	}
}

@media (max-width: $breakpoint-md) {
	.stripe-container {
		.stripe-content-container {
			margin: 0;
			border-radius: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			padding: 5vh 5vw;
			height: 100vh;
		}
	
		.title {
			font-size: 25px !important;
		}
	}
}