@import '../../../styles/colors';
@import '../../../styles/size';
@import '../../../styles/fonts';

.welcome-container {
	background: $sub-background-color;
	padding: calc(max((100vh - 600px) / 2, 110px)) 15%;
	min-height: 100vh;

	.welcome-content-container {
		background-color: $background-color-green;
		border-radius: 32px;
		height: 600px;
		overflow: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 32px;
	}

	.logo {
		color: rgba(255, 255, 255, 0.61);
	  width: 50%;
	}

	.welcome-text {
		color: $gray-blue;
		font-size: 32px;
		text-transform: uppercase;
		font-weight: 600;
	}
}

@media (max-width: $breakpoint-lg) {
	.welcome-container {
		padding: 80px 5% 80px 5%;
	}
}

@media (max-width: $breakpoint-md) {
	.welcome-container {
		background-color: $background-color-green;
		padding: 60px 5% 80px 5%;

		.logo {
			width: 80%;
		}

		.welcome-content-container {
			background-color: unset;
			border-radius: unset;
			flex-direction: column;
			justify-content: unset;
			align-items: center;
			gap: 24px;
		}
	}

}