@import '../../../styles/colors';
@import '../../../styles/size';

.common-input {
	width: 100%;

	.invalid .MuiOutlinedInput-root {
		& fieldset {
			border-color: $red;
		}
	}

	.invalid-message {
		text-align: left;
		font-size: 14px;
		margin-top: 4px;
		color: $red;
	}

	.valid-message {
		text-align: left;
		font-size: 14px;
		margin-top: 4px;
		color: $gray-blue;
	}

	.MuiOutlinedInput-input {
		padding: 8px 16px;
		font-size: 16px;
	}
}

@media (max-width: $breakpoint-md) {
	.common-input {
		.invalid-message {
			font-size: 10px;
			margin-top: 2px;
		}

		.valid-message {
			font-size: 10px;
			margin-top: 2px;
		}

		.MuiOutlinedInput-input {
			padding: 6px 12px;
			font-size: 12px;
		}
	}
}