@import '../../../styles/size';
@import '../../../styles/colors';

.view-media-modal-container {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;

	.transform-wrapper {
		height: 100%;
		width: 100%;
	}

	.image-container {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.video-container {
		height: 100%;
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
	}

	.image {
		max-width: 100%;
		max-height: 100%;
	}

	.change-media-button {
		font-size: 32px;
		position: absolute;
		z-index: 1;
		color: $yellow;
	}

	.change-media-button-left {
		left: 0px;
	}

	.change-media-button-right {
		right: 0px;
	}

	.change-media-button-close-button {
		right: 0px;
		top: 0px;
	}
}

@media (max-width: $breakpoint-md) {
	.view-media-modal-container {
		.change-media-button {
			font-size: 24px;
		}
	}
}