@import '../../../../styles/colors';
@import '../../../../styles/size';

.chat-content-container {
	background: $sub-background-color;
	padding: 110px 0 0 0;
	min-height: 100vh;

	.chat-item {
		padding: 8px 16px;
		cursor: pointer;
		user-select: none;

		&:hover {
			opacity: 0.8;
		}

		&:active {
			opacity: 0.6;
		}
	}
	.chat-item.selected {
		background-color: $background-color-green;
	}

	.recent-message {
		white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
		color: $light-gray;
	}

	.chat-list {
		height: calc(100vh - 110px);
		border-right: 1px $light-gray solid;
		overflow-y: auto;
	}

	.chat-box {
		height: calc(100vh - 110px);

		.chat-info {
			display: flex;
			align-items: center;
			height: 60px;
			position: fixed;
			border-bottom: 1px $light-gray solid;
			width: 100%;

			.opponent-name {
				font-size: 18px;
				font-weight: 600;
				white-space: nowrap;
		    overflow: hidden;
		    text-overflow: ellipsis;
			}
		}

		.messages-box {
			margin-top: 60px;
			height: calc(100vh - 230px);
			overflow-y: auto;
			padding: 16px;

			.message-item {
				background-color: $white;
				width: fit-content;
				max-width: 80%;
				padding: 8px 16px;
				margin-top: 16px;
				border-radius: 8px;

				.sent-time {
					color: $light-gray;
					font-size: 14px;
					margin-left: 8px;
				}
			}
		}

		.input-box {
			height: 60px;
			border-top: 1px solid $light-gray;
			display: flex;
			align-items: center;
			padding: 0 16px;
			gap: 16px;

			.send-icon {
				color: $yellow;
			}

			.send-icon.disabled {
				color: $light-gray;
			}
		}
	}
}

@media (max-width: $breakpoint-md) {
	.chat-content-container {
		padding: 60px 0 0 0;

		.chat-list-desktop {
			height: calc(100vh - 60px);
			border-right: 0;
		}

		.chat-box {
			height: calc(100vh - 60px);

			.chat-info {
				.opponent-name {
					font-size: 14px;
				}
			}

			.message-box {
				.message-item {
					margin-top: 8px;

					.sent-time {
						font-size: 10px;
					}
				}
			}
		}
	}
}