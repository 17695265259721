@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/size';

.my-orka-schedule {
	overflow: auto;

	.select-calendar-type-option {
		font: normal normal normal 16px $Roboto;
		color: #6d9fac;
		opacity: 0.68;
		margin: 0 5px;
		cursor: pointer;
	}
	
	.select-calendar-type-option.bold {
		font: normal normal bold 16px $Roboto;
	}

	.MuiToolbar-root {
		padding-left: 0;
		padding-right: 0;

		.MuiIconButton-root {
			color: #6d9fac;
		}

		.MuiButton-root {
			color: #6d9fac;
		}
	}

	.MainLayout-header {
		z-index: 3 !important;
	}
	
	.MainLayout-leftPanel {
		z-index: 2 !important;
	}
}

.tooltip-header {
	display: flex;
	justify-content: flex-end;
}

.tooltip-content {
	padding: 16px;

	.icon-container {
		display: flex;
		align-items: center;
	}

	.text-container {
		display: flex;
		align-items: center;

		.text {
			word-break: break-word;
		}
	}
}

.appointment-text {
	font-size: 12px !important;
	word-break: break-word;
	color: $white;
}

@media (max-width: $breakpoint-md) {
	.my-orka-schedule {
		.select-calendar-type-option {
			font: normal normal normal 12px $Roboto;
		}

		.select-calendar-type-option.bold {
			font: normal normal bold 12px $Roboto;
		}
	}
}