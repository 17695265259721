@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/size';

.owned-course-container {
	background: $sub-background-color;
	padding: 100px 5% 80px 5%;
	min-height: 100vh;

	.course-aviation-story {
		margin-right: 2.5vw;

		.course-aviation-story-subtitle {
			color: $green;
		}

		.media-container {
			position: relative;
			height: 200px;
			border-radius: 8px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			border: $gray-blue solid 1px;
			margin-top: 8px;
			margin-right: 8px;
		}

		.MuiCircularProgress-svg {
			width: 60px;
			height: 60px;
			transform: translate(-10px, -10px);
		}

		.MuiCircularProgress-circle {
			color: $gray-blue;
			width: 60px;
			height: 60px;
		}

		.media {
			height: 198px;
			width: 100%;
			object-fit: cover;
			border-radius: 8px;
			user-select: none;
			-drag: none;
			-moz-user-select: none;
			-webkit-user-drag: none;
			-webkit-user-select: none;
		}
	}

	.course-basic-info-container {
		margin-right: 2.5vw;

		.normal-label {
			color: $main-text-color;
			font-size: 18px;
			font-weight: 600;
			margin-top: 15px;
		}

		.basic-info-value {
			text-align: justify;
		}

		.map {
			width: 100%;
			height: 24vh;
			border-radius: 16px;
			margin-top: 8px;
		}
	}

	.course-lessons-container {
		margin-left: 2.5vw;

		.lesson-title {
			flex: 1;
			word-break: break-word;
		}

		.course-lesson-item-container {
			border-radius: 8px;
			margin-top: 16px;

			.MuiAccordion-root {
				background-color: $white;
			}

			.MuiAccordionSummary-root,
			.MuiAccordionSummary-root.Mui-expanded {
				min-height: unset;
				padding-top: 12px;
				padding-bottom: 12px;
			}

			.MuiAccordionSummary-content,
			.MuiAccordionSummary-content.Mui-expanded {
				margin: 0px;
			}

			.MuiAccordionDetails-root {
				padding: 12px 16px !important;
			}
		}
	}

	.yellow-label {
		color: $yellow;
		font-size: 18px;
		font-weight: 600;
		margin-top: 15px;
	}
}

@media (max-width: $breakpoint-md) {
	.owned-course-container {
		padding-bottom: 50px;

		.course-aviation-story {
			.media-container {
				height: 150px;
				margin-top: 6px;
				margin-right: 6px;
			}

			.MuiCircularProgress-svg {
				width: 40px;
				height: 40px;
				transform: unset;
			}

			.MuiCircularProgress-circle {
				width: 40px;
				height: 40px;
			}

			.media {
				height: 148px;
			}
		}

		.course-basic-info-container {
			.normal-label {
				font-size: 14px;
			}
		}

		.course-lessons-container {
			.course-lesson-item-container {

				.MuiAccordionSummary-root,
				.MuiAccordionSummary-root.Mui-expanded {
					padding-top: 8px;
					padding-bottom: 8px;
				}
			}
		}

		.yellow-label {
			font-size: 14px;
		}
	}
}

@media (min-width: $breakpoint-xl) {
	.owned-course-container {
		margin: auto;
		width: 80%;
	}
}