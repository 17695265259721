@import '../../../styles/colors';
@import '../../../styles/size';
@import '../../../styles/fonts';

.select-sign-up-role-container {
	background: $sub-background-color;
	padding: calc(max((100vh - 600px) / 2, 110px)) 15%;
	min-height: 100vh;

	.select-sign-up-role-content-container {
		background-color: $white;
		border-radius: 32px;
		height: 600px;
		overflow: auto;
	}

	.select-sign-up-role-box {
		height: 600px;
	}

	.left-column {
		background-color: $background-color-green;
		border-radius: 32px 0 0 32px;
		display: flex;
		padding: 20px;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.sign-up-text {
			font-size: 32px;
			color: $gray-blue;
			font-weight: 600;
		}
	}

	.right-column {
		border-radius: 0 32px 32px 0;
		padding: 20px;

		.back-icon {
			color: $gray-blue;
		}

		.right-column-content-container {
			display: flex;
			flex-direction: column;
			gap: 32px;
			align-items: center;
			justify-content: center;
			height: 100%;
			margin-top: -40px;
		}

		.welcome-text {
			font-size: 32px;
			color: $gray-blue;
			font-weight: 600;
			text-align: center;
		}

		.sign-up-sub-text {
			color: $light-gray;
			text-align: center;
		}

		.select-role-button-group {
			display: flex;
			gap: 32px;
			justify-content: center;
		}

		.select-role-button {
			background-color: $sub-background-color;
			color: $light-gray;
			width: 150px;
			text-transform: none;
		}
	}
}

@media (max-width: $breakpoint-lg) {
	.select-sign-up-role-container {
		padding: 80px 5% 80px 5%;
	}
}

@media (max-width: $breakpoint-md) {
	.select-sign-up-role-container {
		padding: 60px 5% 80px 5%;

		.select-sign-up-role-content-container {
			background-color: $sub-background-color;
			border-radius: unset;
			height: unset;
		}

		.select-sign-up-role-box {
			height: unset;
		}

		.right-column {
			padding: unset;

			.right-column-content-container {
				gap: 16px;
				align-items: center;
				justify-content: center;
				height: unset;
				margin-top: unset;
			}

			.welcome-text {
				font-size: 24px;
				font-weight: 600;
				text-align: center;
			}

			.select-role-button-group {
				flex-direction: column;
				gap: 16px;
			}
	
			.select-role-button {
				background-color: $white;
				height: 50px;
				width: calc(100vw - 56px);
			}
		}
	}
}