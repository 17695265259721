@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/size';

.create-course-container {
	margin-bottom: 30px;

	.my-orka-nav-bar {
		background-color: $white;
	}

	.select-course-type-container {
		display: flex;
		height: 100%;
		flex-direction: column;
		align-items: center;
		gap: 1rem;

		.title {
			color: $main-text-color;
			font-family: $Montserrat !important;
			font-weight: 900 !important;
			font-style: normal;
			font-size: 30px !important;
			margin-top: 2rem;
			line-height: 38px;
			text-align: center;
		}

		.subtitle,
		.description {
			text-align: center;
			font: normal normal normal 16px $Roboto;
			letter-spacing: 0px;
			color: #989898;
			width: 90%;
		}

		.buttons-container {
			display: flex;
			flex-direction: row;
			width: 100%;
			justify-content: center;
			column-gap: 40px;
		}

		.button {
			padding: 10px 45px;
			background-color: #dfe6e8;
			text-align: center;
			border: none;
			border-radius: 8px;
			box-shadow: unset;
			font: normal normal 600 16px $Roboto;
			color: #989898;

			&:hover {
				background-color: $yellow;
				color: $white;
			}
		}

		.selected {
			background-color: $yellow !important;
			color: $white !important;
		}
	}

	.course-basic-info-container {
		width: 50%;
		margin: auto;

		.input-yellow-label {
			color: $yellow;
			font-size: 18px;
			font-weight: 600;
			margin-top: 15px;
		}

		.input-normal-label {
			color: $main-text-color;
			font-size: 18px;
			font-weight: 600;
			margin-top: 15px;
		}

		.input-field {
			color: #8DA6AD;
			font-weight: 600;

			&::-webkit-input-placeholder {
				color: #989898;
				font-weight: normal;
			}
	
			&::-moz-placeholder {
				color: #989898;
				font-weight: normal;
			}
	
			&::-ms-placeholder {
				color: #989898;
				font-weight: normal;
			}
	
			&::placeholder {
				color: #989898;
				font-weight: normal;
			}
		}

		.note {
			font-size: 14px;
			color: #989898;
			margin-top: 15px;
		}

		.craft-button-container {
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.craft-button {
			padding: 10px 45px;
			text-align: center;
			border: none;
			border-radius: 8px;
			font: normal normal 600 16px $Roboto;
			color: $white;
			box-shadow: unset;
			background-color: $yellow;
			margin-top: 30px;
		}

		.craft-button-icon {
			size: 12px;
		}
	}
}

@media (max-width: $breakpoint-md) {
	.create-course-container {
		.select-course-type-container {
			.title {
				font-size: 20px !important;
				line-height: 26px;
			}

			.subtitle,
			.description {
				font-size: 12px;
			}

			.buttons-container {
				column-gap: 20px;
			}

			.button {
				padding: 10px 25px;
				font-size: 12px !important;
			}
		}

		.course-basic-info-container {
			width: 90%;

			.input-yellow-label {
				font-size: 14px;
			}

			.input-normal-label {
				font-size: 14px;
			}

			.input-field {
				font-size: 12px;
			}

			.note {
				font-size: 12px;
			}

			.craft-button {
				padding: 10px 25px;
				font-size: 12px;
			}

			.craft-button-icon {
				font-size: 12px;
			}
		}
	}
}