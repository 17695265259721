@import "../../../styles/size";

.not-found-container {
	display: flex;
	justify-content: center;

	.not-found-text {
		font-size: 40px;
	}
}

@media (max-width: $breakpoint-md) {
	.not-found-container {
		.not-found-text {
			font-size: 20px;
		}
	}
}
