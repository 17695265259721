@import '../../../../../../styles/colors';
@import '../../../../../../styles/size';
@import '../../../../../../styles/fonts';

.my-orka-courses-container {
	background-color: $white;
	padding: 1.5rem 3rem 2rem 3rem;
	border-radius: 18px;

	.title-container {
		display: flex;
		align-items: center;
	}

	.title {
		color: $gray-blue !important;
		font-weight: 700;
	}

	.reload-icon {
		color: $gray-blue;
		margin-left: 10px;
	}

	.create-button {
		padding-left: 2rem;
		padding-right: 2rem;
		border: 0px transparent solid;
		width: 100%;
		background-color: #6B8C951A;
		text-align: left;
		font-size: 1.1rem;
		text-transform: uppercase;
		color: #B7B5B5;
		border-radius: 16px;
		margin-top: 16px;
		padding-top: 8px;
		padding-bottom: 8px;
		cursor: pointer;
	}
}

@media (max-width: $breakpoint-md) {
	.my-orka-courses-container {
		background-color: $sub-background-color;
		padding: unset;
		border-radius: unset;

		.title {
			font-size: 16px !important;
		}

		.create-button {
			color: #6b8c95 !important;
			padding-left: 1.5rem;
			padding-right: 1.5rem;
			padding-top: 6px;
			padding-bottom: 6px;
			font-size: 12px;
		}
	}
}
