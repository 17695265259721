@import '../../../../styles/colors';
@import '../../../../styles/size';

.booking-content-container {
	background: $sub-background-color;
	padding: 110px 5% 80px 5%;
	min-height: 100vh;

	.select-class-text {
		font-size: 24px;
		margin-top: 16px;
	}

	.booking-course-name {
		color: $yellow;
		font-size: 18px;
		font-weight: 600;
	}

	.booking-lesson-item {
		padding: 16px;
		background-color: $white;
		height: 100%;
		border-radius: 8px;
		cursor: pointer;
		user-select: none;

		.lesson-name {
			word-break: break-word;
		}
	}
	
	.booking-lesson-item.disabled {
		cursor: not-allowed;
		background-color: $light-gray;
	}

	.booking-lesson-item.selected {
		background-color: $yellow;
	}

	.select-date-time {
		text-align: center;
		margin: 4px 0;
		font-weight: 600;
	}
}

@media (max-width: $breakpoint-md) {
	.booking-content-container {
		padding: 60px 5% 80px 5%;

		.booking-course-name {
			font-size: 14px;
		}

		.select-class-text {
			font-size: 18px;
			margin-top: 8px;
		}
	}
}