@import '../../../../../../styles/colors';
@import '../../../../../../styles/size';
@import '../../../../../../styles/fonts';

.my-orka-course-students-container {
	background-color: $white;
	padding: 1.5rem 3rem 2rem 3rem;
	border-radius: 18px;

	.title-container {
		display: flex;
		align-items: center;
	}

	.title {
		color: $gray-blue !important;
		font-weight: 700;
	}

	.reload-icon {
		color: $gray-blue;
		margin-left: 10px;
	}

	.back-icon {
		color: $gray-blue;
	}

	.student-progress-item {
		background-color: #6B8C951A !important;
		box-shadow: unset !important;
		position: unset !important;
		border-radius: 16px !important;
		margin-top: 16px !important;
	
		.student-name {
			color: #6b8c95;
			font-size: 1.1rem;
		}
	
		.student-progress-information-container {
			display: flex;
			flex-wrap: wrap;
			column-gap: 24px;
		}
	
		.student-progress-information {
			font-family: $Roboto;
			word-break: break-word;
			color: $gray-blue;
		}
	
		.view-edit-button {
			font-family: $Roboto;
			color: $gray-blue;
			font-weight: 600;
			text-decoration: underline !important;
			cursor: pointer;
		}
		
		.student-progress-item-summary.MuiAccordionSummary-root,
		.student-progress-item-summary.MuiAccordionSummary-root.Mui-expanded {
			min-height: unset !important;
			padding-top: 8px !important;
			padding-bottom: 8px !important;
			padding-left: 2rem !important;
			padding-right: 2rem !important;
		}
	
		.MuiAccordionSummary-content,
		.MuiAccordionSummary-content.Mui-expanded {
			margin: 0px;
		}
	
		.MuiAccordionDetails-root {
			padding-left: 2rem !important;
			padding-right: 2rem !important;
		}
	}

	.student-booking-item {
		background-color: #6B8C951A;
		border-radius: 16px;
		margin-top: 16px;
		padding-top: 8px;
		padding-bottom: 8px;
		padding-left: 2rem;
		padding-right: 2rem;

		.lesson-name {
			color: #6b8c95;
			font-size: 1.1rem;
		}

		.MuiInput-root,
		.MuiInput-input {
			padding: 0;
		}

		.summary-input-title {
			color: $light-gray;
		}

		.summary-input-field {
			background-color: $white;
			padding: 8px !important;
			border-radius: 8px;
		}

		.summary-buttons-container {
			margin-top: 10px;
			display: flex;
			justify-content: center;
			gap: 30px
		}

		.summary-button {
			width: 100px;
		}

		.date-time-container {
			display: flex;
			column-gap: 24px;
		}
	}
}

@media (max-width: $breakpoint-md) {
	.my-orka-course-students-container {
		background-color: $sub-background-color;
		padding: unset;
		border-radius: unset;

		.title {
			font-size: 16px !important;
		}

		.student-progress-item {
			.student-name {
				font-size: 14px;
			}
	
			.student-progress-item-summary.MuiAccordionSummary-root,
			.student-progress-item-summary.MuiAccordionSummary-root.Mui-expanded {
				padding-top: 6px !important;
				padding-bottom: 6px !important;
				padding-left: 1.5rem !important;
				padding-right: 1.5rem !important;
			}
	
			.MuiAccordionDetails-root {
				padding-left: 1.5rem !important;
				padding-right: 1.5rem !important;
			}
		}

		.student-booking-item {
			.lesson-name {
				font-size: 14px;
			}
		}
	}
}