@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/size';

.checkout-course-container {
	background: $sub-background-color;
	padding: 100px 25% 30px 25%;
	min-height: 100vh;

	.course-basic-information-container {
		.course-title {
			font-size: 24px;
			color: $yellow;
			text-align: center;
			font-weight: 600;
		}

		.course-description {
			text-align: justify;
		}
	}

	.course-checkout-information-container {
		.title-text {
			color: $yellow;
			font-size: 18px;
			font-weight: 600;
		}

		.title-text-black {
			color: $black;
			font-size: 18px;
			font-weight: 600;
		}

		.online-course-lesson-item {
			display: flex;
			flex-direction: row;
			gap: 16px;

			.lesson-title {
				flex: 1;
				word-break: break-word;
			}
		}

		.checkout-button-container {
			display: flex;
			justify-content: center;
			margin-top: 4px;
		}
		
		.bought-course-container {
			display: flex;
			flex-direction: column;
			align-items: center;
			margin-top: 4px;
			gap: 4px;
		}
	}

	.select-payment-method-container {
		display: flex;
		gap: 16px;

		.select-payment-method-button {
			flex: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
		}

		.save-x-percent {
			font-size: 14px;
		}
	}

	.select-lesson-item {
		display: flex;
		align-items: center;

		.lesson-title {
			word-break: break-word;
		}
	}

	.select-date-time {
		text-align: center;
		margin: 4px 0;
		font-weight: 600;
	}

	.hour-item {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: $light-gray;
		padding: 4px 0;
		border-radius: 4px;
		color: $white;
		cursor: pointer;

		&:hover {
			opacity: 0.8;
		}
		&:active {
			opacity: 0.6;
		}
	}

	.hour-item.selected-hour {
		background-color: $yellow;
		color: $white;
	}

	.MuiInput-root,
	.MuiInput-input {
		padding: 0;
	}

	.promo-code-input {
		background-color: $white;
		padding: 8px;
		border-radius: 8px;
	}
}

@media (max-width: $breakpoint-lg) {
	.checkout-course-container {
		padding: 100px 15% 30px 15%;
	}
}

@media (max-width: $breakpoint-md) {
	.checkout-course-container {
		padding: 100px 5% 30px 5%;

		.course-basic-information-container {
			.course-title {
				font-size: 18px;
			}
		}

		.course-checkout-information-container {
			.title-text {
				font-size: 14px;
			}

			.title-text-black {
				font-size: 14px;
			}
		}

		.select-payment-method-container {
			gap: 8px;

			.save-x-percent {
				font-size: 10px;
			}
		}
	}
}