@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/size';

.payment-success-content-container {
	background: $sub-background-color;
	padding: 100px 25% 30px 25%;
	min-height: 100vh;

	.course-title {
		font-size: 24px;
		color: $yellow;
		text-align: center;
		font-weight: 600;
	}

	.course-description {
		text-align: justify;
	}

	.title-text {
		color: $yellow;
		font-size: 18px;
		font-weight: 600;
	}

	.course-lesson-item {
		display: flex;
		flex-direction: row;
		gap: 16px;

		.lesson-title {
			flex: 1;
			word-break: break-word;
		}

		.lesson-price {
			width: 100px;
			text-align: end;
		}
	}

	.button-container {
		display: flex;
		justify-content: center;
	}
}

@media (max-width: $breakpoint-lg) {
	.payment-success-content-container {
		padding: 100px 15% 30px 15%;
	}
}

@media (max-width: $breakpoint-md) {
	.payment-success-content-container {
		padding: 100px 5% 30px 5%;

		.course-title {
			font-size: 18px;
		}

		.title-text {
			font-size: 14px;
		}
	}
}