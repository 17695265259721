.about-carousel-container {
  /* display: flex;
  margin: 20vh 15vw 20vh 15vw;
  align-self: center;
  justify-content: center;
  align-items: center; */
  margin: 0 auto 7rem auto;
  width: 80%;
}

.about-carousel-item.carousel-item {
  flex-direction: column;
  align-items: center;
}

.about-carousel-container .carousel-caption {
  position: unset !important;
  right: unset !important;
  bottom: unset !important;
  left: unset !important;
}

.about-carousel-container .carousel-control-prev,
.about-carousel-container .carousel-control-next {
  color: #949494 !important;
}

.about-carousel-comment {
  text-align: center;
  padding: 0 16%;
  color: #949494;
  font-size: 1.3rem;
  letter-spacing: 0.3px;
  opacity: 0.8;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.about-carousel-container .about-carousel-user {
  color: #64949a;
  text-transform: capitalize;
  opacity: 0.68;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: unset !important;
}

.about-carousel-container .about-carousel-course {
  color: #e9a535;
  opacity: 0.68;
  text-transform: capitalize;
  font-size: 20px;
  font-weight: 800;
  margin-bottom: unset !important;
  margin-right: unset !important;
}

.light-blue {
  color: #adbfc5;
}

.common-questions-container {
  width: 100%;
}

.common-questions-title {
  margin: 0 10vw;
  font-size: 40px !important;
  letter-spacing: 2.48px;
  color: #949494 !important;
  text-transform: uppercase;
  font-family: Montserrat, sans-seri !important;
  font-weight: 900 !important;
}

.questions-container {
  margin: 1.3rem 10vw 3rem 10vw;
}

.faqs-accordion-item {
  background-color: #64949a;
}

.hidden {
  display: none !important;
}

.hidden.d-flex.justify-content-between {
  display: none !important;
}

.question-accordion-item {
  background: #f0f3f4;
  border-radius: 28px;
  padding: 1vh 4vw;
  margin-bottom: 2vh;
}

.question-title-active {
  font-weight: 700;
  letter-spacing: 0.2px;
  color: #64949a;
  font-size: 18px;
}

.question-body-active {
  color: #949494;
  font-weight: 300;
  font-size: 18px;
  margin: 0 !important;
}

.question-body {
  margin: 0 !important;
  color: #adbfc5;
  font-weight: 700;
  letter-spacing: 0.2px;
  font-size: 16px;
}

.question-accordion-container-active {
  padding: 2vh 0;
}

.font-size-20 {
  font-size: 20px;
}

.strong-blue {
  color: #64949a;
}

.carousel-indicators [data-bs-target] {
  width: 10px !important;
  height: 10px !important;
  border-radius: 50%;
  background-color: #e9a535 !important;
}

.font-weight-200 {
  font-weight: 200;
}

@media (max-width: 576px) {
  .about-carousel-comment {
    line-height: 23px;
    letter-spacing: 0;
  }
  .about-carousel-user {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0;
    font-weight: 600;
  }
  .about-carousel-course {
    font-size: 18px;
    line-height: 23px;
    letter-spacing: 0;
    font-weight: 600;
    text-align: center;
    margin-bottom: 10vh;
  }
  .common-questions-title {
    font-size: 23px !important;
    line-height: 37px;
    letter-spacing: 1.13px;
  }
  .question-accordion-item {
    border-radius: 10px;
  }
  .question-body {
    margin-right: 10vw !important;
  }
  .question-body-active {
    margin-right: 11vw !important;
    line-height: 22px;
    color: #949494;
    opacity: 0.8;
  }
}
@media (max-width: 576px) {
  .AccordionItem {
    margin-top: 1rem !important;
  }
  .AccordionItem .accordion-button.collapsed, .AccordionItem .botoAgregar {
    height: 10px !important;
    min-height: 35px !important;
    margin-top: 1rem;
  }
  .AccordionItem .AccordionItem {
    margin-top: 1rem !important;
  }
}
/*estilo para la caja de gris*/
.container-general-account-information-page-instructor {
  border-radius: 4.2617960426vh;
}

/*estilos para ocultar el menu mobil*/
.box-general-account-information-page-instructor .container-profile-contents {
  display: none;
}

/*estilos para mostrar el menu de pc*/
.box-general-account-information-page-instructor .container-name-profile-instructor-pc {
  display: block;
}

/*elementos del menu de pc (estos estilos estan en el componente pero ahora los puse aqui por temas de comodidad)*/
.container-general-account-information-page-instructor .hi-there {
  color: #48676F;
  opacity: 0.68;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 3.0441400304vh !important;
  margin-bottom: 0.5rem;
}

.container-general-account-information-page-instructor .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.3272450533vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.container-general-account-information-page-instructor h4.titles-sections-info,
.container-general-account-information-page-instructor i {
  color: #64949a !important;
  font-size: 3.0441400304vh !important;
  opacity: 0.68;
}

/*ponerle la fuente al header*/
.container-general-account-information-page-instructor h4.titles-sections-info {
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
}

.container-general-account-information-page-instructor .container-personal-info-and-account-info {
  background-color: #fff;
  border-radius: 4.2617960426vh;
  padding: 4rem;
}

/*estilos a la clase de los elementos label*/
.container-general-account-information-page-instructor .container-personal-info-and-account-info .label-element {
  color: #949494;
  opacity: 0.5;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh;
  font-weight: 400;
}

.container-general-account-information-page-instructor .container-personal-info-and-account-info .item-personal-info:nth-child(4) .box-city-state-zip {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;
}

/* .profile-info .container-personal-info-and-account-info .item-personal-info:nth-child(4) .box-city-state-zip{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem;
} */
/*espacio en cada caja de inputs*/
.container-general-account-information-page-instructor .container-personal-info-and-account-info .item-personal-info {
  margin-bottom: 2.5rem !important;
}

.container-general-account-information-page-instructor .item-personal-info .field-full-last-name-mobil {
  display: none;
}

/*estilos al icono que esta dentro del componente Navbar que esta dentro del componente AccountIinformationPageStudent mas bien de instructor*/
.box-general-account-information-page-instructor .icon-user-header {
  background-color: #fff !important;
}

.box-general-account-information-page-instructor .myOrka-link {
  margin-left: 0.8rem;
  margin-right: 1rem;
}

/*estilos al texto dentro de los campos de texto*/
.box-general-account-information-page-instructor .edicion-text-box {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh !important;
  font-weight: 400 !important;
}

.box-general-account-information-page-instructor .txt-change-password {
  color: #949494;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh;
  font-weight: 600;
}

.container-generic {
  display: block;
}

/*estilos para el componente de help*/
.box-general-account-information-page-instructor .box-help-information {
  display: none;
}

@media (max-width: 768px) {
  .container-generic {
    display: none;
  }
  /*estilo para ocultar el menu de pc*/
  .box-general-account-information-page-instructor .container-name-profile-instructor-pc {
    display: none;
  }
  /*mostrar el menu mobil*/
  .box-general-account-information-page-instructor .container-profile-contents {
    border-radius: 0 0 1.8rem 1.8rem;
    display: block;
  }
  .container-general-account-information-page-instructor h4.titles-sections-info,
  .container-general-account-information-page-instructor .container-personal-info-and-account-info i {
    color: #949494 !important;
    font-size: 2.2831050228vh !important;
  }
  /*estilos a los labels*/
  .container-general-account-information-page-instructor .container-personal-info-and-account-info .label-element {
    font-size: 1.8264840183vh;
  }
  /*estilos a las letras de los campos de texto*/
  .box-general-account-information-page-instructor .edicion-text-box {
    font-size: 1.8264840183vh !important;
  }
  /*estilos a la opcion de cambiar contraseña*/
  .box-general-account-information-page-instructor .txt-change-password {
    font-size: 1.8264840183vh;
  }
  /* ========================== <estilos al navbar (modo mobil)> ========================== */
  /* .box-general-account-information-page-instructor #Linkstaticob{
      margin-right: 1.4rem;
      margin-left: 0;
  } */
  .box-general-account-information-page-instructor .myOrka-link {
    margin-right: 1.2rem;
    margin-left: 0;
  }
  /*este estilo no esta en el navbar pero ogual lo agrego por si tengo que agregarle algo al navbar*/
  .box-general-account-information-page-instructor .navBar-link-home {
    display: inline-block;
  }
  .box-general-account-information-page-instructor .container-header-myOrka {
    background-color: #c0d1d5;
  }
  /*cambiar el background de imagen de user del header (por el momento esta clase NO ESTA EN EL NAVBAR PERO QUIZA LA AGREGUE)*/
  .box-general-account-information-page-instructor .icon-user-header {
    background-color: #c0d1d5 !important;
  }
  /*quitarle los margin a loa lados al navbar*/
  .box-general-account-information-page-instructor .my-orka-nav-bar {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  /*color de fondo de  la imagen grande del usuario*/
  .box-general-account-information-page-instructor .container-profile-contents .nav-user-icon {
    background-color: rgba(59, 107, 120, 0.2196078431) !important;
  }
  /*estilos para el color del usuario*/
  .box-general-account-information-page-instructor .container-profile-contents .icon-user-main {
    color: #8db2bc !important;
    border-radius: 5rem;
    display: inline-block;
    padding: 0.1rem;
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  /*separacion arriba de l texto de hi there*/
  .box-general-account-information-page-instructor .container-profile-contents .hi-there {
    margin-top: 0.8rem;
  }
  /*color al texto del nombre del usuario y separacion arriba*/
  .box-general-account-information-page-instructor .container-profile-contents .full-name-person {
    color: #64949a !important;
    margin-top: -0.5rem;
  }
  /* ========================== </estilos al navbar (modo mobil)> ========================== */
  /*poner en color blanco*/
  .container-general-account-information-page-instructor {
    background-color: #fff !important;
  }
  .container-general-account-information-page-instructor .container-personal-info-and-account-info {
    padding: unset;
  }
  .container-general-account-information-page-instructor .container-personal-info-and-account-info .item-personal-info:nth-child(4) {
    justify-content: unset;
  }
  /* estilos ocultar el campo de texto de pc y mostrar el de mobil dentro de otra caja*/
  .container-general-account-information-page-instructor .item-personal-info .field-full-last-name-pc {
    display: none;
  }
  .container-general-account-information-page-instructor .item-personal-info .field-full-last-name-mobil {
    display: block;
  }
  /*estilos para el componente de help*/
  .box-general-account-information-page-instructor .box-help-information {
    color: #64949a !important;
    display: block;
    margin-top: 14rem;
    margin-bottom: 2rem;
    margin-left: -1rem;
  }
  .box-general-account-information-page-instructor .box-help-information .helpBox {
    margin-left: -8px;
    margin-bottom: 0.5rem;
  }
}
.personal-detail .icon-user {
  color: #e9a535;
}

.personal-detail .container-name-profile-student-mobil {
  display: none;
}

.container-generic {
  display: block;
}

/*quite el codigo que iba aqui y lo pase a la hoja de estilos
de ContentAccountIinformationPageStudent
*/
@media (max-width: 768px) {
  .container-generic {
    display: none;
  }
  .personal-detail .container-name-profile-student-mobil {
    border-radius: 0 0 1.8rem 1.8rem;
    display: block;
  }
  .personal-detail .container-name-profile-student-mobil .nav-user-icon {
    background-color: rgba(142, 178, 188, 0.2196078431) !important;
  }
}
.container-general-all-set {
  margin: 5vh auto 5vh auto;
  width: 75%;
}

.container-img-header-text {
  background-color: rgba(142, 178, 188, 0.61);
  border-radius: 35px;
  color: #64949a !important;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

/* .container-img-header-text img{
    color: #fff!important;
    margin-right: 2.2rem;
    width: 50%;
} */
.container-img-header-text .img-all-set {
  color: rgba(255, 255, 255, 0.61);
  margin-right: 2.2rem;
  width: 50%;
}

.container-img-header-text h3 {
  color: #64949a !important;
  font-size: 3.8051750381vh !important;
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.container-img-header-text p {
  font-size: 1.8264840183vh;
  font-family: Roboto, sans-serif !important;
  font-weight: 400;
  line-height: 0.5;
}

.container-img-header-text .break-after-now-student {
  display: none;
}

.container-img-header-text .break-after-cources-teacher {
  display: none;
}

.box-general-all-set .boxHelpAllSet {
  color: #64949a;
}

.box-general-all-set .boxHelpAllSet .help {
  left: 2rem;
  bottom: 0rem;
}

.box-general-all-set .boxHelpAllSet .helpBox {
  margin-left: 5.2rem;
}

@media (max-width: 768px) {
  .box-general-all-set {
    background-color: #adbfc5 !important;
  }
  .container-general-all-set {
    margin: 0 auto;
    width: 72%;
    height: 100vh;
  }
  /* .sub-container-general{
      margin: 0 auto;
      width: 70%;
      height: 50vh;
  } */
  .container-img-header-text {
    background-color: unset;
    border-radius: unset;
    margin-top: 10vh;
    padding-top: unset;
    padding-bottom: unset;
    text-align: left;
  }
  /*oculta la caja de la imagen*/
  .container-img-header-text .box-img {
    display: none;
  }
  .container-img-header-text h3 {
    font-size: 3.0441400304vh !important;
    margin-left: -0.9rem;
    margin-bottom: unset;
  }
  .container-img-header-text .text-general {
    line-height: unset;
    margin-bottom: 0.5rem;
  }
  .container-img-header-text .text-for-teacher {
    line-height: unset;
  }
  .container-img-header-text .text-general,
  .container-img-header-text .text-for-student,
  .container-img-header-text .text-for-teacher {
    font-size: 1.2176560122vh !important;
    text-align: left;
    line-height: unset;
  }
  .container-img-header-text .break-after-now-student {
    display: inline-block;
  }
  .container-img-header-text .break-after-cources-teacher {
    display: inline-block;
  }
  /*estilos a componente de help*/
  .box-general-all-set .boxHelpAllSet .helpBox {
    margin-left: -0.5rem;
  }
  .box-general-all-set .boxHelpAllSet .help {
    left: 2rem;
    bottom: 0rem;
  }
}
.book-title {
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
  font-style: normal;
  font-size: 45px !important;
  color: #949494 !important;
  text-transform: uppercase;
  margin-bottom: 75px;
  padding: 0 !important;
}

.book-container {
  margin: 24vh 5vw;
}

.book-accordion-mobile {
  display: none;
}

.accordion-header_booknow .accordion-button {
  text-transform: uppercase;
  opacity: 0.8;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-style: normal;
  font-size: 23px !important;
  padding: 30px 60px !important;
  background: #f8f8f8 !important;
}

.accordion-header_booknow .accordion-button.colapsed {
  color: #adbfc5 !important;
  background: #a1a1a1 !important;
  padding: 0 !important;
}

.book-accordion-item {
  margin: 40px 0;
}

.calendar-status-text {
  font: normal normal normal 8px Roboto, sans-serif;
  color: rgba(71, 71, 71, 0.68);
  text-transform: capitalize;
  margin: 0;
}

.calendar-status-container {
  margin-left: 25px;
}

.fontsize-8 {
  font-size: 10px !important;
}

.gray-unavaible {
  color: rgba(127, 130, 131, 0.5);
}

.yellow-avaible {
  color: rgb(247, 194, 109);
}

.mr-4 {
  margin-right: 4px;
}

.w-40 {
  width: 40%;
}

.my-10 {
  margin: 10px 0;
}

/*estilos para el modal de dinero gastado*/
.modal-spent-money .modal-dialog {
  margin: 0 auto;
  width: 50% !important;
}

.modal-spent-money .modal-header {
  border-bottom: 1px solid #fff;
}

.box-help-book {
  color: #64949a;
}

/**/
.box-help-book .helpBox {
  margin-left: 6.5rem;
}

@media (max-width: 576px) {
  .book-container {
    margin: 10vh 5vw;
  }
  .book-title {
    font: normal normal 900 25px/32px Montserrat !important;
    color: #64949a !important;
    margin-bottom: 50px;
  }
  .book-accordion-mobile {
    display: block;
  }
  .book-accordion-desk {
    display: none;
  }
  .book-h3-mobile {
    text-align: left;
    font: normal normal bold 16px/20px Roboto !important;
    margin: 0;
    letter-spacing: 0px;
    color: #e9a535 !important;
    text-transform: uppercase;
  }
  .calendarBookNow {
    padding: 0 25px;
  }
  .date-time_booknow--title {
    margin: 30px 0;
  }
  .available-schedule-container {
    grid-gap: 20px;
  }
  .available-schedule-item {
    padding: 5px 20px;
  }
  .book-accordion-next {
    color: #64949a;
    margin: 30px 0;
  }
  .book-accordion-next > p {
    margin: 0;
    padding-right: 10px;
    font: normal normal 600 16px Roboto;
    color: #64949a;
  }
  .footer-book {
    display: none;
  }
  .book-steps-item-mobile {
    background: rgba(161, 161, 161, 0.2);
    border-radius: 15px;
  }
  .book-steps-item-mobile > p {
    color: #a1a1a1;
    text-transform: uppercase;
    opacity: 0.45;
    padding: 10px;
    font: normal normal bold 16px/20px Roboto;
  }
  .book-h2-mobile {
    font: normal normal 600 16px/20px Roboto !important;
    color: #a1a1a1 !important;
    margin: 0;
  }
  .payment-form-group {
    margin: 40px 15px;
  }
  .margin-15px {
    margin: 15px !important;
  }
  .checkout-form-input {
    font: normal normal normal 16px Roboto !important;
    color: #a1a1a1 !important;
    text-transform: uppercase !important;
    opacity: 0.5 !important;
    padding: 20px 0 !important;
    width: 100%;
    outline: none;
  }
  .review-text-bold {
    font: normal normal bold 16px/20px Roboto;
    color: #7b98a0;
    margin: 10px 0;
  }
  .review-text-regular {
    font: normal normal normal 16px/20px Roboto;
    color: #7b98a0;
    opacity: 0.68;
  }
  .review-text-price {
    font: normal normal bold 14px/17px Roboto;
    color: #64949a;
    opacity: 0.68;
  }
  .review-text-price.final {
    font: normal normal bold 16px/20px Roboto;
    color: #e9a535;
    opacity: 1;
  }
  .review-label-check {
    font: normal normal normal 12px/15px Roboto;
  }
  .review-check-link {
    font: normal normal 600 12px/15px Roboto;
  }
  .review-li > .review-text-regular {
    font: normal normal 600 16px/20px Roboto;
    color: #7b98a0;
    opacity: 0.68;
  }
  .review-checkout {
    width: 100% !important;
    text-align: center;
    border-radius: 12px;
    font: normal normal 600 16px/20px Roboto;
  }
  .infoReviewAndFinish-Container {
    margin-bottom: 40px;
  }
  .content-box {
    box-sizing: content-box !important;
  }
  .payment-instructions-col {
    padding: 0;
  }
  .payment-instructions-text.mb20 {
    margin: 10px 20px;
    width: auto;
    padding: 0;
  }
  .payment-instructions-text {
    margin: auto;
  }
  .payment-container {
    padding: 0 !important;
  }
  .payment-option-title {
    font-size: 16px;
  }
  .payment-price {
    font-size: 16px;
  }
  .payment-option-subtitle {
    font-size: 12px;
  }
  .payment-instructions-text {
    color: #a1a1a1;
  }
}

.date-time_booknow--container {
  justify-content: space-around;
}

.date-time_booknow--container > div {
  background: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
}

.accordion-date_booknow {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.calendarBookNow {
  background: #ffffff;
  padding: 20px 25px;
  border-radius: 24px;
}

.date-time_booknow--title {
  font: normal normal 200 16px Roboto, sans-serif;
  margin-bottom: 30px;
  color: #a1a1a1;
  opacity: 0.7;
}

.available-schedule-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 30px;
}

.available-schedule-item {
  background: rgba(161, 161, 161, 0.2);
  border-radius: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 35px;
  cursor: pointer;
}

.available-schedule-item.active {
  background: rgba(247, 194, 109, 0.81);
}

.available-schedule-item p {
  margin: 0;
  font: normal normal 600 16px Roboto, sans-serif;
  color: rgba(142, 178, 188, 0.8);
}

.available-schedule-item.active p {
  color: rgba(239, 159, 30, 0.8);
}

.calendarBookNow > div > div > div > div:nth-child(2) > div > div > div:nth-child(1) {
  background-color: rgba(142, 178, 188, 0.2196078431) !important;
  border-radius: 12px !important;
  color: #8db2bc !important;
}

div.calendarBookNow > div > div > div > div:nth-child(2) > div > div > div:nth-child(1) > .rmdp-week-day {
  color: #64949a !important;
  font-weight: bold;
  opacity: 68% !important;
}

.rmdp-day-picker > div {
  background: rgba(235, 235, 235, 0.2) 0% 0% no-repeat padding-box;
  border-radius: 16px;
  opacity: 1;
}

.rmdp-header-values span {
  text-align: center;
  font: normal normal bold 18px Roboto, sans-serif;
  color: #64949a;
  text-transform: uppercase;
  opacity: 0.68;
}

.rmdp-arrow-container .rmdp-arrow {
  height: 10px;
  width: 10px;
  border: solid #8db2bc;
  border-width: 0 2px 2px 0;
}

.rmdp-arrow-container:hover {
  background-color: transparent !important;
  box-shadow: transparent !important;
}

.review-text-bold {
  font: normal normal bold 20px Roboto;
  text-align: left;
  color: #7b98a0;
  margin-bottom: 5px;
}

.review-text-bold.service {
  text-align: right;
}

.review-text-regular {
  text-align: left;
  font: normal normal normal 18px Roboto;
  color: rgba(131, 131, 131, 0.68);
  margin: 0;
}

.review-li > .review-text-regular {
  font: normal normal 600 18px Roboto;
  text-transform: uppercase;
}

.review-text-regular.right {
  text-align: right;
}

.review-text-price {
  text-align: left;
  font: normal normal bold 20px Roboto;
  color: rgba(107, 140, 149, 0.68);
  margin: 0;
  margin-bottom: 5px;
}

.review-text-price.final {
  color: rgb(107, 140, 149);
}

.p-10 {
  padding: 0 5rem !important;
}

.review-promo {
  border: 1px solid rgba(112, 112, 112, 0.36);
  border-radius: 8px;
  color: #7b98a0;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 12px;
  opacity: 0.8;
  text-transform: uppercase;
  width: 100% !important;
}

.review-li {
  color: #e9a535;
  list-style: outside;
}

.review-li::marker {
  font-size: 2em;
  line-height: 0.5em;
}

.review-label-check {
  text-align: left;
  font: normal normal normal 15px Roboto;
  color: rgba(131, 131, 131, 0.68);
}

.review-check-link {
  color: #7b98a0;
  opacity: 0.68;
  font: normal normal 600 15px Roboto;
}

.review-label-check > input {
  border: 1px solid red;
}

.height50vh {
  height: 50vh !important;
}

.height30vh {
  height: 30vh !important;
}

.review-cupon {
  background: rgba(161, 161, 161, 0.33);
  border-radius: 8px;
  color: rgba(131, 131, 131, 0.68);
  padding: 10px 12px;
  text-transform: uppercase;
  margin-top: 10px;
  width: 100% !important;
}

.error-cupon {
  background: rgba(223, 10, 10, 0.33);
  border-radius: 8px;
  color: rgba(131, 131, 131, 0.68);
  padding: 10px 12px;
  text-transform: uppercase;
  margin-top: 10px;
  width: 100% !important;
}

.review-text {
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 0 !important;
}

.review-check {
  visibility: hidden;
}

.review-check + *::before {
  content: "";
  display: inline-block;
  vertical-align: bottom;
  width: 1rem;
  height: 1rem;
  border-radius: 10%;
  border-style: solid;
  border-width: 0.1rem;
  border-color: #64949a;
}

.review-check:checked + *::before {
  content: "✓";
  color: white;
  text-align: center;
  background: teal;
  border-color: teal;
}

.review-check:checked:checked + * {
  color: teal;
}

.review-all-section {
  font: normal normal normal 10px Roboto;
  color: #949494;
  opacity: 0.5;
}

.review-checkout {
  color: white;
  cursor: pointer;
  padding: 10px 80px !important;
  margin: 30px 0 0 !important;
  background: rgba(239, 183, 91, 0.74);
  width: fit-content !important;
  border-radius: 8px;
  font: normal normal 600 20px Roboto;
}
.itemsDropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.draggableContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.titleDraggable {
  font-family: Roboto, sans-serif !important;
  font-weight: 600 !important;
  font-size: 25px !important;
  letter-spacing: 0.6px;
}

.Green.mt-5.mb-4 {
  font-family: Roboto, sans-serif !important;
  font-weight: 500 !important;
  font-size: 17px !important;
  letter-spacing: 0.6px;
}

.draggableRow {
  display: flex;
  justify-content: center;
}

.draggableUl {
  display: flex;
  padding: 0 !important;
  margin: 0 !important;
}

.draggableLi {
  padding-right: 10px;
  border-radius: 7px;
}

.draggableImage {
  min-height: 33vh;
  max-height: 33vh;
  border-radius: 7px;
  object-fit: cover;
  max-width: 150px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.moreMediaRow {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 150px;
  min-height: 33vh;
  max-height: 33vh;
  border: 1px solid #707070;
  column-gap: 5px;
  border-radius: 7px;
  margin-left: 7px;
}

.spanViewGallery {
  cursor: pointer;
  font-size: 10px;
}

.btnYellow {
  border: none;
  border-radius: 0.75rem;
  padding: 0.75rem 4rem;
  background: #e9a535;
  color: white;
}

.playAbsoluteDragabble {
  width: 50px;
  position: absolute;
  top: 20%;
  right: 10%;
}
.CardStory {
  width: 33%;
  height: 275px !important;
}

.CardStoryVideo {
  width: 100%;
}

.CardStoryDrop {
  height: 100%;
}

.IconCard {
  height: 45px;
  width: 32px;
  color: #c0d1d5;
}

.IconCardFull {
  height: 100%;
  object-fit: cover;
  position: relative;
}

.playAbsolute {
  width: 50px;
  position: absolute;
  top: 40%;
  left: 35%;
  z-index: 5;
}

.CardViewGallery {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 10px;
  left: 15px;
  right: 15px;
  padding: 5px 10px;
  border-radius: 5px;
  background: #F9D7A0;
  opacity: 0.9;
  color: white;
}

.imgViewGallery {
  height: 17px;
  padding-right: 5px;
}
.StartingAt {
  font-size: 1rem !important;
}

.Price {
  font-size: 3rem;
  line-height: 3rem;
}

.Hours {
  font-size: 1.1rem;
  line-height: 1.1rem;
  font-weight: 400 !important;
}

.WhatsIncluded {
  font-size: 1rem;
}

.Cents {
  font-size: 1.5rem;
  top: -1.5rem;
}
.BgTransparent {
  background: transparent !important;
  border: none !important;
}

.itemImgCarousel {
  max-width: 100%;
  max-height: 50vh;
}

.itemVideoCarousel {
  max-width: 100%;
  min-height: 50vh;
  max-height: 50vh;
}

.exitCarousel {
  position: absolute;
  right: 1px;
  cursor: pointer;
  z-index: 5;
  color: white;
  font-size: 50px;
  font-weight: 100;
}

.active.carousel-item {
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.galleryModal {
  height: 100%;
  width: 100% !important;
}

.galleryCarousel {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.first-box-carousel-item-carousel-with-dots {
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-position: center center;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  min-height: 85vh;
  max-height: 85vh;
}

.CarouselText {
  background-color: #fff;
  box-shadow: 3px 20px 30px rgba(0, 0, 0, 0.1137254902);
  border-radius: 4px;
  width: 75%;
  height: auto;
  padding: 50px 75px;
}

@media (max-width: 768px) {
  .CarouselText {
    font-size: 1.3rem !important;
    width: 85%;
  }
}
.carousel-indicators {
  margin-bottom: -7vh !important;
  grid-gap: 20px;
}

@media (max-width: 768px) {
  .Carousel-title {
    font-size: 20px !important;
  }
}
@media (min-width: 768px) {
  .carousel-dots {
    margin-top: 3em;
  }
}
.payment-container {
  padding: 0 8%;
}

.checkout-form-input {
  background: transparent !important;
  border: none !important;
  border-radius: 0 !important;
  border-bottom: 0.5px solid #64949a !important;
  padding: 0 !important;
  margin: 0 !important;
  font-size: 20px;
}

.stripe-input--placeholder {
  color: #949494;
}

.checkout-form-input.stripe {
  padding: 3px !important;
}

.payment-col-item {
  padding: 0 !important;
  margin: 0 !important;
}

.payment-form-group {
  padding: 0 !important;
  margin-right: 15px;
}

.payment-label {
  text-align: start;
  display: block;
  font: normal normal 600 16px Roboto;
  color: #64949a;
  opacity: 0.8;
}

.payment-subtitles {
  padding: 0 !important;
}

.payment-instructions-text {
  font-family: Roboto;
  color: #707070;
  margin: 0 20px;
}

.payment-instructions-text.mb20 {
  margin: 20px;
}

.payment-chevron-container {
  text-align: end !important;
  padding-right: 30px !important;
}

.payment-option-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0 !important;
}

.payment-option-title.yellow {
  color: #e9a535;
}

.payment-option-title.gray {
  color: #adbfc5;
}

.payment-option-subtitle {
  margin: 0;
  text-align: right;
}

.payment-option-subtitle.yellow {
  color: #e9a535;
}

.payment-option-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 20px;
  padding: 20px;
}

.payment-option-container.background-yellow {
  background: #f3cd90;
}

.payment-option-container.background-gray {
  background: #c0d1d5;
}

.payment-price {
  font-size: 28px;
  letter-spacing: 1px;
  font-weight: 700;
  margin: 0;
}

.payment-price.yellow {
  color: #e9a535;
}

.payment-price.gray {
  color: #c0d1d5;
}

.payment-instructions-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.payment-chevron-item {
  font-size: 25px;
  color: #949494;
  margin: 30px 15px;
  cursor: pointer;
}

.radio-yellow {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 3px solid white;
  background: white;
  padding: 5px;
}

.radio-yellow:checked {
  background-color: #e9a535;
}

.mr-10 {
  margin-right: 30px !important;
}

.ml-4 {
  margin-left: 10px !important;
}

.ml-10 {
  margin-left: 30px !important;
}

.mb-50 {
  margin-bottom: 50px;
}
.box-hours-milles-classes-left {
  width: 96.7% !important;
}

/* .box-hours-milles-classes-left .secondary-box{

} */
.box-hours-milles-classes-left .secondary-box > div {
  width: 90%;
}

.box-hours-milles-classes-left .secondary-box .text-measures {
  color: #fff !important;
  opacity: 68%;
  font-family: Roboto, sans-serif;
  font-size: 0.9375rem !important;
  font-weight: 700;
  font-style: normal;
}

.box-hours-milles-classes-left .secondary-box .text-quantity {
  color: #fff !important;
  font-family: Roboto, sans-serif;
  font-size: 1.5625rem;
  font-weight: 700;
  font-style: normal;
}

.box-hours-milles-classes-left .secondary-box .text-rest {
  color: #fff !important;
  font-size: 0.5rem;
  margin-bottom: 0;
  opacity: 68%;
}

@media (max-width: 768px) {
  .box-hours-milles-classes-left {
    width: 100% !important;
  }
}
.comment {
  color: #a1a1a1;
  font-weight: 400;
}

.likes {
  width: 1rem;
}

.triangles {
  width: 0.8rem;
}

/* .profile-info {
  border-radius: 4.2617960426vh;
} */

/*estilos para el h4 y los iconos de lapiz y disquete*/
.profile-info h4.titles-sections-info,
.profile-info i {
  color: #64949a !important;
  opacity: 0.68;
}

.profile-info h4.titles-sections-info {
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
}

.profile-info .container-personal-info-and-account-info {
  background-color: #fff;
  border-radius: 1.75rem !important;
  padding: 4rem;
}

/*estilos a la clase de los elementos label*/
.profile-info .container-personal-info-and-account-info .label-element {
  color: #949494;
  opacity: 0.5;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh;
  font-weight: 400;
}

.profile-info .container-personal-info-and-account-info .item-personal-info {
  margin-bottom: 2.5rem !important;
}

.profile-info .container-personal-info-and-account-info .item-personal-info:nth-child(4) .box-city-state-zip {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;
}

.profile-info .item-personal-info .field-full-last-name-mobil {
  display: none;
}

.personal-detail {
  background-color: #F8F8F8;
}
/*estilos al icono que esta dentro del componente Navbar que esta dentro del componente AccountIinformationPageStudent*/
.personal-detail .icon-user-header {
  background-color: #fff !important;
}

/* estilo para tener mismo espacio entre elementos del navbar modo pc */
.personal-detail .myOrka-link {
  margin-left: 0.8rem;
  margin-right: 1rem;
}

.personal-detail .container-name-profile-student-mobil {
  display: none;
}

.personal-detail .container-name-profile-student-pc {
  display: block;
}

.personal-detail .edicion-text-box {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh !important;
  font-weight: 400 !important;
}

.personal-detail .txt-change-password {
  color: #949494;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh;
  font-weight: 600;
  margin-top: 0.6rem;
}

/*estilos para el componente de help*/
.personal-detail .box-help-mobil-account-information-page-student {
  display: none;
}

@media (max-width: 768px) {
  .profile-info h4.titles-sections-info,
  .profile-info .container-personal-info-and-account-info i {
    color: #949494 !important;
    font-size: 2.2831050228vh !important;
  }
  /*estilos a los labels*/
  .profile-info .container-personal-info-and-account-info .label-element {
    font-size: 1.8264840183vh;
  }
  /*estilos a las letras de los campos de texto*/
  .personal-detail .edicion-text-box {
    font-size: 1.8264840183vh !important;
  }
  /*estilos a la opcion de cambiar contraseña*/
  .personal-detail .txt-change-password {
    font-size: 1.8264840183vh;
  }
  .personal-detail #Linkstaticob {
    padding-right: 0.375rem;
  }
  .personal-detail .navBar-link-home {
    display: inline-block;
  }
  .personal-detail .container-header-myOrka {
    background-color: #c0d1d5;
  }
  .personal-detail .container-name-profile-student-pc {
    display: none;
  }
  .personal-detail .container-name-profile-student-mobil {
    border-radius: 0 0 1.8rem 1.8rem;
    display: block;
  }
  .profile-info {
    background-color: #fff !important;
  }
  .profile-info .container-personal-info-and-account-info {
    padding: unset;
  }
  /*cambiar el background de imagen de user del header*/
  .personal-detail .icon-user-header {
    background-color: #c0d1d5 !important;
  }
  .personal-detail .container-name-profile-student-mobil .nav-user-icon {
    background-color: rgba(142, 178, 188, 0.2196078431) !important;
  }
  /*quitarle los margin a loa lados al navbar*/
  .personal-detail .my-orka-nav-bar {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  /* estilo para tener mismo espacio entre elementos del navbar modo mobil */
  .personal-detail .myOrka-link {
    margin-right: 1.2rem;
    margin-left: 0;
  }
  .profile-info .container-personal-info-and-account-info .item-personal-info:nth-child(4) {
    justify-content: unset;
  }
  /*ocultar el campo de texto de pc y mostrar el de mobil dentro de otra caja*/
  .profile-info .item-personal-info .field-full-last-name-pc {
    display: none;
  }
  .profile-info .item-personal-info .field-full-last-name-mobil {
    display: block;
  }
  /*estilos para el componente de help*/
  .personal-detail .box-help-mobil-account-information-page-student {
    color: #64949a !important;
    display: block;
    margin-top: 16rem;
    margin-bottom: 2rem;
    margin-left: -1rem;
  }
  .personal-detail .box-help-mobil-account-information-page-student .helpBox {
    margin-left: -8px;
    margin-bottom: 0.5rem;
  }
}

/*estilo para la caja de gris*/
/* .container-general-account-information-page-instructor{
    border-radius: 4.26179604261796vh; //28px;
} */
/* .box-general-account-information-page-instructor .container-profile-contents{
    display: none;        
}

//estilos para mostrar el menu de pc
.box-general-account-information-page-instructor .container-name-profile-instructor-pc{
    display: block;    
} */
/*elementos del menu de pc (estos estilos estan en el componente pero ahora los puse aqui por temas de comodidad)*/
.container-general-account-information-page-instructor .hi-there {
  color: #48676F;
  opacity: 0.68;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 3.0441400304vh !important;
  margin-bottom: 0.5rem;
}

.container-general-account-information-page-instructor .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.3272450533vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.container-personal-info-and-account-info-instructor {
  background-color: #fff;
  /* border-radius: 4.2617960426vh; */
  padding-top: 4rem;
  padding-right: 4rem !important;
  padding-bottom: 4rem;
  padding-left: 4rem !important;
}

/*estilos a la clase de los elementos label*/
.container-personal-info-and-account-info-instructor .label-element {
  color: #949494;
  opacity: 0.5;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh;
  font-weight: 400;
}

.container-personal-info-and-account-info-instructor .item-personal-info {
  margin-bottom: 2.5rem !important;
}

.container-personal-info-and-account-info-instructor .item-personal-info:nth-child(2) {
  display: flex;
  justify-content: space-between;
}

.container-personal-info-and-account-info-instructor .item-personal-info:nth-child(3) {
  display: flex;
  justify-content: space-between;
}

.container-personal-info-and-account-info-instructor .item-personal-info:nth-child(4) {
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
}

.container-personal-info-and-account-info-instructor .item-personal-info:nth-child(4) .box-input-address {
  /* background-color: green; */
  flex: 0 0 calc(33% - 1rem);
}

.container-personal-info-and-account-info-instructor .item-personal-info:nth-child(4) .box-city-state-zip {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;
}

/* .profile-info .container-personal-info-and-account-info .item-personal-info:nth-child(4) .box-city-state-zip{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 1.2rem;
} */
/*espacio en cada caja de inputs*/
.container-general-account-information-page-instructor .container-personal-info-and-account-info-instructor .item-personal-info {
  margin-bottom: 2.5rem !important;
}

.container-general-account-information-page-instructor .item-personal-info .field-full-last-name-mobil {
  display: none;
}

/*estilos al icono que esta dentro del componente Navbar que esta dentro del componente AccountIinformationPageStudent mas bien de instructor*/
.box-general-account-information-page-instructor .icon-user-header {
  background-color: #fff !important;
}

.box-general-account-information-page-instructor .myOrka-link {
  margin-left: 0.8rem;
  margin-right: -1rem;
}

/*estilos al texto dentro de los campos de texto*/
.box-general-account-information-page-instructor .edicion-text-box {
  color: #949494;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh;
  font-weight: 400;
}

.box-general-account-information-page-instructor .txt-change-password {
  color: #949494;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh;
  font-weight: 600;
}

.container-personal-info-and-account-info-instructor .field-password {
  position: relative;
}

.container-personal-info-and-account-info-instructor .field-password .eyePassword {
  position: absolute;
  right: 1rem;
  bottom: 0.3rem;
}

/*estilos para el componente de help*/
.box-general-account-information-page-instructor .box-help-information {
  display: none;
}

@media (max-width: 768px) {
  .container-general-account-information-page-instructor h4.titles-sections-info,
  .container-general-account-information-page-instructor .container-personal-info-and-account-info-instructor i {
    color: #949494 !important;
    font-size: 2.2831050228vh !important;
  }
  .container-personal-info-and-account-info-instructor {
    padding-left: unset !important;
    padding-right: unset !important;
  }
  .container-personal-info-and-account-info-instructor .label-element {
    font-size: 1.8264840183vh;
  }
  .box-general-account-information-page-instructor .edicion-text-box {
    font-size: 1.8264840183vh;
  }
  .box-general-account-information-page-instructor #Linkstaticob {
    margin-right: 1.4rem;
    margin-left: 0;
  }
  .box-general-account-information-page-instructor .myOrka-link {
    margin-right: 1.2rem;
    margin-left: 0;
  }
  .box-general-account-information-page-instructor .navBar-link-home {
    display: inline-block;
  }
  .box-general-account-information-page-instructor .container-header-myOrka {
    background-color: #c0d1d5;
  }
  .container-personal-info-and-account-info-instructor .item-personal-info .field-full-last-name-pc {
    display: none;
  }
  .container-personal-info-and-account-info-instructor .item-personal-info .field-full-last-name-mobil {
    display: block;
  }
  .container-personal-info-and-account-info-instructor {
    padding: 3rem;
  }
  .container-personal-info-and-account-info-instructor .item-personal-info:nth-child(2) {
    gap: 1rem;
    justify-content: unset;
  }
  .container-personal-info-and-account-info-instructor .item-personal-info:nth-child(3) {
    justify-content: unset;
    gap: 1rem;
  }
  .container-personal-info-and-account-info-instructor .item-personal-info:nth-child(4) {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .container-personal-info-and-account-info-instructor .item-personal-info:nth-child(4) .box-input-address {
    /* background-color: green; */
    flex-basis: 100%;
  }
  .box-general-account-information-page-instructor .icon-user-header {
    background-color: #c0d1d5 !important;
  }
  .box-general-account-information-page-instructor .my-orka-nav-bar {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  .box-general-account-information-page-instructor .container-profile-contents .hi-there {
    margin-top: 0.8rem;
  }
  .box-general-account-information-page-instructor .container-profile-contents .full-name-person {
    color: #64949a !important;
    margin-top: -0.5rem;
  }
  .container-general-account-information-page-instructor {
    background-color: #fff !important;
  }
  .container-personal-info-and-account-info-instructor .field-password {
    margin-top: 1rem;
  }
  .container-personal-info-and-account-info-instructor .field-password .eyePassword {
    color: #64949a;
    right: 1.6rem;
  }
  .box-general-account-information-page-instructor .box-help-information {
    color: #64949a !important;
    display: block;
    margin-top: 8rem;
    margin-bottom: 2rem;
    margin-left: -1rem;
  }
  .box-general-account-information-page-instructor .box-help-information .helpBox {
    margin-left: -8px;
    margin-bottom: 0.5rem;
  }
}
.personal-detail .icon-user {
  color: #e9a535;
}


.profile-info .container-personal-info-and-account-info-student {
  background-color: #fff;
  border-radius: 1.75rem !important;
  padding: 4rem;
}

/*estilos a la clase de los elementos label*/
.container-personal-info-and-account-info-student .label-element {
  color: #949494;
  opacity: 0.5;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh;
  font-weight: 400;
}

.container-personal-info-and-account-info-student .item-personal-info {
  margin-bottom: 2.5rem !important;
}

.container-personal-info-and-account-info-student .item-personal-info:nth-child(2) {
  display: flex;
  /* background-color: #e1e1e1; */
  justify-content: space-between;
}

.container-personal-info-and-account-info-student .item-personal-info:nth-child(3) {
  display: flex;
  justify-content: space-between;
}

.container-personal-info-and-account-info-student .item-personal-info:nth-child(4) {
  /* background-color: orange; */
  display: flex;
  justify-content: space-between;
  gap: 1.2rem;
}

.container-personal-info-and-account-info-student .item-personal-info:nth-child(4) .box-input-address {
  /* background-color: green; */
  flex: 0 0 calc(33% - 1rem);
}

.container-personal-info-and-account-info-student .item-personal-info:nth-child(4) .box-city-state-zip {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1.2rem;
}

.container-personal-info-and-account-info-student .item-personal-info .field-full-last-name-mobil {
  display: none;
}

/*estilos al icono que esta dentro del componente Navbar que esta dentro del componente AccountIinformationPageStudent*/
.personal-detail .icon-user-header {
  background-color: #fff !important;
}

/* estilo para tener mismo espacio entre elementos del navbar modo pc */
.personal-detail .myOrka-link {
  margin-left: 0.8rem;
  margin-right: -1rem;
}

.personal-detail .container-name-profile-student-mobil {
  display: none;
}

.personal-detail .container-name-profile-student-pc {
  display: block;
}

.personal-detail .edicion-text-box {
  color: #949494;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh;
  font-weight: 400;
}

.personal-detail .txt-change-password {
  color: #949494;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh;
  font-weight: 600;
  margin-top: 0.6rem;
}

.container-personal-info-and-account-info-student .field-password {
  position: relative;
}

.container-personal-info-and-account-info-student .field-password .eyePassword {
  position: absolute;
  right: 1rem;
  bottom: 0.3rem;
}

/*estilos para el componente de help*/
.personal-detail .box-help-mobil-account-information-page-student {
  display: none;
}

@media (max-width: 768px) {
  .profile-info h4.titles-sections-info,
  .profile-info .container-personal-info-and-account-info-student i {
    color: #949494 !important;
    font-size: 2.2831050228vh !important;
  }
  .profile-info .container-personal-info-and-account-info-student .label-element {
    font-size: 1.8264840183vh;
  }
  .personal-detail .edicion-text-box {
    font-size: 1.8264840183vh;
  }
  .personal-detail #Linkstaticob {
    padding-right: 0;
  }
  .personal-detail .navBar-link-home {
    display: inline-block;
  }
  .personal-detail .container-header-myOrka {
    background-color: #c0d1d5;
  }
  .container-personal-info-and-account-info-student .item-personal-info .field-full-last-name-pc {
    display: none;
  }
  .container-personal-info-and-account-info-student .item-personal-info .field-full-last-name-mobil {
    display: block;
  }
  .profile-info .container-personal-info-and-account-info-student {
    padding: unset;
  }
  .container-personal-info-and-account-info-student .item-personal-info:nth-child(2) {
    gap: 1rem;
    justify-content: unset;
  }
  .container-personal-info-and-account-info-student .item-personal-info:nth-child(3) {
    justify-content: unset;
    gap: 1rem;
  }
  .container-personal-info-and-account-info-student .item-personal-info:nth-child(4) {
    justify-content: unset;
  }
  .container-personal-info-and-account-info-student .item-personal-info:nth-child(4) {
    /* background-color: orange; */
    display: flex;
    justify-content: space-between;
    gap: 1rem;
    flex-wrap: wrap;
  }
  .container-personal-info-and-account-info-student .item-personal-info:nth-child(4) .box-input-address {
    /* background-color: green; */
    flex-basis: 100%;
  }
  .container-personal-info-and-account-info-student .field-password {
    margin-top: 1rem;
  }
  .container-personal-info-and-account-info-student .field-password .eyePassword {
    right: 1.6rem;
    color: #64949a;
  }
  .personal-detail .box-help-mobil-account-information-page-student {
    color: #64949a !important;
    display: block;
    margin-top: 8rem;
    margin-bottom: 2rem;
    margin-left: -1rem;
  }
  .personal-detail .box-help-mobil-account-information-page-student .helpBox {
    margin-left: -8px;
    margin-bottom: 0.5rem;
  }
  
}

/*ocultar header de vista mobil */
.container-general-finances-configuration .container-finances .header-finances-mobil {
  color: rgba(107, 140, 149, 0.68) !important;
  display: none;
}

.finances-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.header-finances-pc {
  color: rgba(107, 140, 149, 0.68) !important;
  margin: 0;
  padding: 0;
  width: 30%;
}

.container-general-finances-configuration .container-finances .box-before-document-missing-pc {
  display: block;
  padding: 0;
  width: 65%;
  margin-bottom: 12px;
}

.container-general-finances-configuration .container-finances .box-before-document-missing-mobil {
  display: none;
}

.container-general-finances-configuration .container-finances .box-general-document-missing {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
}

.container-general-finances-configuration .container-finances .box-general-document-missing p {
  color: #e9a535 !important;
  font-size: 16px !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 400;
  margin: 0 !important;
}

.container-finances .how-text {
  color: #a1a1a1;
  font-family: Roboto, sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 1rem;
  opacity: 0.4;
}

.container-finances .box-checks {
  display: flex;
}

/*definicion de espacio entre las cajas*/
.container-finances .box-checks .box-check {
  flex-basis: 35%;
}

/*espacio entre el input radio y el label*/
.container-finances .box-checks .box-check > input {
  display: inline-block;
  margin-right: 0.5rem;
}

.box-general-no-payment-information-on-file {
  display: flex;
  justify-content: space-between;
  margin-top: 2rem;
}

/*estilos a la caja de ...*/
.box-general-no-payment-information-on-file .box-texts {
  width: 35%;
}

.box-general-no-payment-information-on-file .box-texts p {
  margin-bottom: 0;
}

.box-general-no-payment-information-on-file .no-payment {
  color: rgba(161, 161, 161, 0.4); /*#A1A1A1 con 40% de opacidad*/
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 2.4353120244vh;
}

.container-general-finances-configuration .container-content > div {
  margin-left: 0;
  margin-top: 0;
}

.container-general-finances-configuration .container-content .add-bank {
  color: rgba(107, 140, 149, 0.68);
  text-decoration: underline !important;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1rem;
  margin-left: 2rem;
	text-transform: none;
}

.container-general-finances-configuration .box-performance {
  margin-top: 3rem;
}

.container-general-finances-configuration .box-performance h5 {
  color: rgba(107, 140, 149, 0.68) !important;
}

.box-performance .button-earnings-summary {
  background-color: rgba(107, 140, 149, 0.37);
  border-radius: 0.625rem; /* 10px; */
  color: rgba(96, 136, 147, 0.68);
  display: inline-block;
  font-family: Roboto, sans-serif !important;
  font-size: 1.2rem; /* 14px; */
  font-weight: 600 !important;
  padding-left: 0.9375rem; /* 15px; */
  padding-right: 0.9375rem; /* 15px; */
  padding-top: 0.25rem; /*4px*/
  padding-bottom: 0.25rem; /*4px*/
  margin-left: 2rem;
  margin-top: 0.7rem;
}


.box-general-no-payment-information-on-file .text-link-your-bank-account {
  display: none;
}

.box-general-no-payment-information-on-file .box-form-add-bank {
  background-color: rgba(142, 178, 188, 0.5);
  border-radius: 1.5220700152vh;
  width: 60%;
  padding: 2rem;
}
.box-general-no-payment-information-on-file .box-form-add-bank .box-input-account-holder input {
  width: 100%;
}

.box-general-no-payment-information-on-file .box-inputs-account-number-and-routing-number {
  display: flex;
  margin-top: 1rem;
  gap: 1rem;
}

.box-general-no-payment-information-on-file .box-inputs-account-number-and-routing-number input {
  width: 100%;
}

/*estilos a la caja del boton*/
.box-form-add-bank .box-button {
  margin-top: 1rem;
  text-align: right;
}

.container-finances .box-buttons .btn-save {
  background-color: #c0d1d5;
  border-radius: 0.9375rem;
  color: #707070;
  font-weight: bold;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

/*estilos al boton*/
.box-form-add-bank .box-button button {
  background-color: rgba(107, 140, 149, 0.37);
  border: none;
  border-radius: 1.2176560122vh;
  color: rgba(107, 140, 149, 0.8);
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-style: normal;
  padding: 0.2rem 1.2rem;
}

.box-general-no-payment-information-on-file .box-input p {
  color: rgba(107, 140, 149, 0.8);
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.box-general-no-payment-information-on-file .box-input input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #64949a;
}

.box-general-no-payment-information-on-file .box-form-add-bank h4.header-add-bank-information {
  color: rgba(107, 140, 149, 0.8) !important;
}

.box-general-no-payment-information-on-file .box-form-add-bank h4.header-link-your-bank-account {
  display: none;
}

.container-general-inputs-finances-configuration {
  margin-top: 2rem;
}

.container-finances .container-general-inputs-finances-configuration .text-tax-information {
  color: rgba(107, 140, 149, 0.68) !important;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-style: normal;
}

.container-general-inputs-finances-configuration .text-billing {
  display: none;
}

.container-finances .container-inputs-finances-configuration {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
}

.container-finances .container-inputs-finances-configuration > div {
  flex-basis: 30%;
}

.container-inputs-finances-configuration-address-city-state-zip {
  display: flex;
  margin-top: 2rem;
  margin-bottom: 2rem;
  gap: 1rem;
}

.container-inputs-finances-configuration-address-city-state-zip .box-address {
  width: 40%;
}

.container-inputs-finances-configuration-address-city-state-zip .box-city-state-zip {
  width: 60%;
  display: flex;
  gap: 1rem;
}

.container-finances .box-buttons {
  display: flex;
  gap: 1rem;
}

@media (max-width: 768px) {
  .header-finances-pc {
    display: none;
  }
  .container-general-finances-configuration .container-finances .box-before-document-missing-pc {
    display: none;
  }
  .container-general-finances-configuration .container-finances .box-before-document-missing-mobil {
    display: block;
  }
  .container-general-finances-configuration .container-finances .header-finances-mobil {
    display: block;
    margin-top: 1rem;
  }
  /*estilo para que se oculte el menu de pc*/
  .container-general-finances-configuration .container-name-profile-student-pc {
    display: none;
  }
  /*definicion de espacio entre las cajas*/
  .container-finances .box-checks .box-check {
    flex-basis: 50%;
  }
  /*======================================================*/
  /*estilos al formulario*/
  .box-general-no-payment-information-on-file {
    display: unset;
  }
  /*cambiar el tamaño a la caja de los textos*/
  .box-general-no-payment-information-on-file .box-texts {
    width: 100%;
  }
  /*quitar el texto de no-payment*/
  .box-general-no-payment-information-on-file .no-payment {
    /* font-size: 1.97869101978691vh; */
    display: none;
  }

  .box-general-no-payment-information-on-file .text-link-your-bank-account {
    display: block;
  }
  /*======================================*/
  .container-finances .box-buttons .btn-save {
    background-color: transparent;
    border-radius: unset;
    color: #707070;
    font-weight: bold;
    text-decoration: underline;
    padding-left: unset;
    padding-right: unset;
  }
  /*======================================*/
  .box-general-no-payment-information-on-file .box-form-add-bank {
    background-color: unset;
    border-radius: unset;
    margin-top: 1rem;
    width: 100%;
    padding: unset;
  }
  /*estilos a la caja del boton*/
  .box-form-add-bank .box-button {
    text-align: left;
  }
  /*ocultar el boto de request earning summary*/
  .container-finances #BtnFinances {
    display: none;
  }
  .box-general-no-payment-information-on-file .box-input p {
    display: none;
  }
  .container-general-inputs-finances-configuration .container-inputs-finances-configuration .box-middle,
  .container-general-inputs-finances-configuration .container-inputs-finances-configuration .box-ssn,
  .container-general-inputs-finances-configuration .container-inputs-finances-configuration .box-input-ssn {
    display: none;
  }
  /*ocultar el header de tax information*/
  .container-general-inputs-finances-configuration .text-tax-information {
    display: none;
  }
  /*mostrar el texto de billing*/
  .container-general-inputs-finances-configuration .text-billing {
    display: block;
  }
  .box-general-no-payment-information-on-file .box-input input {
    border-bottom: 1px solid #949494;
  }
  .box-general-no-payment-information-on-file .box-input input::placeholder {
    color: #a1a1a1;
  }
  .box-general-no-payment-information-on-file .box-form-add-bank h4.header-add-bank-information {
    display: none;
  }
  /*======================================================*/
  .container-finances .container-inputs-finances-configuration {
    gap: 1rem;
  }
  .container-finances .container-inputs-finances-configuration label {
    display: none;
  }
  .container-finances .container-inputs-finances-configuration input::placeholder {
    color: #a1a1a1;
  }
  .container-finances .container-inputs-finances-configuration > div {
    flex-basis: 45%;
  }
  /*estilos para poner esta caja sin un display*/
  .container-inputs-finances-configuration-address-city-state-zip {
    display: block;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .container-inputs-finances-configuration-address-city-state-zip label {
    display: none;
  }
  .container-inputs-finances-configuration-address-city-state-zip .box-address {
    width: 100%;
  }
  .container-inputs-finances-configuration-address-city-state-zip .box-city-state-zip {
    width: 100%;
  }
  .container-inputs-finances-configuration-address-city-state-zip .box-city-state-zip .box-field {
    margin-top: 1.4rem;
  }
  .container-inputs-finances-configuration-address-city-state-zip .box-address input::placeholder {
    color: #a1a1a1;
  }
  .container-inputs-finances-configuration-address-city-state-zip .box-city-state-zip input::placeholder {
    color: #a1a1a1;
  }
}
/*estilos a la caja de payment*/
.container-general-payment {
  border-radius: 4.2617960426vh;
}

.container-general-payment .container-payment {
  background-color: #FFF;
  border-radius: 1.75rem !important;
  display: flex;
  padding: 4rem;
}

/*estilos a textos del menu*/
.container-general-payment .hi-there {
  color: #48676F;
  font-size: 25px;
  margin-bottom: 0.5rem;
}

.container-general-payment .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.3272450533vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.container-general-payment .container-payment .header-payment {
  color: #64949a !important;
  font-size: 3.0441400304vh !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700 !important;
  opacity: 0.68;
}

.container-general-payment .spacious {
  padding: 3rem;
}

.container-general-payment .container-payment .link-add-card {
  margin-top: 0.9rem;
}

.container-general-payment .container-payment .text-payment {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh !important;
  font-weight: 400;
  margin-top: -0.3rem;
  opacity: 0.5;
}

.container-general-payment .container-payment .link-add-a-card {
  color: #64949a;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh !important;
  font-weight: 600;
}

/*estilos al formulario de stripe version PC*/
.container-payment .box-form-stripe-pc {
  background-color: rgba(142, 178, 188, 0.5);
  border-radius: 1.5220700152vh;
  padding: 2rem;
  margin-left: 3.5rem;
  flex-basis: 90%;
}

.container-payment .box-form-stripe-pc .title-new-card {
  color: #64949a !important;
  opacity: 0.8;
  font-family: Roboto, sans-serif !important;
  font-size: 3.0441400304vh !important;
  font-weight: 700;
  margin-bottom: 2rem;
}

/*estilos al formulario de stripe version PC*/
/* estilo para tener mismo espacio entre elementos del navbar modo pc */
.box-general-payment .myOrka-link {
  margin-left: 0.8rem;
  margin-right: -1rem;
}

/*estilos a la caja del componente help*/
.box-general-payment .box-help-mobil-payment {
  display: none;
}

/*estilos al componente del menuProfile normal (pc)*/
.box-general-submit-document-student .container-name-profile-student-pc {
  display: block;
}

/*estilos al componete del menuProfileMobil*/
.box-general-payment .container-name-profile-student-mobil {
  display: none;
}

/*establecerle background a la imagen de usuario*/
.box-general-payment .icon-user-header {
  background-color: #fff !important;
}

/*estilos para mostrar/ocultar cajas de add card*/
.box-button-to-paymentRecord {
  display: block;
}

.box-button-to-paymentRecord-mobil {
  display: none;
}

/*estilos para que se oculte el formulario mobil de guardar tarjeta*/
.box-form-stripe-mobil {
  display: none;
}

@media (max-width: 768px) {
  /*estilos al navbar*/
  .box-general-payment .container-header-myOrka {
    background-color: #c0d1d5;
  }
  /*quitarle el padding left al link de home */
  .box-general-payment #Linkstaticon {
    padding-left: 0;
  }
  /*quitarle el padding right al link de myOrka*/
  .box-general-payment #Linkstaticob {
    padding-right: 0;
  }
  /*establecerle background a la imagen*/
  .box-general-payment .icon-user-header {
    background-color: #c0d1d5 !important;
  }
  .container-general-payment .spacious {
    padding: unset;
  }
  /*estilos para que se oculte el menu de pc*/
  .box-general-payment .container-name-profile-student-pc {
    display: none;
  }
  /*estilos para que aparesca el menu mobil*/
  .box-general-payment .container-name-profile-student-mobil {
    border-radius: 0 0 1.8rem 1.8rem;
    display: block;
  }
  /*establecer background a la imagen del usuario*/
  .box-general-payment .container-name-profile-student-mobil .nav-user-icon {
    background-color: rgba(142, 178, 188, 0.2196078431) !important;
  }
  /*quitarle los margin a loa lados al navbar*/
  .box-general-payment .my-orka-nav-bar {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  /* estilo para tener mismo espacio entre elementos del navbar modo mobil */
  .box-general-payment .myOrka-link {
    margin-right: 1.2rem;
    margin-left: 0;
  }
  /*establecer background a la caja*/
  .container-general-payment {
    background-color: #fff !important;
  }
  /*quitarle espacio y border a la caja seleccionada*/
  .container-general-payment .container-payment {
    border-radius: unset;
    padding: 0;
    flex-direction: column;
  }
  .box-form-stripe-mobil {
    display: block;
  }
  .container-general-payment .container-payment .header-payment {
    font-size: 2.2831050228vh !important;
    color: #a1a1a1 !important;
  }
  .container-general-payment .container-payment #Padding4x4 {
    padding: 0;
  }
  /*estilos a la caja del componente help*/
  .box-general-payment .box-help-mobil-payment {
    color: #64949a !important;
    display: block;
    margin-top: 15rem;
    margin-bottom: 2rem;
    margin-left: -1rem;
  }
  /*darle ubicacion al mensaje de help*/
  .box-general-payment .box-help-mobil-payment .helpBox {
    margin-left: -3px;
    margin-bottom: 0.5rem;
  }
  /*estilos para mostrar/ocultar cajas de add card*/
  .box-button-to-paymentRecord {
    display: none;
  }
  .box-button-to-paymentRecord-mobil {
    display: block;
  }
}
.container-general-reviews {
  border-radius: 4.2617960426vh;
}

.container-general-reviews .container-row {
  padding: 3rem;
}

.container-general-reviews .hi-there {
  color: #48676F;
  opacity: 0.68;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 3.0441400304vh;
  margin-bottom: 0.5rem;
}

.container-general-reviews .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.32725vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.container-general-reviews .container-reviews {
  background-color: #fff;
  height: 95vh;
  border-radius: 1.75rem !important;
  padding: 4rem 4rem 0 4rem;
}

.container-general-reviews .container-reviews .header-reviews {
  color: rgba(107, 140, 149, 0.68) !important;
  font-family: Roboto, sans-serif !important;
  font-size: 3.0441400304vh !important;
  font-weight: 700 !important;
}

.container-general-reviews .container-reviews .text-reviews {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1.82648vh !important;
  font-weight: 400;
  margin-top: -0.3rem;
  opacity: 0.5;
}

.container-general-reviews .content-reviews {
  overflow-y: scroll;
  overflow-x: hidden;
  height: 70%;
}

.box-general-reviews .box-help-mobil-reviews {
  display: none;
}

@media (max-width: 768px) {
  .container-general-reviews .container-reviews {
    height: 70vh;
    padding: unset;
  }
  .container-general-reviews .container-reviews .header-reviews {
    display: none;
  }
  .container-general-reviews .container-row {
    padding: unset;
  }
  .container-general-reviews .container-name-profile-student-pc {
    display: none;
  }
  .box-general-reviews .box-help-mobil-reviews {
    color: #64949a !important;
    display: block;
    margin-top: 8rem;
    margin-bottom: 2rem;
  }
  .box-help-mobil-reviews .help {
    left: 2rem;
  }
  .box-general-reviews .box-help-mobil-reviews .helpBox {
    margin-left: 0rem;
    margin-bottom: 0.5rem;
  }
}
.container-general-safety {
  border-radius: 4.2617960426vh;
}

/*para que se muestre el menu de pc*/
.box-general-safety .container-name-profile-student-pc {
  display: block;
}

/*para que se oculte el menu mobil*/
.box-general-safety .container-name-profile-student-mobil {
  display: none;
}

/*elementos del menu de pc (estos estilos estan en el componente pero ahora los puse aqui por temas de comodidad)*/
.container-general-safety .hi-there {
  color: #48676F;
  opacity: 0.68;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 3.0441400304vh;
  margin-bottom: 0.5rem;
}

.container-general-safety .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.3272450533vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.container-general-safety .myOrka-link {
  margin-left: 0.8rem;
  margin-right: -1rem;
}

.box-general-safety .container-row {
  padding: 3rem;
}

.container-general-safety .container-safety {
  background-color: #fff;
  border-radius: 1.75rem !important;
  padding: 4rem;
}

/* estilo para tener mismo espacio entre elementos del navbar modo pc */
.box-general-safety .myOrka-link {
  margin-left: 0.8rem;
  margin-right: -1rem;
}

.container-general-safety .container-safety h4.header-safety {
  color: #64949a !important;
  font-family: Roboto, sans-serif !important;
  font-size: 3.0441400304vh !important;
  font-weight: 700 !important;
  opacity: 0.68;
}

/* .break-mobil{
    display: none;
} */
.container-general-safety .container-safety p.text-safety {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400;
  padding-right: 0;
  margin-top: -0.3rem;
  opacity: 0.5;
}

.container-general-safety .container-safety .text-safety-span {
  font-family: Roboto, sans-serif !important;
  font-weight: 600 !important;
}

.container-general-safety .container-safety h5.header-unsafe-activity {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh !important;
  font-weight: 400 !important;
  margin-top: 2rem;
  margin-left: 2.8rem;
}

.container-general-safety .container-select-and-textarea {
  margin: 0 0 0 auto;
  width: 88%;
}

.container-general-safety .custom-select-safety {
  background-color: #d10000;
  border: 0.5px solid rgba(112, 112, 112, 0.2);
  border-radius: 0.9132420091vh !important;
  color: #949494;
  /* 
  opacity: 0.40; */
  /* color: rgba(152, 152, 152, 0.40); */
  font-size: 2.2831050228vh;
  font-family: Roboto, sans-serif !important;
  font-weight: 400 !important;
  margin-top: 2rem;
  margin-bottom: 1.2rem;
}

.container-general-safety .custom-select-safety::placeholder {
  color: rgba(152, 152, 152, 0.4);
}

.container-general-safety select.custom-select-safety option:hover {
  background-color: #c0d1d5 !important;
}

.container-general-safety .custom-select-safety::after {
  margin-right: 5rem;
}

.container-general-safety .textarea-safety {
  border: 0.5px solid rgba(112, 112, 112, 0.2);
  border-radius: 0.9132420091vh;
}

.container-general-safety .textarea-safety::placeholder {
  color: rgba(152, 152, 152, 0.4);
  font-size: 2.2831050228vh;
}

.container-general-safety .box-button {
  margin-top: 1.2rem;
  text-align: right;
}

.box-general-safety .box-help-mobil-safety {
  display: none;
}

@media (max-width: 768px) {
  /*para que se oculte el menu de pc*/
  .box-general-safety .container-name-profile-student-pc {
    display: none;
  }
  /*para que se muestre el menu mobil*/
  .box-general-safety .container-name-profile-student-mobil {
    display: block;
  }
  .box-general-safety .container-header-myOrka {
    background-color: #c0d1d5;
  }
  .container-general-safety .container-safety {
    padding: 3rem;
  }
  /*quitarle los margin a loa lados al navbar*/
  .box-general-safety .my-orka-nav-bar {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  /* estilo para tener mismo espacio entre elementos del navbar modo mobil */
  .box-general-safety .myOrka-link {
    margin-right: 1.2rem;
    margin-left: 0;
  }
  /*color de fondo de  la imagen grande del usuario*/
  .box-general-safety .container-name-profile-student-mobil .nav-user-icon {
    background-color: rgba(59, 107, 120, 0.2196078431) !important;
  }
  .box-general-safety .container-row {
    padding: unset;
  }
  .container-general-safety .container-safety h5.header-unsafe-activity {
    margin-left: unset;
  }
  .container-general-safety .container-select-and-textarea {
    width: 100%;
  }
  /**/
  .box-general-safety .box-help-mobil-safety {
    color: #64949a !important;
    display: block;
    margin-top: 8rem;
    margin-bottom: 2rem;
  }
  /* .break-mobil{
      display: inline;
  } */
  /* .container-general-safety .container-safety p.text-safety{
      padding: 0!important;
  } */
  /* .container-general-safety .container-safety{ 
      font-size: 1.82648401826484vh!important; // 12px
  } */
  .container-general-safety .textarea-safety::placeholder {
    color: rgba(152, 152, 152, 0.4);
    font-size: 1.8264840183vh;
  }
  .box-help-mobil-safety .help {
    left: 2rem;
  }
  .box-general-safety .box-help-mobil-safety .helpBox {
    margin-left: 0rem;
    margin-bottom: 0.5rem;
  }
}

.container-general-submit-document-instructor {
  border-radius: 4.2617960426vh;
}

/*para que se muestre el menu de pc*/
.container-general-submit-document-instructor .container-name-profile-instructor-pc {
  display: block;
}

/*para que se oculte el menu mobil*/
.box-general-submit-document-instructor .container-profile-contents {
  display: none;
}

/*elementos del menu de pc (estos estilos estan en el componente pero ahora los puse aqui por temas de comodidad)*/
.container-general-submit-document-instructor .hi-there {
  color: #48676F;
  opacity: 0.68;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 1.3rem !important;
  margin-bottom: 0.5rem;
}

.container-general-submit-document-instructor .spacious {
  padding: 3rem;
}

.container-general-submit-document-instructor .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.3272450533vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.box-general-submit-document-instructor .myOrka-link {
  margin-left: 0.8rem;
  margin-right: -1rem;
}

.box-general-submit-document-instructor .modal-content {
  width: 100vw !important;
}

.container-general-submit-document-instructor .container-personal-documents {
  background-color: #fff;
  border-radius: 1.75rem !important;
  padding: 4rem;
}

/*texto de submit documents*/
.container-general-submit-document-instructor .container-personal-documents h4.titles-sections-info {
  color: #64949a !important;
  font-family: Roboto, sans-serif !important;
  font-size: 3.0441400304vh !important;
  font-weight: 700;
  opacity: 0.68;
}

/*texto de here you can*/
.container-general-submit-document-instructor .container-personal-documents p.text-submit-documents {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh !important;
  font-weight: 400;
  margin-top: -0.3rem;
  opacity: 0.5;
}

/*textos de TSA Check Report y Goverment I.D*/
h5.header-submit-documents {
  color: #949494;
  display: inline-block;
  font-family: Roboto, sans-serif !important;
  font-size: 12px !important;
  font-weight: 700;
  margin-top: 1.6rem;
  margin-right: 1rem;
}

/*margin right a texto de Goverment I.D*/
h5.header-submit-documents.header-goverment {
  margin-right: 1.8rem;
}

/*ocultar los botones de take a photo vista*/
.container-general-submit-document-instructor .container-personal-documents .box-button-photo {
  display: none;
}

/* .box-general-submit-document-instructor .icon-user-header{
    background-color: #fff!important;
} */
.container-personal-documents .box-element-span-document-missing-warning {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

/*reajustar el width en la caja de document missing*/
.box-general-document-missing {
  display: flex;
  align-items: baseline;
}

/*espacio a la derecha a imagen de warning*/
.box-image-warning {
  margin-right: 0.5rem;
}

.container-personal-documents .box-buttons-upload-photo {
  display: flex;
}

.container-personal-documents .box-buttons-upload-photo .box-button-upload {
  background-color: rgba(107, 140, 149, 0.2);
  border-radius: 0.9132420091vh;
  color: #64949a;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh;
  font-weight: 600;
  /* flex-basis: 7.0625rem; //113px; */
  flex-basis: 8.4187408492vw;
}

.container-personal-documents .box-buttons-upload-photo .box-button-upload #IconPadding {
  padding-right: 0.5rem !important;
}

/*estilos para el componente de help*/
.box-general-submit-document-instructor .box-help-mobil-submit-document-instructor {
  display: none;
}

.containerGeneralFileUploaded {
  margin-bottom: 0.5rem;
  width: 32%;
}

.containerFileUploaded {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.containerGeneralFileUploaded .containerFileUploaded .faFile_icon_fontAwesome {
  font-size: 1.3rem;
  font-weight: 700;
}

.containerGeneralFileUploaded .containerFileUploaded .nameFile,
.containerGeneralFileUploaded .containerFileUploaded .optionDelete {
  color: #64949a;
  text-decoration: underline;
}

.containerGeneralFileUploaded .containerFileUploaded .nameFile {
  font-family: Roboto, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  font-style: normal;
}

.containerGeneralFileUploaded .containerFileUploaded .optionDelete {
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 0.9375rem;
  font-weight: 600;
  font-style: normal;
}

/*mostrar y ocultar los menus*/
.container-profile-contents {
  display: none;
}

/*estilos para mostrar el componente generico*/
.container-generic {
  display: block;
}

@media (max-width: 768px) {
  .box-general-submit-document-instructor #Linkstaticob {
    padding-right: 0;
  }
  /*ocultar el menu de pc*/
  .container-general-submit-document-instructor .container-name-profile-instructor-pc {
    display: none;
  }
  /*mostrar el menu mobil*/
  .box-general-submit-document-instructor .container-profile-contents {
    display: block;
  }
  .container-general-submit-document-instructor .spacious {
    padding: unset;
  }
  /*hacer mas pequeño el header el tamaño al header de submit documents*/
  .container-general-submit-document-instructor .container-personal-documents h4.titles-sections-info {
    font-size: 16px !important;
  }
  /*hacer mas pequeño los headers de TSA Check report y goverment I.D*/
  h5.header-submit-documents {
    font-size: 16px !important;
  }
  /*==========================<estilos al navbar>==========================*/
  /*este estilo es para el color de fondo del navbar*/
  .box-general-submit-document-instructor .container-header-myOrka {
    background-color: #c0d1d5;
  }
  /*quitarle los margin a loa lados al navbar*/
  .box-general-submit-document-instructor .my-orka-nav-bar {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  .box-general-submit-document-instructor .container-name-profile-student-mobil {
    border-radius: 0 0 1.8rem 1.8rem;
    display: block;
  }
  /*color de fondo de  la imagen grande del usuario*/
  .box-general-submit-document-instructor .container-profile-contents .nav-user-icon {
    background-color: rgba(59, 107, 120, 0.2196078431) !important;
  }
  /*estilos para el color del usuario*/
  .box-general-submit-document-instructor .container-profile-contents .icon-user-main {
    color: #8db2bc !important;
    border-radius: 5rem;
    display: inline-block;
    padding: 0.1rem;
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  /*separacion arriba de l texto de hi there*/
  .box-general-submit-document-instructor .container-profile-contents .hi-there {
    margin-top: 0.8rem;
  }
  /*color al texto del nombre del usuario y separacion arriba*/
  .box-general-submit-document-instructor .container-profile-contents .full-name-person {
    color: #64949a !important;
    margin-top: -0.5rem;
  }
  /* estilo para tener mismo espacio entre elementos del navbar modo mobil */
  .box-general-submit-document-instructor .myOrka-link {
    margin-right: 1.2rem;
    margin-left: 0;
  }
  /*==========================</estilos al navbar>==========================*/
  .container-general-submit-document-instructor .container-personal-documents {
    padding: unset;
  }
  .container-general-submit-document-instructor {
    background-color: #fff !important;
  }
  /*modifique la propiedad col solo a mobil*/
  .container-general-submit-document-instructor .container-personal-documents .col {
    flex: 0 0 !important;
  }
  .container-personal-documents .box-buttons-upload-photo {
    justify-content: space-around;
  }
  .container-personal-documents .box-buttons-upload-photo .box-inside-button-upload-photo {
    flex-basis: calc(54% - 0.1rem);
    padding: 0rem 0.1rem 0 0.1rem !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  /*se le quito el padding a los campos de texto*/
  .container-personal-documents .box-buttons-upload-photo .box-inside-button-upload-photo#BtnSubmit {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .container-personal-documents .box-buttons-upload-photo .box-button-upload #IconPadding,
  .container-personal-documents .box-buttons-upload-photo .box-button-photo #IconPadding {
    padding-right: 5px !important;
  }
  .container-personal-documents .box-buttons-upload-photo span {
    font-size: 12px !important;
  }
  .container-personal-documents .box-buttons-upload-photo > div {
    /* flex-basis: 100%; */
    flex: 0 0 calc(100% - 1rem);
    /* padding: 0; */
  }
  .container-general-submit-document-instructor .container-personal-documents .box-button-photo {
    display: inline-block;
  }
  /*estilos para el componente de help*/
  .box-general-submit-document-instructor .box-help-mobil-submit-document-instructor {
    color: #64949a;
    display: block;
    margin-top: 8rem;
    margin-bottom: 2rem;
    margin-left: -1rem;
  }
  .box-general-submit-document-instructor .box-help-mobil-submit-document-instructor .helpBox {
    margin-left: -8px;
    margin-bottom: 0.5rem;
  }
  /*mostrar y ocultar los menus*/
  .container-profile-contents {
    display: block;
  }
  /*estilos para mostrar el componente generico*/
  .container-generic {
    display: none;
  }
}

p#text-drag-drop {
  color: #64949a !important;
}

.btnSelectFile {
  padding: 0.5rem 2rem !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 600 !important;
}

.container-general-submit-document-student {
  border-radius: 4.2617960426vh;
}

.box-general-submit-document-student .container-name-profile-student-pc {
  display: block;
}

.box-general-submit-document-student .container-name-profile-student-mobil {
  display: none;
}

.box-name-profile-student-mobil .hi-there {
  color: #48676F;
  font-weight: 600;
}

.container-general-submit-document-student .spacious {
  padding: 3rem;
}

.container-general-submit-document-student .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.3272450533vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.container-general-submit-document-student .hi-there {
  color: #48676F;
  opacity: 0.68;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

.container-general-submit-document-student .container-personal-documents {
  background-color: #fff;
  border-radius: 1.75rem !important;
  padding: 4rem;
}

.container-general-submit-document-student .container-personal-documents h4.titles-sections-info {
  color: #64949a !important;
  font-family: Roboto, sans-serif !important;
  font-size: 3.0441400304vh !important;
  font-weight: 700;
  opacity: 0.68;
}

.container-general-submit-document-student .container-personal-documents p.text-submit-documents {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh !important;
  font-weight: 400;
  margin-top: -0.3rem;
  opacity: 0.5;
}

h5.header-submit-documents {
  color: #949494;
  display: inline-block;
  font-family: Roboto, sans-serif !important;
  font-size: 20px !important;
  font-weight: 700;
  margin-top: 1.6rem;
  margin-right: 1rem;
}

.container-general-submit-document-student .container-personal-documents h5.header-submit-documents.header-goverment {
  margin-right: 1.8rem;
}

.container-general-submit-document-student .container-personal-documents .box-button-photo {
  display: block;
}

.box-general-submit-document-student .icon-user-header {
  background-color: #fff !important;
}

/* estilo para tener mismo espacio entre elementos del navbar modo pc */
.box-general-submit-document-student .myOrka-link {
  margin-left: 0.8rem;
  margin-right: -1rem;
}

.container-personal-documents .box-element-span-document-missing-warning {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

/*reajustar el width en la caja de document missing*/
.box-general-document-missing {
  display: flex;
  align-items: baseline;
}

.box-image-warning {
  margin-right: 0.5rem;
}

.container-personal-documents .box-buttons-upload-photo {
  display: flex;
}

.container-personal-documents .box-buttons-upload-photo .box-button-upload {
  background-color: rgba(107, 140, 149, 0.2);
  border-radius: 0.9132420091vh;
  color: #64949a;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh;
  font-weight: 600;
  flex-basis: 7.0625rem;
}

.container-personal-documents .box-buttons-upload-photo .box-button-upload #IconPadding {
  padding-right: 0.5rem !important;
  height: 15px !important;
  width: 18px !important;
}

/*estilos para el componente de help*/
.box-general-submit-document-student .box-help-mobil-submit-document-student {
  display: none;
}

.containerGeneralFileUploaded {
  margin-bottom: 0.5rem;
  width: 32%;
}

.containerFileUploaded {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.containerGeneralFileUploaded .containerFileUploaded .faFile_icon_fontAwesome {
  font-size: 1.3rem;
  font-weight: 700;
}

.containerGeneralFileUploaded .containerFileUploaded .nameFile,
.containerGeneralFileUploaded .containerFileUploaded .optionDelete {
  color: #64949a;
  text-decoration: underline;
}

.containerGeneralFileUploaded .containerFileUploaded .nameFile {
  font-family: Roboto, sans-serif;
  font-size: 0.9375rem;
  font-weight: 400;
  font-style: normal;
}

.containerGeneralFileUploaded .containerFileUploaded .optionDelete {
  cursor: pointer;
  font-family: Roboto, sans-serif;
  font-size: 0.9375rem;
  font-weight: 600;
  font-style: normal;
}

/*estilos para el componente generico que se muestre en vista pc*/
.container-generic {
  display: block;
}

/*estilos para que se oculte elm menu mobil*/
.container-name-profile-student-mobil {
  display: none;
}

@media (max-width: 768px) {
  .box-general-submit-document-student #Linkstaticob {
    padding-right: 0;
  }
  .box-general-submit-document-student .container-name-profile-student-pc {
    display: none;
  }
  .box-general-submit-document-student .container-name-profile-student-mobil {
    display: block;
  }
  .container-general-submit-document-student .spacious {
    padding: unset;
  }
  /*cambiarle el tamaño al header h4*/
  .container-general-submit-document-student .container-personal-documents h4.titles-sections-info {
    font-size: 2.2831050228vh !important;
  }
  .container-general-submit-document-student .container-personal-documents h5.header-submit-documents {
    font-size: 16px !important;
  }

  .box-general-submit-document-student .container-header-myOrka {
    background-color: #c0d1d5;
  }
  /*cambiar el background de imagen de usuario del header*/
  .box-general-submit-document-student .icon-user-header {
    background-color: #c0d1d5 !important;
  }
  .box-general-submit-document-student .container-name-profile-student-mobil .nav-user-icon {
    background-color: rgba(142, 178, 188, 0.2196078431) !important;
  }
  /*quitarle los margin a loa lados al navbar*/
  .box-general-submit-document-student .my-orka-nav-bar {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  /* estilo para tener mismo espacio entre elementos del navbar modo mobil */
  .box-general-submit-document-student .myOrka-link {
    margin-right: 1.2rem;
    margin-left: 0;
  }
  .box-general-submit-document-student .container-name-profile-student-mobil {
    border-radius: 0 0 1.8rem 1.8rem;
    display: block;
  }
  .container-general-submit-document-student .container-personal-documents {
    padding: unset;
  }
  .container-general-submit-document-student {
    background-color: #fff !important;
  }
  /*modifique la propiedad col solo a mobil*/
  .container-general-submit-document-student .container-personal-documents .col {
    flex: 0 0 !important;
  }
  .container-personal-documents .box-buttons-upload-photo {
    justify-content: space-around;
  }
  /*.container-personal-documents .box-buttons-upload-photo .box-button-upload
  {
      flex-basis: 45%;
      padding: 0.3rem!important;
  }*/
  .container-personal-documents .box-buttons-upload-photo .box-inside-button-upload-photo {
    flex-basis: calc(54% - 0.1rem);
    padding: 0rem 0.1rem 0 0.1rem !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  /*se le quito el padding a los campos de texto*/
  .container-personal-documents .box-buttons-upload-photo .box-inside-button-upload-photo#BtnSubmit {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .container-personal-documents .box-buttons-upload-photo .box-button-upload #IconPadding,
  .container-personal-documents .box-buttons-upload-photo .box-button-photo #IconPadding {
    padding-right: 5px !important;
  }
  .container-personal-documents .box-buttons-upload-photo span {
    font-size: 12px;
  }
  .container-personal-documents .box-buttons-upload-photo > div {
    /* flex-basis: 100%; */
    flex: 0 0 calc(100% - 1rem);
    /* padding: 0; */
  }
  /*estilos para el componente de help*/
  .box-general-submit-document-student .box-help-mobil-submit-document-student {
    color: #64949a;
    display: block;
    margin-top: 8rem;
    margin-bottom: 2rem;
    margin-left: -1rem;
  }
  .box-general-submit-document-student .box-help-mobil-submit-document-student .helpBox {
    margin-left: -8px;
    margin-bottom: 0.5rem;
  }
  /*
      estilos para el template donde se muestra el archivo
  */
  .containerGeneralFileUploaded {
    width: 90%;
  }
  /*estilos para el componente generico que se oculte en vista pc*/
  .container-generic {
    display: none;
  }
  /*estilos para que se muestre el menu mobil*/
  .container-name-profile-student-mobil {
    display: block;
  }
}
.course-my-orka {
  background-color: #fff;
  border-radius: 1.56rem;
  cursor: pointer;
  height: 21vh;
  padding: 1rem 1.5rem;
  margin-top: 1.4rem;
  margin-bottom: 1.4rem;
  width: 100%;
}

.course-my-orka p {
  color: #c0d1d5;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 1.375rem;
  font-style: normal;
  opacity: 0.68;
}

@media (max-width: 768px) {
  .course-my-orka {
    width: 100%;
  }
}
.hask-link-browse-classes {
  color: #858585;
  font-family: Roboto, sans-serif;
  font-size: 0.9375rem;
  font-weight: 700;
  font-style: normal;
  opacity: 0.68;
}

.text-empty-card {
  color: #c0d1d5;
  opacity: 0.68;
  font-size: 1.125rem;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 0;
}
.container-general-finances-configuration {
  border-radius: 4.2617960426vh;
}

/*ocultar el menu mobil*/
.box-general-finances-configuration .container-name-profile-student-mobil {
  display: none;
}

.container-general-finances-configuration .hi-there {
  color: #48676F;
  opacity: 0.68;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 3.0441400304vh;
  margin-bottom: 0.5rem;
}

.container-general-finances-configuration .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.3272450533vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.box-general-finances-configuration .container-row {
  padding: 3rem;
}

.container-general-finances-configuration .container-finances {
  background-color: #fff;
  border-radius: 1.75rem !important;
  padding: 4rem;
}

.box-general-finances-configuration .myOrka-link {
  margin-left: 0.8rem;
  margin-right: 1rem;
}

.container-finances .container-inputs-finances-configuration {
  margin-top: 2rem;
}

.container-general-finances-configuration .container-finances h4.title-section-info {
  color: #64949a !important;
  font-family: Roboto, sans-serif !important;
  font-size: 3.0441400304vh !important;
  font-weight: 700 !important;
  opacity: 0.68;
}

.container-general-finances-configuration .container-finances p.text-finances {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh !important;
  font-weight: 400;
  margin-top: -0.3rem;
  opacity: 0.5;
}

.box-general-finances-configuration .box-help-mobil-finances-configuration {
  display: none;
}

@media (max-width: 768px) {
  .container-general-finances-configuration .container-finances {
    padding: unset;
  }
  .container-general-finances-configuration .container-finances p.text-finances {
    font-size: 1.6742770167vh !important;
    margin-top: 0.2rem;
  }
}

.form-ratings .box-text-ratings p {
  color: #000;
  opacity: 0.68;
  font-family: Roboto, sans-serif;
  font-size: 0.625rem;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 0;
}

.text-area-ratings {
  border: none;
  border-radius: 1rem;
  color: #A1A1A1;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 600;
  font-style: normal;
  padding: 0.5rem 1rem;
  width: 100%;
}

.form-ratings .box-btn-rating {
  text-align: right;
}

.form-ratings .btn-rating {
  background-color: rgba(107, 140, 149, 0.4392156863);
  border-radius: 0.75rem;
  color: #64949a;
  font-family: Roboto, sans-serif;
  font-size: 0.9375rem;
  font-weight: 600;
  font-style: normal;
  padding: 0.1rem 1.5rem;
  opacity: 0.76;
}
.Hithere {
  background: #f8f8f8;
  padding: 55px 60px 80px 60px;
  margin: 35px 90px;
  border-radius: 28px;
}

.hiThere-greeting {
  display: none;
}

.hiThere-options-desktop {
  width: 330px !important;
  max-width: 330px !important;
  min-width: 330px !important;
  margin-right: 20px;
}

.HithereOptions .container {
  min-height: 55vh;
  background-color: #F8F8F8;
  border-radius: 20px 20px 20px 20px;
}

.accordion-button.collapsed {
  background: rgba(107, 140, 149, 0.1) !important;
}

.hithere-menu-btn.btn.btn-primary.btn-lg {
  background-color: #f8f8f8 !important;
  border-left: #f8f8f8 !important;
  border-right: #f8f8f8 !important;
  text-align: left !important;
  font-size: large !important;
  font-weight: bold;
  width: auto;
}

h6.hiThere-greeting-h6 {
  font-size: 18px !important;
  font-family: Roboto !important;
  font-weight: 600 !important;
  color: rgba(72, 103, 111, 0.68) !important;
  margin: 0;
  padding: 0;
}

h2.hiThere-greeting-h2 {
  font-size: 3rem !important;
  font-family: Roboto !important;
  font-weight: 700 !important;
  color: #8db2bc !important;
  line-height: 1.1;
}

.hiThere-greeting-sub {
  font-size: 25px !important;
  font-family: Roboto !important;
  font-weight: 500 !important;
  color: rgba(72, 103, 111, 0.68) !important;
  margin: 15px 0 !important;
  padding: 0 !important;
}

.hithere-options-container-desk {
  width: 80%;
}

@media (max-width: 768px) {
  .Hithere {
    background: rgba(235, 235, 235, 0.48);
    margin: 0;
    border-radius: 0;
    padding: 120px 50px 40px 50px;
  }
}
.DiscountContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  background: #e9a535;
  color: #e9a535;
  border-radius: 28px;
}

.Icon {
  width: 24px;
}

.Icon.trash {
  margin: 0 4px;
  padding: 0 4px;
}

.LessonInfo.LessonHeader.BorderRadius28 {
  text-transform: uppercase;
}

.RightDiscount {
  display: flex;
  flex-direction: column;
}

.DiscountTitle {
  font-size: 110%;
}

.DiscountText {
  font-size: 72%;
}

.LeftDiscount.justify-content-around {
  display: flex;
  justify-content: space-between !important;
  align-items: center;
  width: 45%;
}

.pl-30 {
  padding-left: 30px !important;
}

.DiscountTextAmount {
  padding: 0;
  margin: 0;
  font-size: 23px;
  padding-left: 20px;
}

.DiscountPAmount {
  padding: 0;
  margin: 0;
  font-size: 19px;
}

.CoursePremium {
  padding: 0;
  margin: 0;
  font-size: 18px;
}

.onlineTotalCost {
  align-items: center;
}

.DiscountInputCost {
  width: 30%;
  border: none;
  resize: none;
  background-color: transparent;
  color: #e9a535;
  font-size: 20px;
  line-height: 20px;
  font-weight: 500;
}

.DiscountInputCost::placeholder {
  color: #e9a535;
}

.DiscountInputCost:focus {
  outline: none;
}

.DownDiscount {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 20px 0;
}

.DiscountInputCointainer {
  padding: 0 1rem;
  width: 23%;
  background: #fff;
  border: none;
  border-radius: 10px;
}

.DiscountInputCointainerOnsite {
  padding: 0 1rem;
  background: #fff;
  border: none;
  border-radius: 10px;
}

.DiscountInputPercentage {
  font-size: 20px;
  line-height: 20px;
  text-align: end;
  border: none;
  color: #e9a535;
}

.DiscountInputPercentage:focus {
  outline: none;
}

.DiscountInputPercentage::placeholder {
  color: #e9a535;
}

.OnlinePriceCourseInput {
  width: 80%;
  font-size: 20px;
  line-height: 20px;
  text-align: end;
  border: none;
  color: #e9a535;
}

.OnlinePriceCourseInput:focus {
  outline: none;
}

.OnlinePriceCourseInput::placeholder {
  color: #e9a535;
}

.UpDiscount {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.UpDiscountOnsite {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
}

.percentage-discount {
  margin: 0;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

#yellow-pencil-icon--mobile {
  display: none;
}

.upDiscountDesktopContainer {
  width: -webkit-fill-available;
  display: flex;
  justify-content: space-between;
}

.openDiscountIconsContainer {
  display: flex;
  align-items: center;
}

.pl-25 {
  margin-left: 25px;
}

.CoursePremium.mobile-display-none.my-1 {
  display: none;
}

.w-35 {
  width: 35%;
}

.LeftDiscount.w-35 {
  align-items: center;
  display: flex;
  justify-content: flex-end;
}

.middleDiscount {
  display: none;
}

.amount-lesson-discount-p {
  margin: 0;
  font-size: 20px;
}

.amount-lesson-discount-cont {
  margin-right: 40px;
}

@media (max-width: 576px) {
  .lesson-hide-mobile {
    display: none;
  }
  .middleDiscount {
    display: block;
  }
  .lessonDiscountContainer {
    align-items: center !important;
  }
  .BackgroundDarkWhite.BorderRadius28.paddingOnlineCourse {
    margin-top: 7vh;
  }
  .newCourse-row.row {
    background-color: #f8f8f8;
    border-radius: 10px;
  }
  .UpDiscount {
    align-items: flex-start;
  }
  .UpDiscountOnsite {
    align-items: flex-end;
  }
  .onlineTotalCost {
    padding-right: 0;
  }
  #yellow-pencil-icon--desktop {
    display: none;
  }
  #yellow-pencil-icon--mobile {
    display: inline-block;
  }
  .amount-lesson-discount-cont {
    display: none;
  }
  .LeftDiscount.justify-content-around {
    justify-content: flex-start !important;
  }
  .yellow-pencil-icon--mobile {
    display: inline-block;
  }
  .DiscountPAmount {
    display: none;
  }
  .percentage-discount {
    font-size: 10px;
  }
  .DiscountTextAmount {
    padding-left: 0;
    font-size: 20px;
  }
  .DownDiscount {
    margin: 5px 0;
  }
  .CoursePremium.mobile-display-none.my-1 {
    display: inline-block;
  }
  .backCourseText {
    margin: 0;
    margin-left: 10px;
  }
  .DiscountTitle {
    font-size: 90%;
  }
  .upDiscountDesktopContainer {
    flex-direction: column;
  }
  .DiscountInputCointainer {
    padding: 0 1rem !important;
    width: 35%;
  }
  .DiscountInputCointainerOnsite {
    padding: 0 0.5rem;
    width: auto;
  }
  .OnlinePriceCourseInput {
    font-size: 18px;
    text-align: center;
  }
}
html {
  box-sizing: content-box;
  scroll-behavior: smooth;
}

.modalViewClasses {
  border-radius: 2rem !important;
  width: 100% !important;
}

.col {
  padding: 0 !important;
}

.modal-lg {
  max-width: 80% !important;
}

.modal-header {
  padding: 1rem 2.5rem 0.7rem 2.5rem !important;
}

.DrawerInput {
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 70%;
}

.InputColumn {
  max-width: 50%;
}

.Height30p {
  min-height: 30%;
}

.LessonHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.LessonHeaderIcon {
  width: 23px;
  height: 23px;
  margin: 0 5px;
  cursor: pointer;
}

.LessonInput {
  padding: 1rem;
  color: #a1a1a1;
  font-size: 20px;
  border: none;
  border-radius: 2rem;
  background-color: white;
  width: 100%;
  height: 18vh;
  resize: none;
}

.LessonInput:focus {
  outline: none;
}

.LessonInput::placeholder {
  color: #a1a1a1;
}

.thumbnail-text {
  color: #a1a1a1;
}

.accordion-collapse.collapse {
  border-radius: 0 0 1.5rem 1.5rem !important;
}

.accordion-button {
  border-radius: 1.5rem 1.5rem 0 0 !important;
}

.accordion-button.collapsed {
  border-radius: 1.5rem !important;
}

.thumbnail-subtitle {
  color: #64949a;
  font-family: Roboto;
  font-size: 95%;
  opacity: 50%;
  line-height: normal;
  padding-left: 2rem;
  margin-bottom: 0.5rem;
}

.LittleCardImage {
  height: 5rem;
  justify-content: center;
}

.LittleCardIcon {
  width: 40% !important;
  margin: auto;
}

.littleCardsContainer {
  padding: 0rem 1rem 2rem 1rem !important;
}

.PaddingIcon {
  margin-right: 1rem;
}

.PaddingColumnInput {
  padding: 1rem;
}

.lightSpan {
  font-weight: 100 !important;
}

.FooterModal {
  padding: 1rem 2.5rem !important;
}
.hiThere-calendar-mobile {
  display: none;
}

.options-hiThere-mobile {
  display: none !important;
}

.pending-yellow {
  color: #e9a535 !important;
}

.calendar-container-desktop {
  width: 100%;
  margin: 0 !important;
}

.font-size10 {
  font-size: 10px !important;
}

.font-size14 {
  font-size: 14px !important;
  line-height: 16px !important;
}

.font-size22 {
  font-size: 22px !important;
}

.font-weight700 {
  font-weight: 700;
}

.currentPlanes-item--p {
  font: normal normal bold 16px/20px Roboto;
  color: #64949a;
  margin: 0;
}

.color-64949aB3 {
  color: rgba(107, 140, 149, 0.7019607843);
}

.currentPlanes-item--sub {
  font: normal normal normal 10px/12px Roboto;
  letter-spacing: 0px;
  color: #64949a;
}

.currentUpcoming-input {
  width: 100%;
  border: none;
  border-radius: 8px;
  height: 125px;
  font: normal normal normal 12px/15px Roboto;
  color: rgba(107, 140, 149, 0.7);
  outline: none;
  padding: 10px;
}

.currentUpcoming-input::placeholder {
  color: rgba(107, 140, 149, 0.24);
}

@media (min-width: 1416px) {
  .hiThere-options-desktop {
    margin-bottom: 30px;
  }
}
@media (max-width: 576px) {
  .options-hiThere-mobile {
    display: block !important;
  }
  .Hithere .container {
    background-color: #c0d1d5;
    border-radius: 0;
    padding: 10vh 3.5rem 2.5rem 3.5rem;
  }
  .hiThere-greeting {
    display: block;
  }
  .calendar-container-mobile {
    padding: 20px !important;
  }
  .calendar-container-desktop {
    display: none !important;
  }
  .hiThere-greeting-horizontal {
    display: none;
  }
  .hiThere-calendar-mobile {
    display: inline-block;
  }
  .menuInstructor-h3-mobile {
    color: #64949a !important;
    font-family: Roboto, sans-serif !important;
    font-size: 20px !important;
    font-weight: 600;
    margin: 40px 0;
  }
  .hiThere-greeting-h6 {
    font-family: Roboto, sans-serif !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    color: #48676f;
    text-transform: capitalize;
    margin: 0;
  }
  .hiThere-greeting-h2 {
    font-family: Roboto, sans-serif !important;
    font-size: 38px !important;
    font-weight: 700 !important;
    color: #8db2bc !important;
    text-transform: capitalize;
  }
  .menuInstructor-h5-mobile {
    font-size: 16px !important;
    font-weight: 600 !important;
    color: #64949a !important;
    opacity: 0.8;
  }
  .accordion-item {
    border: none !important;
  }
  .currentCourse-item-on {
    background-color: rgba(107, 140, 149, 0.24) !important;
    border-radius: 17px;
    padding: 1rem 1.5rem;
    margin: 0.8rem 0;
  }
  .currentCourse-item-off {
    background-color: rgba(107, 140, 149, 0.1);
    border-radius: 28px;
    padding: 0.5rem 1.5rem;
    margin: 0.8rem 0;
  }
  .pencil-current-blue {
    color: #64949a !important;
    font-size: 12px;
    align-self: center;
    margin-left: 5px;
    margin-bottom: 10px !important;
  }
  .currentCourse-item--h4 {
    font: normal normal 600 16px/20px Roboto;
    color: #64949a !important;
    margin: 0 !important;
  }
  .currentCourse-item--h4.off {
    opacity: 0.5;
  }
  .currentCourse-item--h4.on {
    opacity: 1;
    font-weight: 700 !important;
    margin-bottom: 10px !important;
  }
  .currentCourse-item--p {
    margin: 0 !important;
  }
  .currentCourse-item--p {
    color: rgba(107, 140, 149, 0.8);
    font: normal normal normal 13px/16px Roboto;
  }
  .currentCourse-item--p.underline {
    text-decoration: underline;
    font-weight: bold;
  }
  .currentCourse-square-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .currentCourse-square-mobil {
    align-items: center;
    justify-content: center;
    background: rgba(107, 140, 149, 0.2);
    border-radius: 8px;
    width: 50px;
    height: 50px;
  }
  .currentCourse-square-mobil .currentCourse-item--number {
    font: normal normal bold 18px/22px Roboto;
    letter-spacing: 0px;
    color: #64949a;
    text-transform: capitalize;
    opacity: 0.8;
    margin: 0;
  }
  .currentCourse-square--p {
    font: normal normal 600 10px/12px Roboto;
    letter-spacing: 0px;
    color: rgba(142, 178, 188, 0.68);
    text-transform: capitalize;
    text-align: center;
    margin: 0;
  }
  .font-size9 {
    font-size: 9px;
  }
  .font-size14 {
    font-size: 14px;
  }
  .currentUpcoming-btn {
    border-radius: 28px;
    padding: 1px 18px;
    font: normal normal 600 12px/14px Roboto;
    color: #64949a;
  }
  .currentUpcoming-btn.approve {
    background: rgba(107, 140, 149, 0.39);
  }
  .currentUpcoming-btn.deny {
    background: rgba(107, 140, 149, 0.7);
  }
  .currentUpcoming-btn.send {
    background: rgba(107, 140, 149, 0.2) 0% 0%;
  }
}

.box-name-profile-student-mobil .hi-there {
  color: #48676f;
  font-weight: 600;
}

.container-name-profile-student-mobil .full-name-person {
  color: #64949a !important;
}

.profile-info .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.3272450533vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.profile-info .hi-there {
  color: #48676f;
  opacity: 0.68;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

.container-general-payment .container-name-profile-student-pc .options-box-account-info-student .btn-logout {
  text-decoration: underline;
}

/*estilos para el menu de accordion*/
.accordion-general-section {
  margin: 0 auto;
  width: 85%;
}

.accordion-menu-mobil.accordion-body {
  background-color: #fff;
  padding: 0 !important;
}

/*cambiar el color de los botones del menu de accordion*/
.accordion-button {
  background-color: transparent !important;
  padding: unset !important;
}

/* .accordion-menu-mobil .accordion-header{
    font-size: 3.75rem!important;
} */
.accordion-menu-mobil .accordion-header .accordion-button {
  color: #64949a !important;
}

/*cambiar el color general del accordion*/
.accordion-item {
  padding-bottom: unset !important;
}

.accordion-item:nth-child(1) {
  margin-top: 1.2rem;
}

@media (max-width: 768px) {
  .box-name-profile-student-mobil {
    background-color: #c0d1d5;
    border-radius: 0 0 1.8rem 1.8rem;
    padding-bottom: 2rem;
    padding-top: 1rem;
    padding-left: 2rem;
    padding-right: 2rem;
    text-align: center;
  }
  .container-name-profile-student-mobil .box-name-profile-student-mobil p {
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  .container-name-profile-student-mobil .full-name-person {
    font-size: 1.5rem !important;
  }
  /*este estilo se tulizo para poner las opciones del menu de accordeon de color blanco, anteriormente ya se habian
  puesto en ese color con otro selector, el cual no recuerdo, pero al hacer merge se rompio ese estilo y ahora se puso este
  */
  .accordion-general-section .accordion-button {
    background-color: #fff !important;
  }
  /*este estilo se tulizo para poner las opciones del menu de accordeon de color blanco, anteriormente ya se habian
  puesto en ese color con otro selector, el cual no recuerdo, pero al hacer merge se rompio ese estilo y ahora se puso este
  */
  .accordion-general-section .accordion-button {
    background-color: #fff !important;
  }
  .container-name-profile-student-mobil .box-name-profile-student-mobil .icon-user-main {
    color: #8db2bc !important;
    /* background-color: #93b6bd!important; */
    border-radius: 5rem;
    display: inline-block;
    padding: 0.1rem;
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
}
.messenger-desk-container {
  position: fixed;
  bottom: 0;
  z-index: 50;
  border-radius: 20px 20px 0 0;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.1607843137);
  overflow: hidden;
  right: 300px;
  background: #fff;
  min-width: 320px;
}

.messenger-mobile-container {
  display: none;
}

.messenger-desk-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #aabdc2;
  padding: 40px 20px;
}

.messenger-desk-header > p {
  margin: 0;
  color: #fff;
  font: normal normal bold 20px Roboto;
}

.messenger-desk-header > div > p {
  margin: 0;
  color: #fff;
  font: normal normal bold 20px Roboto;
  margin-left: 20px;
}

.messenger-desk-header > i {
  color: #fff;
}

.messenger-desk-header > div > i {
  color: #fff;
}

.pl-10 {
  padding-left: 10px;
}

.messenger-desk__inbox-container {
  min-height: 40vh;
  max-height: 65vh;
  overflow-y: scroll;
}

.messenger-desk__inbox-container > .accordion > .accordion-item:nth-child(1) {
  margin: 0;
}

.messenger-desk__inbox-container > .accordion > .accordion-item > .accordion-header > .accordion-button {
  font-family: Roboto !important;
  font-size: 20px !important;
  font-weight: 600 !important;
  background: #fff !important;
  text-transform: none;
  color: #64949a !important;
  margin-right: 10px !important;
}

.messenger-desk__inbox-container > .accordion > .accordion-item {
  border-radius: none !important;
  border: 0 0.25px solid rgba(112, 112, 112, 0.3) !important;
}

.messenger-desk__inbox-item-header {
  border-bottom: 0.25px solid rgba(112, 112, 112, 0.3) !important;
  border-radius: 0 !important;
}

.messenger-desk__inbox-container > .accordion > .accordion-item > .accordion-collapse {
  background: #fff !important;
}

.messenger-desk__inbox-container > .accordion > .accordion-item > .accordion-collapse.collapse.show > .accordion-body {
  padding: 0 !important;
}

.messenger-desk__inbox-container > .accordion > .accordion-item > .accordion-collapse.collapse.show > .accordion-body > div {
  margin: 0rem 1.5rem !important;
  padding: 1.5rem 0;
  border-bottom: 0.25px solid rgba(112, 112, 112, 0.25);
}

.messenger-info-container > p {
  margin: 0;
}

.size-12 {
  font-size: 10px;
}

.messenger-info-from {
  font: normal normal 600 18px Roboto;
  color: #8db2bc;
}

.messenger-info-date {
  font: normal normal normal 14px Roboto;
  color: #8db2bc;
}

.messenger-desk__inbox--single-container {
  min-height: 75vh;
  max-height: 75vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.messenger-desk__inbox--send {
  min-height: 10vh;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 20px;
  border-top: 1px solid rgba(112, 112, 112, 0.35);
}

.messenger-desk__inbox--input {
  border: 0;
  outline: none;
  width: 100%;
  height: 100%;
  resize: none;
  overflow: hidden;
  color: #64949a;
  opacity: 0.7;
}

.messenger-desk__inbox--input::placeholder {
  color: #949494;
}

.messenger-desk__inbox--container {
  height: 70%;
}

.messenger-desk__inbox--single {
  display: flex;
  flex-direction: column-reverse;
  padding: 20px;
  overflow-x: hidden; /* Barra de desplazamiento horizontal */
  overflow-y: scroll;
}

.messenger-desk__inbox--from {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
}

.messenger-desk__inbox--to {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.messenger-desk__inbox--to-text {
  margin: 0;
  width: 90%;
  padding: 15px;
  background: #e1e9eb;
  border-radius: 10px;
  max-width: 353px;
  font: normal normal normal 18px/24px Roboto;
  color: #64949a;
}

.messenger-desk__inbox--from-text {
  margin: 0;
  width: 90%;
  padding: 15px;
  background: #f8f8f8;
  border-radius: 10px;
  max-width: 353px;
  font: normal normal normal 18px/24px Roboto;
  color: #707070;
}

.messenger-desk__inbox--from-info > p {
  font: normal normal normal 12px/15px Roboto;
  color: #707070;
  opacity: 0.68;
  margin-top: 5px;
}

.messenger-desk__inbox--to-info > p {
  font: normal normal normal 12px/15px Roboto;
  color: #64949a;
  opacity: 0.68;
  margin-top: 5px;
}

.messenger-desk__inbox--send > .fa-sharp.fa-solid.fa-paper-plane {
  color: #64949a;
}

@media (max-width: 576px) {
  .messenger-desk-container {
    display: none;
  }
  .messenger-mobile-container {
    display: block;
    position: absolute;
    top: 0;
    right: 0 !important;
    left: 0;
    z-index: 50;
    height: 100%;
    overflow: hidden;
    right: 300px;
    background: #fff;
    min-width: 320px;
  }
  .messenger-desk-header > p {
    font: normal normal 600 20px Roboto;
  }
  .messenger-desk-header > i {
    font-size: 25px;
    margin-right: 10px;
  }
  .messenger-desk__inbox-container {
    max-height: 100%;
  }
  .messenger-desk__inbox--single-container {
    max-height: 85vh;
  }
  .messenger-desk__inbox-container > .accordion > .accordion-item > .accordion-header > .accordion-button {
    padding: 25px 15px !important;
  }
}

.box-general-moderator .container-header-myOrka {
  background-color: unset;
}

.moderator-container {
  display: flex;
  background: #f8f8f8;
  border-radius: 28px;
  padding: 50px 60px;
  margin: 40px 70px;
}

.h3-moderator {
  font-family: Roboto, sans-serif !important;
  text-transform: none;
  font-weight: 700 !important;
  color: #48676f !important;
  opacity: 0.68 !important;
  font-size: 20px !important;
  margin: 0 !important;
}

.h2-moderator {
  font-family: Roboto, sans-serif !important;
  text-transform: none;
  font-weight: 700 !important;
  color: #8db2bc !important;
  font-size: 38px !important;
  letter-spacing: 1px;
}

.btn-moderator {
  font-family: Roboto, sans-serif !important;
  text-transform: none;
  color: #a1a1a1 !important;
  opacity: 0.68;
  font-size: 20px !important;
  margin-bottom: 20px;
  cursor: pointer;
}

.grayItem.moderatorGrayItem.accordion-item {
  margin-top: 15px !important;
}

.box-general-moderator .accordion-item {
  margin-bottom: 1rem;
}

.gray.item-moderator.accordion-header > .accordion-button.collapsed {
  padding: 12px 25px !important;
}

.box-general-moderator .accordion-header {
  padding: 0 !important;
}

.box-general-moderator .accordion-header button {
  padding: 0.75rem 1.56rem !important;
  background-color: #f8f8f8 !important;
  /* padding-left: 1.56rem!important;
  padding-right: 1.56rem!important; */
}

.gray.item-moderator.accordion-header > .accordion-button {
  padding: 10px 25px !important;
  background-color: #f8f8f8 !important;
}

.gray.item-moderator.accordion-header {
  padding: 0;
}

.moderatorGrayItem.accordion-item {
  margin-top: 0 !important;
}

.logout-moderator {
  font-weight: 700 !important;
  text-decoration: underline;
}

.active {
  color: #64949a !important;
}

.moderator-menu-container {
  width: 30%;
}

.moderator-menu {
  padding: 40px 30px;
}

.moderator-items-container {
  width: 70%;
  padding: 0 0 0 20px;
}

.modal-item-gray {
  color: #a1a1a1;
}

.modal-item-blue {
  color: #64949a;
}

.moderator-item-user {
  font-size: 20px;
}

.moderator-item-user-report {
  color: #d10000 !important;
}

.moderator-accordion-container {
  padding: 20px;
  background: #fff;
  border-radius: 28px;
  height: 65vh;
}

.accordion-scroll {
  overflow: scroll;
  height: 100%;
}

.moderator-accordion-title {
  margin: 0 !important;
  color: #a1a1a1 !important;
  font-family: Roboto, sans-serif !important;
  text-transform: none;
  font-weight: 600 !important;
  font-size: 20px !important;
}

.moderator-accordion-body_item {
  justify-content: space-between;
  border-bottom: 1px solid #a1a1a1;
  padding: 10px 0 !important;
  margin: 0 15px !important;
}

.grayItem {
  padding-right: 35px;
}

.gray {
  color: #a1a1a1 !important;
}

.blueStarIconModerator {
  color: #64949a !important;
}

.font-20 {
  font-size: 20px;
}

.w-60 {
  width: 60% !important;
}

/* .modal-content {
  width: 100% !important;
  border-radius: 28px !important;
} */

.moderator-modal-container {
  /* height: 100vh;
  display: flex;
  align-items: center;
  margin: auto; */
  height: 100%;
}

.body-modal {
  padding: 30px 70px !important;
}

.title-modal {
  margin: 0 !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 600;
  font-size: 20px;
  color: #64949a;
  opacity: 0.68;
}

.subtile-modal {
  padding-left: 5px;
  font-family: Roboto, sans-serif !important;
  font-weight: 400;
  font-size: 15px;
  color: #949494;
  opacity: 0.5;
}

.typeReport-modal {
  padding: 20px 40px;
  font-family: Roboto, sans-serif !important;
  font-size: 17px;
  color: #949494;
}

.infoContainer-modal {
  border: 0.5px solid #707070;
  border-radius: 6px;
  opacity: 0.37;
  margin: 0 0.625rem;
}

.infoTitle-modal {
  width: 100%;
  background: #c3d8d8;
  padding: 10px;
  margin: 0 !important;
}

.infoText-modal {
  padding: 20px;
}

.btnClose-modal {
  padding: 5px 30px !important;
  background: #c7d5d8 0% 0% no-repeat padding-box;
  border-radius: 6px;
  color: #64949a;
  font-family: Roboto, sans-serif !important;
  font-size: 15px;
  cursor: pointer;
}

.cursor-pointer {
  cursor: pointer;
}

.moderator-reviews-container {
  border-radius: 28px;
  padding: 40px 60px;
  background: #fff;
  height: 65vh;
}

.moderator-scroll {
  overflow: scroll;
  overflow-x: hidden;
  height: 100%;
}

.moderator-modal-container .moderator-scroll {
  overflow: scroll;
  overflow-x: hidden;
  height: 60%;
}

.moderator-reviews-h3 {
  font-family: Roboto, sans-serif !important;
  font-weight: 600;
  font-size: 18px !important;
  color: #64949a !important;
  opacity: 0.68 !important;
  text-transform: none;
  margin-bottom: 0.3rem !important;
}

.moderator-reviews-p {
  font-family: Roboto, sans-serif !important;
  font-size: 12px !important;
  color: #949494 !important;
  opacity: 0.5 !important;
  margin: 0 !important;
}

.reviews-items_container {
  padding: 30px 40px 0 40px;
}

.reviews-item-p {
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0px;
  color: #a1a1a1;
  padding: 20px 20px 0 20px;
  margin: 0 !important;
}

@media (max-width: 576px) {
  .box-general-moderator .container-header-myOrka {
    background-color: #c0d1d5;
  }
  .navbar-transparent {
    top: 0;
    position: absolute;
  }
  .moderator-container {
    flex-direction: column;
    border-radius: 5px;
    padding: 5px 6px;
    margin: 4px 7px;
  }
  .moderator-items-container {
    width: 100%;
    padding: 3px;
  }
  .moderator-accordion-container {
    height: auto;
    padding: 0px;
    margin-bottom: 20px;
  }
  .moderator-accordion-container.off {
    display: none;
  }
  .moderator-accordion-container.active {
    display: block;
  }
  .movil-moderator-profile {
    background: #c0d1d5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    border-bottom-left-radius: 28px;
    border-bottom-right-radius: 28px;
    height: 28vh;
  }
  .movil-container-icon {
    border-radius: 50%;
    width: 104px;
    height: 104px;
    background: rgba(142, 178, 188, 0.5215686275) 0% 0% no-repeat padding-box;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    overflow: hidden;
  }
  .movil-moderator-icon {
    font-size: 75px;
    text-align: center;
    color: #8db2bc;
  }
  .movil-moderator-p {
    font-family: Roboto, sans-serif !important;
    color: #48676f;
    opacity: 0.68;
    font-size: 16px;
    line-height: 20px;
    margin: 1.5rem 0 0.2rem 0;
  }
  .movil-moderator-h2 {
    font-family: Roboto, sans-serif !important;
    color: #8db2bc;
    text-transform: capitalize;
    font-size: 40px !important;
    margin-bottom: 40px;
  }
  .movil-moderator-options {
    margin: 2rem 2rem 2rem 3rem;
  }
  .accordion-scroll {
    height: 38vh;
  }
  .grayItem {
    padding-right: 25px;
  }
  .movil-moderator-logout {
    font-family: Roboto, sans-serif !important;
    text-decoration: underline;
    font-weight: 600;
    color: #858585;
    text-transform: capitalize;
    opacity: 0.68;
  }
  .moderator-accordion-title {
    font-size: 12px !important;
  }
  .gray.font-20 {
    font-size: 20px;
    padding-right: 4px !important;
    padding-left: 4px !important;
  }
  .movil-moderator-icons {
    align-items: center;
  }
  .movil-moderator-flex {
    display: flex;
  }
  .moderator-accordion-body_item {
    margin: 0 0 0 15px !important;
  }
  .grayItem.accordion-body {
    padding-right: 20px !important;
    padding-left: 0 !important;
  }
  .moderator-accordion-title.movil-accordion-title {
    font-size: 16px !important;
  }
  .moderator-modal-container {
    width: 100% !important;
    justify-content: center;
    margin: 0 !important;
  }
  .moderator-modal-container .modal-content {
    margin: 0 auto;
    margin-top: 30%;
    width: 85% !important;
  }
  .body-modal {
    padding: 40px 30px !important;
  }
  .typeReport-modal {
    padding: 10px 20px 15px 20px;
  }
  .infoContainer-modal {
    margin: 0 20px;
  }
  .infoText-modal {
    padding: 15px;
    font-size: 15px;
    line-height: 18px;
  }
  .d-flex.justify-content-end.mt-3.moderator-modal-close {
    margin: 30px 20px 0 0 !important;
  }
}
@media (min-width: 1920px) {
  .moderator-menu-container {
    width: 22%;
  }
  .moderator-items-container {
    width: 78%;
  }
}
.line {
  margin-left: 2rem;
  border-right: 1px;
  border-right: #8db2bc;
  transform: scaleX(0.5);
}

.helpful-docs {
  color: #64949a;
}

.helpful-docs a {
  color: #64949a;
}

.hide {
  display: none;
}

.weekdays {
  background-color: #48676F;
  border-radius: 20px;
}

.list {
  display: none;
}

.hideInMD {
  display: inline;
}

.list-of-courses-student-my-orka-pc {
  margin-left: -1rem;
}

.box-general-myOrka .main-box {
  border-radius: 1.75rem;
  padding: 3rem;
  margin: 0 auto;
  width: 90%;
}

.box-general-myOrka .box-header-one h4 {
  color: #64949a !important;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-style: normal;
  opacity: 68%;
}

.box-general-myOrka .box-general-content-course .text-progress-overview {
  color: #64949a !important;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-style: normal;
  opacity: 68%;
}

.box-general-content-course .box-content-course {
  border-radius: 1.75rem;
  display: flex;
  height: auto;
  gap: 3rem;
  padding: 2rem 2rem;
}

.box-general-content-course .box-content-course .box-map-and-measures {
  width: 60%;
}

.box-map-and-measures .text-hi-there {
  color: #48676F;
  opacity: 68%;
  font-family: Roboto, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  font-style: normal;
}

.box-map-and-measures .text-user {
  color: #8db2bc;
  font-family: Roboto, sans-serif;
  font-size: 3rem;
  font-weight: 700;
  font-style: normal;
}

.box-map-and-measures .text-flight-summary {
  color: #64949a;
  font-family: Roboto, sans-serif;
  font-size: 0.9375rem;
  font-weight: 700;
  font-style: normal;
}

.box-content-course .box-google-map {
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 1rem;
}

/* .box-content-course .box-google-map .box-google-map-inside{
    height: 5vh;
} */
.box-content-course .box-google-map > * {
  height: 10%;
}

.box-content-course .box-above-google-map {
  display: flex;
  justify-content: space-between;
}

.box-content-course .box-above-google-map .first-box {
  margin-left: 1rem;
}

.box-above-google-map .second-box-summary p {
  color: rgba(71, 71, 71, 0.5882352941);
  font-size: 12px;
  margin-bottom: 0;
}

.box-above-google-map .second-box-summary .see-aviable-classes {
  color: rgba(71, 71, 71, 0.5882352941);
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-style: normal;
  text-decoration: underline;
}

.box-content-course .box-above-google-map > div {
  width: 45%;
}

.box-content-course .text-here-your-summary {
  color: #64949a;
  font-family: Roboto, sans-serif;
  font-size: 0.9375rem;
  font-weight: 700;
  font-style: normal;
  margin-bottom: 0;
}

.box-above-google-map .second-box .first-inside-second-box {
  display: flex;
  gap: 0.5rem;
}

.box-above-google-map .second-box .first-inside-second-box > div {
  background-color: rgba(186, 186, 186, 0.0784313725);
  text-align: center;
  padding: 0.5rem 0.2rem;
  width: 48%;
}

.first-inside-second-box > div .hour-numeric {
  font-size: 1.5625rem;
}

.first-inside-second-box > div .hr-text {
  font-size: 0.625rem;
}

.box-texts-above-first-inside-second-box {
  display: flex;
  justify-content: space-between;
  margin-right: 0px;
}

.box-texts-above-first-inside-second-box p {
  color: #8db2bc;
  font-family: Roboto, sans-serif;
  font-size: 0.5625rem;
  font-weight: 600;
  font-style: normal;
  opacity: 68%;
}

.box-above-google-map .second-box .second-inside-second-box > div {
  background-color: rgba(186, 186, 186, 0.0784313725);
  /* margin-top: .5rem; */
  padding: 0.5rem 0.2rem;
  text-align: center;
  width: 100%;
}

.second-inside-second-box > div .millas-numeric {
  font-size: 1.5625rem;
}

.second-inside-second-box > div .mill-text {
  font-size: 0.625rem;
}

.box-texts-above-second-inside-second-box p {
  color: #8db2bc;
  font-family: Roboto, sans-serif;
  font-size: 0.5625rem;
  font-weight: 600;
  font-style: normal;
  opacity: 68%;
}

.box-see-available-classes-and-see-availability .hash-link {
  color: #64949a;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  font-style: normal;
}

.box-out-form-ratings-mobil {
  display: none;
}

.box-form-ratings-pc {
  background-color: #F8F8F8;
  border-radius: 0.9375rem;
  padding: 1rem 1rem;
}

.box-classCard-EmptyCard-mobil {
  display: none;
}

.box-classCard-EmptyCard-mobil .box-empty-card {
  width: 90% !important;
  max-width: none !important;
}

.list-of-courses-student-my-orka-mobil {
  display: none;
}

.box-browse-classes-mobil {
  display: none;
}

@media (max-width: 768px) {
  .box-general-myOrka .container-header-myOrka {
    background-color: #f8f8f8;
  }
  /*ocultar la lista de cursos*/
  .list-of-courses-student-my-orka-pc {
    display: none;
  }
  .box-general-myOrka .main-box {
    border-radius: unset;
    padding: unset;
    width: 100%;
  }
  .box-general-myOrka .box-current-classes .hask-link-browse-classes {
    display: none;
  }
  .box-general-content-course {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  /*esto es para cambiar la orientacion de como se aplicara el display flex*/
  .box-general-content-course .box-content-course {
    flex-direction: column;
    padding: 2rem 3rem;
  }
  /*cambiarle el width a 100% para que se vea bien en la vista mobil*/
  .box-general-content-course .box-content-course .box-map-and-measures {
    width: 100%;
  }
  .box-wrap-box-google-map-box-above-google-map {
    background-color: #fff;
    border-radius: 28px;
    padding-bottom: 2rem;
    padding-right: 0.6rem;
  }
  .box-general-myOrka .BackgroundDarkWhite {
    background-color: #f8f8f8;
  }
  .box-calendar-and-upcoming-classes .box-inside-calendar-and-upcoming-classes {
    display: flex;
    justify-content: center;
  }
  .box-calendar-and-upcoming-classes .box-inside-calendar-and-upcoming-classes .rmdp-top-class {
    color: transparent;
    display: flex;
    justify-content: center;
  }
  .box-form-ratings-pc {
    display: none;
  }
  .box-out-form-ratings-mobil {
    display: block;
    margin: 2rem auto 1rem auto;
    width: 90%;
  }
  .box-form-ratings-mobil {
    background-color: #c0d1d5;
    border-radius: 0.9375rem;
    padding: 1rem 1rem;
    opacity: 0.7;
  }
  .box-see-available-classes-and-see-availability .hash-link {
    display: none;
  }
  .box-lessons-upcoming,
  .text-no-classes-or-events-yet {
    display: none !important;
  }
  .box-classCard-EmptyCard-mobil {
    display: block;
  }
  .box-classCard-EmptyCard-mobil .box-hours-milles-classes-left {
    width: 100%;
  }
  .box-classCard-EmptyCard-mobil .box-empty-card {
    background-color: #fff;
    width: 100% !important;
  }
  .list-of-courses-student-my-orka-mobil {
    display: block;
  }
  .hide {
    display: inline;
  }
  .hideInMD {
    display: none;
  }
  .box-browse-classes-mobil {
    display: block;
  }
  .list {
    display: block;
    margin: 2rem;
    align-self: center;
    align-items: center;
  }
}
.Title.Mustard {
  display: flex;
  align-items: center;
  align-self: flex-end;
  font-size: 18px;
  font-weight: 600;
}

.ml-10 {
  margin-left: 10px;
}

.eyeIcon {
  margin: 0 40px 0 20px;
  font-size: 23px;
  cursor: pointer;
}

.CourseInput {
  color: #64949a;
  padding-right: 40px;
}

html {
  overflow: scroll;
}

.LessonHeader.Discount {
  display: flex;
  background: #e9a535;
  color: #e9a535;
  border-radius: 20px;
  padding: 4% 8%;
  font-weight: 500;
}

.discountText {
  opacity: 76%;
}

.backCourseContainer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 3%;
}

.mb20 {
  margin-bottom: 20px;
}

.backCourseText {
  margin: 0 20px 0 10px;
}

.locationMapGeneral {
  background: #f8f8f8 0% 0% no-repeat padding-box;
  border-radius: 28px;
  width: 100%;
}

.locationMap {
  height: 455px;
}

.locationMapMyOrka {
  height: 24vh;
}

.includedContainer {
  display: flex;
  flex-direction: column;
}

.includedInput {
  background-color: transparent;
  border: none;
  margin-bottom: 0px;
  width: 4rem;
  text-align: end;
  resize: none;
  color: #64949a;
}

.includedInput::placeholder {
  color: #a1a1a1;
  opacity: 0.76;
}

.includedInput:focus {
  outline: none;
}

.ShowMore {
  color: #64949a;
  text-decoration: underline;
  cursor: pointer;
  font: normal normal bolder 16px Roboto;
  margin: 1rem 0.5rem;
}

.iconShowMore {
  margin-left: 5px;
}

.includedLabel {
  font: normal normal normal 16px Roboto;
  letter-spacing: 0px;
  color: #000;
  opacity: 0.5;
  margin: 4.5px 0;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.backCourse {
  display: flex;
  cursor: pointer;
}

.StaticLessonContainer {
  background-color: #c0d1d5;
}

.greenText {
  color: #7b98a0;
}

.staticTitle {
  opacity: 1 !important;
  color: #7b98a0;
}

.staticText {
  margin-top: 15px;
  color: #7b98a0;
  font-weight: 300;
  font-size: 18px;
  font-family: Roboto;
}

.bookNowBtn {
  width: 50%;
  padding: 5px 0;
  background: #f1aa39;
  text-align: center;
  align-self: center;
  align-items: center;
  border-radius: 10px;
  opacity: 0.74;
  font: normal normal bold 20px/30px Roboto;
  color: #ffffff;
  opacity: 0.89;
}

.bookNowBtn:hover {
  cursor: pointer;
}

.GraySubtitle {
  color: #64949a;
  opacity: 0.5;
  font-family: Roboto, sans-serif;
  font-weight: 100;
  line-height: normal;
}

.mb-10 {
  margin-bottom: 80px;
}

.paddingX-10 {
  padding: 4% 8%;
}

.col-center {
  display: flex;
  justify-content: center;
}

.verticalSeparator {
  border: none;
  border-left: 1px solid hsl(200, 10%, 50%);
  height: 100% !important;
  width: 1px;
}

.CourseInput.deleteBorder {
  border: 0;
}

.onlineDescriptionLabel {
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #8db2bc;
  opacity: 0.68;
}

.lessonInfoText {
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #a1a1a1;
}

.modal-backdrop {
  background-color: #707070 !important;
}

/* .modal-dialog {
  width: 50% !important;
  max-width: none !important;
} */

.borderBottom28 {
  border-bottom-left-radius: 28px;
  border-bottom-right-radius: 28px;
}

.inputAddMedia {
  padding: 15px;
  border: none;
  width: 100%;
  font: normal normal 600 15px/17px Roboto;
  color: #a1a1a1;
}

.inputAddMedia:focus {
  outline: none;
}

.addMediaUploadText {
  font-size: 25px;
  font-family: Roboto, sans-serif;
  color: #a1a1a1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.margin-15 {
  margin: 15px;
}

.AddMediaTitle {
  font-family: Roboto, sans-serif !important;
  font-weight: 700 !important;
  padding-left: 10px;
  font-size: 30px !important;
}

.addMediaAccordionTitle {
  font-family: Roboto, sans-serif !important;
  font-size: 50px !important;
  text-transform: uppercase;
}

.addMediaBody {
  padding: 0 2rem !important;
  max-height: 60vh;
}

.addMediaFooter {
  padding: 1rem 2rem !important;
}

.height70vh {
  height: 70vh;
}

.addMediaSelectImgContainer {
  display: flex;
  justify-content: space-between;
}

.addMediaSelectImg {
  width: 23%;
  height: 80px;
  display: flex;
  justify-content: center;
  background: #ffffff;
  border: 1px solid #707070;
}

.minHeight100p {
  height: 100%;
}

.minHeight75p {
  height: 75%;
}

.videoAddMedia {
  padding: 0;
  margin: 0;
  height: 100%;
  box-sizing: border-box;
}

.rightAddMedia {
  display: flex !important;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.rightAddMediaVideo {
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.rightAddMediaText {
  width: 100%;
}

.selectedImg {
  border: 2px solid #f1aa39;
}

.loadingImg {
  height: 30%;
}

.loadingText {
  text-align: center;
  margin-top: 10% !important;
  font-family: Roboto, sans-serif !important;
  font-size: 22px;
  line-height: 25px;
  color: #a1a1a1;
}

.hstack.gap-5 {
  max-height: 60vh;
}

.BlueLink.ProximaNova {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 85%;
}

.noPadding {
  padding: 0;
}

.clipBoard {
  font-size: 12px;
  border: 1px solid #a1a1a1;
  color: #a1a1a1;
  border-radius: 5px;
  padding: 1px 4px;
  cursor: pointer;
}

.padding20 {
  padding: 0 20px;
}

.LessonImg {
  max-width: 100%;
  max-height: 50%;
}

input[type=time]::-webkit-calendar-picker-indicator {
  display: none;
}

.TitleInput {
  color: #a1a1a1;
  opacity: 0.76;
}

.staticOnlineInfo {
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  color: #a1a1a1;
}

.onlineDescriptionBlueTitle {
  font-family: Roboto, sans-serif !important;
  font-size: 22px !important;
  line-height: 25px !important;
  color: #8db2bc !important;
  opacity: 0.68 !important;
}

.onlineLessonInfoTitle {
  font-family: Roboto, sans-serif !important;
  font-size: 20px !important;
  line-height: 25px;
  font-weight: 600 !important;
  color: #a1a1a1 !important;
  opacity: 0.76 !important;
}

.p-5 {
  padding: 5% !important;
}

.modalDropzone {
  height: 75vh !important;
  width: 100% !important;
  border-radius: 28px !important;
}

.headerDropzone {
  padding: 1rem 2.5rem !important;
}

.fontSize28 {
  font-size: 28px;
}

.draggableGrid {
  display: none;
}

.dropzoneBtn {
  background: rgba(107, 140, 149, 0.4392156863) !important;
  padding: 0.5rem 1rem;
  border-radius: 9px;
  cursor: pointer;
}

.dropzoneBtnText {
  font-weight: 500;
  letter-spacing: 0.5px;
  margin: 0 !important;
}

.dropzoneSubtitle {
  font-weight: 600;
  font-size: 18px;
  margin: 0 !important;
}

.dropzoneSaveBtn {
  border: none;
  border-radius: 0.5rem;
  padding: 0.6rem 4rem;
  background: #e9a535;
  color: white;
  font-size: 22px;
  font-weight: 500;
  cursor: pointer;
}

.cursor {
  cursor: pointer;
}

.height90vh {
  height: 90vh !important;
}

.currentClassUnderline {
  margin: 0 !important;
  cursor: pointer;
}

.OnlineLessonDiscountContainer {
  padding: 4% 7%;
}

.paddingOnlineCourse {
  padding: 4% 8%;
}

.LessonInfo.Width100.DiscountContainer.display-none {
  display: none;
}

.box-general-newCourse {
  height: 110vh;
}

/*estilos para el componente de help */
.box-general-newCourse .boxHelpNewCourse .helpBox {
  margin-left: 6.5rem;
}

.boxHelpNewCourse {
  color: #64949a;
}

@media (max-width: 576px) {
  .newCourse-row {
    flex-direction: column-reverse;
  }
  .Title.Mustard {
    font-size: 16px;
    align-self: center;
  }
  .BackgroundDarkWhite {
    background: none;
  }
  .newCourse-lesson-col {
    background: #f8f8f8;
    padding: 1rem !important;
    border-radius: 10px;
  }
  .eyeIcon {
    margin: 0 20px;
  }
  .LessonIcon {
    margin: 0;
  }
  .DiscountText {
    display: none;
  }
  .newCourse-lesson-hstack {
    margin-top: 1rem;
    margin-left: 5px;
  }
  .LessonInfo.LessonHeader.BorderRadius28 {
    border-radius: 10px !important;
  }
  .newCourse-col-middle {
    margin: 2rem;
  }
  .newCourse-images-input-container {
    margin: 1.5rem 0;
  }
  .CardStory.card {
    width: 100% !important;
    height: 436px !important;
  }
  .cardStoryNone {
    display: none !important;
  }
  .IconCard {
    height: 90px !important;
  }
  .newCourse-all-sections {
    margin: 1rem 0;
  }
  .DiscountContainer {
    border-radius: 10px !important;
  }
  .DiscountContainer.UpDiscount {
    border-radius: 10px;
  }
  .DiscountInputCointainer {
    overflow: hidden;
    padding: 0 !important;
  }
  .includedContainer {
    margin-right: 7px;
  }
  .Width50p.marginDropzone {
    width: 100% !important;
    padding: 1.5rem;
    margin: 0 !important;
  }
  .draggableRow {
    display: none !important;
  }
  .draggableGrid {
    display: grid;
    gap: 15px;
    grid-template-columns: repeat(2, 1fr);
  }
  .draggableImageGrid {
    height: 218px;
    width: 165px;
    border-radius: 10px;
    overflow: hidden;
  }
  .margin-for-navbar {
    margin-top: 7vh;
  }
  .box-general-newCourse .boxHelpNewCourse .help {
    left: 1rem;
  }
  /*estilos para el componente de help */
  .box-general-newCourse .boxHelpNewCourse .helpBox {
    margin-left: 0.5rem;
  }
  .locationMapMyOrka {
    height: 22vh;
  }
}
.box-general-newCourseOnline {
  color: #64949a;
}

.box-general-newCourseOnline .boxHelpNewCourseOnline .helpBox {
  margin-left: 7.4rem;
}
.text-hi-there {
  color: #48676f;
  opacity: 68%;
  font-family: Roboto, sans-serif;
  font-size: 1.25rem;
  font-weight: 700;
  font-style: normal;
}

.box-form-ratings-pc {
  background-color: #F8F8F8;
  border-radius: 0.9375rem;
  padding: 1rem 1rem;
}

.text-user {
  color: #8db2bc;
  font-family: Roboto, sans-serif;
  font-size: 2.5rem;
  font-weight: 700;
  font-style: normal;
}

.fw-semibold {
  font-weight: 600;
}

.class-name {
  color: #64949a;
  opacity: 1;
}

.helpful-docs {
  color: #949494;
  font-size: 0.9rem;
}

.lessonDate {
  color: #8db2bc;
  margin-top: 1rem;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 1.75rem;
  font-style: normal;
  opacity: 0.9;
}

.lessons {
  color: #8db2bc;
  margin-top: 2rem;
  margin-bottom: 1rem;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-size: 1rem;
  font-style: normal;
  opacity: 0.9;
}

.lessonList {
  padding-left: 2rem;
  padding-right: 1rem;
  flex-direction: column;
}

.onlineCourse {
  flex-direction: row;
}

.wrap {
  width: 60%;
}

.line {
  margin-left: 2rem;
  border-right: 1px;
  border-right: #8db2bc;
  transform: scaleX(0.5);
}

@media (max-width: 768px) {
  .onlineCourse {
    flex-direction: column;
  }
  .wrap {
    width: 100%;
  }
  .lessonList {
    padding-left: 1rem;
    padding-right: 1rem;
    flex-direction: column;
    align-self: center;
  }
}
.lesson-my-orka {
  background-color: rgba(0, 0, 0, 0.1137254902);
  border-radius: 1rem;
  cursor: pointer;
  height: 7vh;
  padding: 1rem 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  overflow: hidden;
}

.lesson-my-orka-selected {
  background-color: rgba(0, 0, 0, 0.1137254902);
  border-radius: 1rem;
  cursor: pointer;
  height: 7vh;
  padding: 1rem 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
  overflow: hidden;
}

.lesson-my-orka-selected p {
  color: #707070;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 100%;
  font-style: normal;
  opacity: 0.9;
}

.lesson-my-orka-selected:hover {
  background-color: #8db2bc;
  border-radius: 1rem;
  cursor: pointer;
  height: fit-content;
  padding: 1rem 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 100%;
}

.lesson-my-orka p {
  color: #707070;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: small;
  font-style: normal;
  opacity: 0.9;
}

.lesson-my-orka div {
  color: transparent;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  font-style: normal;
  opacity: 0.9;
}

.lesson-my-orka-selected:hover p {
  color: #48676f;
  font-family: Roboto, sans-serif;
  font-weight: bold;
  font-size: 1rem;
  font-style: normal;
  opacity: 0.9;
}

.lesson-my-orka-selected:hover div {
  color: #48676f;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 100;
  font-style: normal;
  opacity: 1;
}

@media (max-width: 768px) {
  .lesson-my-orka {
    width: auto;
  }
}
.lesson-my-orka-selected {
  width: auto;
}
/*estilos a la caja de payment*/
.container-general-payment {
  border-radius: 4.2617960426vh;
}

.container-general-payment .container-payment {
  background-color: #fff;
  border-radius: 1.75rem !important;
  padding: 4rem;
}

/*estilos a textos del menu*/
.container-general-payment .hi-there {
  color: #48676F;
  font-size: 25px;
  margin-bottom: 0.5rem;
}

.container-general-payment .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.3272450533vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.container-general-payment .container-payment .header-payment {
  color: #64949a !important;
  font-size: 3.0441400304vh !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700 !important;
  opacity: 0.68;
}

.container-general-payment .container-payment .link-add-card {
  margin-top: 0.9rem;
}

.container-general-payment .container-payment .text-payment {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh !important;
  font-weight: 400;
  margin-top: -0.3rem;
  opacity: 0.5;
}

.container-general-payment .container-payment .link-add-a-card {
  color: #64949a;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh !important;
  font-weight: 600;
}

/* estilo para tener mismo espacio entre elementos del navbar modo pc */
.box-general-payment .myOrka-link {
  margin-left: 0.8rem;
  margin-right: -1rem;
}

/*estilos a la caja del componente help*/
.box-general-payment .box-help-mobil-payment {
  display: none;
}

/*estilos al componente del menuProfile normal (pc)*/
.box-general-submit-document-student .container-name-profile-student-pc {
  display: block;
}

/*estilos al componete del menuProfileMobil*/
.box-general-payment .container-name-profile-student-mobil {
  display: none;
}

/*establecerle background a la imagen de usuario*/
.box-general-payment .icon-user-header {
  background-color: #fff !important;
}

/*estilos para mostrar/ocultar*/
.container-generic {
  display: block;
}

@media (max-width: 768px) {
  /*estilos al navbar*/
  .box-general-payment .container-header-myOrka {
    background-color: #c0d1d5;
  }
  /*quitarle el padding left al link de home */
  .box-general-payment #Linkstaticon {
    padding-left: 0;
  }
  /*quitarle el padding right al link de myOrka*/
  .box-general-payment #Linkstaticob {
    padding-right: 0;
  }
  /*establecerle background a la imagen*/
  .box-general-payment .icon-user-header {
    background-color: #c0d1d5 !important;
  }
  /*estilos para que se oculte el menu de pc*/
  .box-general-payment .container-name-profile-student-pc {
    display: none;
  }
  /*estilos para que aparesca el menu mobil*/
  .box-general-payment .container-name-profile-student-mobil {
    border-radius: 0 0 1.8rem 1.8rem;
    display: block;
  }
  /*establecer background a la imagen del usuario*/
  .box-general-payment .container-name-profile-student-mobil .nav-user-icon {
    background-color: rgba(142, 178, 188, 0.2196078431) !important;
  }
  /*quitarle los margin a loa lados al navbar*/
  .box-general-payment .my-orka-nav-bar {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  /* estilo para tener mismo espacio entre elementos del navbar modo mobil */
  .box-general-payment .myOrka-link {
    margin-right: 1.2rem;
    margin-left: 0;
  }
  /*establecer background a la caja*/
  .container-general-payment {
    background-color: #fff !important;
  }
  /*quitarle espacio y border a la caja seleccionada*/
  .container-general-payment .container-payment {
    border-radius: unset;
    padding: 0;
  }
  .container-general-payment .container-payment .header-payment {
    font-size: 2.2831050228vh !important;
  }
  .container-general-payment .container-payment #Padding4x4 {
    padding: 0;
  }
  /*estilos a la caja del componente help*/
  .box-general-payment .box-help-mobil-payment {
    color: #64949a !important;
    display: block;
    margin-top: 15rem;
    margin-bottom: 2rem;
    margin-left: -1rem;
  }
  /*darle ubicacion al mensaje de help*/
  .box-general-payment .box-help-mobil-payment .helpBox {
    margin-left: -3px;
    margin-bottom: 0.5rem;
  }
  .container-generic {
    display: none;
  }
}
/*estilos para la parte de las opciones*/
/*estilos para la parte del payment record*/
.box-general-payment-record .box-payment-record {
  background-color: #fff;
  border-radius: 28px;
  padding: 3rem;
}

/*estilos a las cajas de los menuProfile y menuProfile*/
.box-general-payment-record .container-name-profile-student-pc {
  display: block;
}

.box-general-payment-record .container-name-profile-student-mobil {
  display: none;
}

/*estilos al menuProfile para este componente*/
.container-general-payment {
  background-color: #F8F8F8;
}

.container-general-payment .hi-there {
  color: #48676F;
  font-size: 3.0441400304vh;
  opacity: 0.68;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  margin-bottom: 0.5rem;
}

/*estilos a caja que contiene el contenido*/
.box-payment-record {
  display: flex;
}

.box-general-payment-record .box-payment-record .box-inside-payment-record .title-payment {
  color: #64949a !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700 !important;
  font-size: 3.0441400304vh !important;
  opacity: 0.68;
}

.box-general-payment-record .box-payment-record .box-inside-payment-record .title-new-card {
  color: #64949a !important;
  opacity: 0.8;
  font-family: Roboto, sans-serif !important;
  font-size: 3.0441400304vh !important;
  font-weight: 700;
  margin-bottom: 2rem;
}

/* estilo para tener mismo espacio entre elementos del navbar modo pc */
.box-general-payment-record .myOrka-link {
  margin-left: 0.8rem;
  margin-right: -1rem;
}

.box-general-payment-record .box-payment-record .box-inside-payment-record p {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh !important;
  font-weight: 400;
  margin-top: -0.3rem;
  opacity: 0.5;
}

.box-general-payment-record .box-payment-record .box-inside-payment-record:first-of-type {
  flex-basis: 41%;
}

.box-general-payment-record .box-payment-record .box-inside-payment-record:last-of-type {
  background-color: rgba(142, 178, 188, 0.5);
  border-radius: 1.5220700152vh;
  padding: 2rem;
  margin-left: 3.5rem;
  flex-basis: 60%;
}

.box-general-payment-record .box-payment-record .box-inside-payment-record:first-of-type button {
  background-color: transparent;
  border: none;
  color: #64949a;
  font-family: Roboto, sans-serif !important;
  font-weight: 600 !important;
  font-size: 2.2831050228vh !important;
}

.box-general-payment-record .box-payment-record .box-inside-payment-record:last-of-type .box-button-add-card {
  text-align: right;
  margin-top: 2rem;
}

.box-general-payment-record .box-payment-record .box-inside-payment-record:last-of-type .box-button-add-card button {
  background-color: rgba(107, 140, 149, 0.37);
  border: none;
  border-radius: 8px;
  color: rgba(107, 140, 149, 0.8);
  font-family: Roboto, sans-serif !important;
  font-size: 2.1308980213vh;
  font-weight: 600 !important;
  padding: 0.3rem 1.5rem;
}

/*esto es para que se oculte el h4 de modo mobil*/
.box-general-payment-record .box-payment-record .title-payment-mobil {
  display: none;
}

/*estilos para que se oculte el formulario mobil*/
.box-general-payment-record .box-form-stripe-mobil {
  display: none;
}

/*estilos al componente de help*/
.box-general-payment-record .box-help-mobil-payment-record {
  display: none;
}

@media (max-width: 768px) {
  /*estilos generales al navbar*/
  .box-general-payment-record .container-header-myOrka {
    background-color: #c0d1d5;
  }
  /*estilos a las opcines del navbar*/
  .box-general-payment-record #Linkstaticon {
    padding-left: 0;
  }
  .box-general-payment-record #Linkstaticob {
    padding-right: 0;
  }
  /*estilos a imagen de usuario grande*/
  .box-general-payment-record .container-name-profile-student-mobil .nav-user-icon {
    background-color: rgba(142, 178, 188, 0.2196078431) !important;
  }
  /*quitarle los margin a loa lados al navbar*/
  .box-general-payment-record .my-orka-nav-bar {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  /* estilo para tener mismo espacio entre elementos del navbar modo mobil */
  .box-general-payment-record .myOrka-link {
    margin-right: 1.4rem;
    margin-left: 0;
  }
  /*estilos a las cajas de los menuProfile y menuProfile*/
  .box-general-payment-record .container-name-profile-student-pc {
    display: none;
  }
  .box-general-payment-record .container-name-profile-student-mobil {
    display: block;
  }
  /*estilos al contenedor principal*/
  .box-general-payment-record .box-payment-record {
    flex-direction: column;
    padding: unset;
  }
  /*esto es para que se oculte el h4*/
  .box-general-payment-record .box-payment-record .title-payment {
    display: none;
  }
  /*esto es para que se muestre el h4 de modo mobil*/
  .box-general-payment-record .box-payment-record .title-payment-mobil {
    display: block;
  }
  /*estilos para que se oculte el formulario de tarjeta pc*/
  .box-general-payment-record .box-inside-payment-record:last-of-type {
    display: none;
  }
  /*estilos para que aparesca el formulario de tarjeta mobil*/
  .box-general-payment-record .box-form-stripe-mobil {
    display: block;
  }
  /*estilos aletras principales */
  .box-general-payment-record .box-payment-record .title-payment {
    color: #949494 !important;
    font-size: 1.8264840183vh !important;
  }
  /*estilos al componente de help*/
  .box-general-payment-record .box-help-mobil-payment-record {
    color: #64949a;
    display: block;
    padding-top: 10rem;
    padding-bottom: 3rem;
  }
  /*definir la posicion del elemento help*/
  .box-general-payment-record .box-help-mobil-payment-record .help {
    left: 2rem;
  }
  /*esto es para que se vea bien posicionado el mensaje*/
  .box-general-payment-record .box-help-mobil-payment-record .helpBox {
    margin-left: 0rem;
  }
}
.PreviewOnlineContainer {
  padding: 50px;
}
/* .content-reviews:first-of-type{
    margin-top: 0;
} */
.review-item_reviews {
  margin-top: 2rem;
}

.review-item_reviews:first-of-type {
  margin-top: 0;
}

.review-item-reviews-h3 {
  font-family: Roboto, sans-serif !important;
  font-weight: 600;
  font-size: 18px !important;
  color: #64949a !important;
  opacity: 0.68 !important;
  text-transform: none;
  margin-bottom: 0.3rem !important;
}

.review-item-reviews-p {
  font-family: Roboto, sans-serif !important;
  font-size: 12px !important;
  color: #949494 !important;
  opacity: 0.5 !important;
  margin: 0 !important;
}

.review-item-reviews-comment {
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-size: 15px;
  letter-spacing: 0px;
  color: #a1a1a1;
  padding: 20px 20px 0 20px;
  margin: 0 !important;
}
@media (max-width: 768px) {
  .box-general-reviews .container-header-myOrka {
    background-color: #c0d1d5;
  }
  /*en otros archivos de estilos se encuentra este selector con otras
  clases antecediendo a esta por ejemplo se puede encontrar la siguiente clase
  .container-name-profile-student-mobil pero aqui decidi solo especificar este
  selector. este selector es para darle color de fondo a la imagen de usuario grande*/
  .nav-user-icon {
    background-color: rgba(59, 107, 120, 0.2196078431) !important;
  }
}
.box-general-safety-instructor {
  border-radius: 4.2617960426vh;
}

/*para que se muestre el menu de pc*/
.container-general-safety-instructor .container-name-profile-student-pc {
  display: block;
}

/*para que se oculte el menu mobil*/
.box-general-safety-instructor .container-name-profile-student-mobil {
  display: none;
}

/*elementos del menu de pc (estos estilos estan en el componente pero ahora los puse aqui por temas de comodidad)*/
.container-general-safety-instructor .hi-there {
  color: #48676F;
  opacity: 0.68;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 3.0441400304vh !important;
  margin-bottom: 0.5rem;
}

.container-general-safety-instructor .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.3272450533vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.container-general-safety-instructor .myOrka-link {
  margin-left: 0.8rem;
  margin-right: -1rem;
}

.box-general-safety-instructor .container-row {
  padding: 3rem;
}

.container-general-safety-instructor .container-safety-instructor {
  background-color: #fff;
  border-radius: 1.75rem !important;
  padding: 4rem;
}

/* estilo para tener mismo espacio entre elementos del navbar modo pc */
.box-general-safety-instructor .myOrka-link {
  margin-left: 0.8rem;
  margin-right: 1rem;
}

.container-general-safety-instructor .container-safety-instructor h4.header-safety {
  color: #64949a !important;
  font-family: Roboto, sans-serif !important;
  font-size: 3.0441400304vh !important;
  font-weight: 700;
  opacity: 0.68;
}

.break-mobil {
  display: none;
}

.container-general-safety-instructor .container-safety-instructor p.text-safety {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 400;
  padding-right: 0;
  margin-top: -0.3rem;
  opacity: 0.5;
}

.container-general-safety-instructor .container-safety-instructor h5.header-unsafe-activity {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh !important;
  font-weight: 400 !important;
  margin-top: 2rem;
  margin-left: 2.8rem;
}

.container-general-safety-instructor .container-select-and-textarea {
  margin: 0 0 0 auto;
  width: 88%;
}

.container-general-safety-instructor .custom-select-safety {
  background-color: #fff;
  border: 0.5px solid rgba(112, 112, 112, 0.2);
  border-radius: 0.9132420091vh !important;
  color: #949494;
  margin-top: 2rem;
  margin-bottom: 1.2rem;
  /* 
  opacity: 0.40; */
  /* color: rgba(152, 152, 152, 0.40); */
  font-size: 2.2831050228vh;
  font-family: Roboto, sans-serif !important;
  font-weight: 400 !important;
  margin-top: 2rem;
  margin-bottom: 1.2rem;
  appearance: none;
  background: url(https://webdevpuneet.com/wp-content/uploads/2019/11/arrow.png) no-repeat calc(98% - 10px) 50%;
  padding-left: 1rem;
}

.container-general-safety-instructor .textarea-safety {
  border: 0.5px solid rgba(112, 112, 112, 0.2);
  border-radius: 0.9132420091vh;
}

.container-general-safety-instructor .custom-select-safety::placeholder {
  color: rgba(152, 152, 152, 0.4);
}

.container-general-safety-instructor select.custom-select-safety option:hover {
  background-color: #c0d1d5 !important;
}

.container-general-safety-instructor .textarea-safety {
  border: 0.5px solid rgba(112, 112, 112, 0.2);
  border-radius: 0.9132420091vh;
}

.container-general-safety-instructor .textarea-safety::placeholder {
  color: rgba(152, 152, 152, 0.4);
  font-size: 2.2831050228vh;
}

.container-general-safety-instructor .box-button {
  margin-top: 1.2rem;
  text-align: right;
}

.box-general-safety-instructor .box-help-mobil-safety-instructor {
  display: none;
}

@media (max-width: 768px) {
  /*para que se oculte el menu de pc*/
  .container-general-safety-instructor .container-name-profile-student-pc {
    display: none;
  }
  /*para que se muestre el menu mobil*/
  .box-general-safety-instructor .container-name-profile-student-mobil {
    display: block;
  }
  .box-general-safety-instructor .container-header-myOrka {
    background-color: #c0d1d5;
  }
  .container-general-safety-instructor .container-safety-instructor {
    padding: 0.5rem;
  }
  /*quitarle los margin a loa lados al navbar*/
  .box-general-safety-instructor .my-orka-nav-bar {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  /* estilo para tener mismo espacio entre elementos del navbar modo mobil */
  .box-general-safety-instructor .myOrka-link {
    margin-right: 1.2rem;
    margin-left: 0;
  }
  /*color de fondo de  la imagen grande del usuario*/
  .box-general-safety-instructor .container-name-profile-student-mobil .nav-user-icon {
    background-color: rgba(59, 107, 120, 0.2196078431) !important;
  }
  .box-general-safety-instructor .container-row {
    padding: unset;
  }
  .container-general-safety-instructor .container-safety-instructor {
    border-radius: unset !important;
  }
  .container-general-safety-instructor .container-safety-instructor h4.header-safety {
    display: none;
  }
  .container-general-safety-instructor .container-safety-instructor p.text-safety {
    padding-left: 0.5rem;
  }
  .container-general-safety-instructor .container-safety-instructor h5.header-unsafe-activity {
    margin-left: unset;
  }
  .container-general-safety-instructor .container-select-and-textarea {
    width: 100%;
  }
  /**/
  .box-general-safety-instructor .box-help-mobil-safety-instructor {
    color: #64949a !important;
    display: block;
    margin-top: 8rem;
    margin-bottom: 2rem;
  }
  .break-mobil {
    display: inline;
  }
  .container-general-safety-instructor .textarea-safety::placeholder {
    color: rgba(152, 152, 152, 0.4);
    font-size: 1.8264840183vh;
  }
  .box-help-mobil-safety-instructor .help {
    left: 2rem;
  }
  .box-general-safety-instructor .box-help-mobil-safety-instructor .helpBox {
    margin-left: 0rem;
    margin-bottom: 0.5rem;
  }
}
.container-general-safety {
  border-radius: 4.2617960426vh;
}

/*para que se muestre el menu de pc*/
.box-general-safety .container-name-profile-student-pc {
  display: block;
}

/*para que se oculte el menu mobil*/
.box-general-safety .container-name-profile-student-mobil {
  display: none;
}

/*elementos del menu de pc (estos estilos estan en el componente pero ahora los puse aqui por temas de comodidad)*/
.container-general-safety .hi-there {
  color: #48676F;
  opacity: 0.68;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 3.0441400304vh;
  margin-bottom: 0.5rem;
}

.container-general-safety .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.3272450533vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.container-general-safety .myOrka-link {
  margin-left: 0.8rem;
  margin-right: -1rem;
}

.box-general-safety .container-row {
  padding: 3rem;
}

.container-general-safety .container-safety {
  background-color: #fff;
  border-radius: 1.75rem !important;
  padding: 4rem;
}

/* estilo para tener mismo espacio entre elementos del navbar modo pc */
.box-general-safety .myOrka-link {
  margin-left: 0.8rem;
  margin-right: 1rem;
}

.container-general-safety .container-safety h4.header-safety {
  color: #64949a !important;
  font-family: Roboto, sans-serif !important;
  font-size: 3.0441400304vh !important;
  font-weight: 700 !important;
  opacity: 0.68;
}

/* .break-mobil{
    display: none;
} */
.container-general-safety .container-safety p.text-safety {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 0.9rem !important;
  font-weight: 400;
  margin-top: -0.3rem;
  opacity: 0.5;
}

.container-general-safety .container-safety .text-safety-span {
  font-family: Roboto, sans-serif !important;
  font-weight: 600 !important;
}

.container-general-safety .container-safety h5.header-unsafe-activity {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 2.2831050228vh !important;
  font-weight: 400 !important;
  margin-top: 2rem;
  margin-left: 2.8rem;
}

.container-general-safety .container-select-and-textarea {
  margin: 0 0 0 auto;
  width: 88%;
}

.container-general-safety .custom-select-safety {
  background-color: #fff;
  border: 0.5px solid rgba(112, 112, 112, 0.2);
  border-radius: 0.9132420091vh !important;
  color: #949494;
  /* 
  opacity: 0.40; */
  /* color: rgba(152, 152, 152, 0.40); */
  font-size: 2.2831050228vh;
  font-family: Roboto, sans-serif !important;
  font-weight: 400 !important;
  margin-top: 2rem;
  appearance: none;
  background: url(https://webdevpuneet.com/wp-content/uploads/2019/11/arrow.png) no-repeat calc(98% - 10px) 50%;
  padding-left: 1rem;
  margin-bottom: 1.2rem;
}

.container-general-safety .custom-select-safety::placeholder {
  color: rgba(152, 152, 152, 0.4);
}

.container-general-safety select.custom-select-safety option:hover {
  background-color: #c0d1d5 !important;
}

.container-general-safety .textarea-safety {
  border: 0.5px solid rgba(112, 112, 112, 0.2);
  border-radius: 0.9132420091vh;
}

.container-general-safety .textarea-safety::placeholder {
  color: rgba(152, 152, 152, 0.4);
  font-size: 2.2831050228vh;
}

.container-general-safety .box-button {
  margin-top: 1.2rem;
  text-align: right;
}

.box-general-safety .box-help-mobil-safety {
  display: none;
}

@media (max-width: 768px) {
  /*para que se oculte el menu de pc*/
  .box-general-safety .container-name-profile-student-pc {
    display: none;
  }
  /*para que se muestre el menu mobil*/
  .box-general-safety .container-name-profile-student-mobil {
    display: block;
  }
  .box-general-safety .container-header-myOrka {
    background-color: #c0d1d5;
  }
  .container-general-safety .container-safety {
    padding: 0.5rem;
  }
  /*quitarle los margin a loa lados al navbar*/
  .box-general-safety .my-orka-nav-bar {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  /* estilo para tener mismo espacio entre elementos del navbar modo mobil */
  .box-general-safety .myOrka-link {
    margin-right: 1.2rem;
    margin-left: 0;
  }
  /*color de fondo de  la imagen grande del usuario*/
  .box-general-safety .container-name-profile-student-mobil .nav-user-icon {
    background-color: rgba(59, 107, 120, 0.2196078431) !important;
  }
  .box-general-safety .container-row {
    padding: unset;
  }
  .container-general-safety .container-safety h4.header-safety {
    display: none;
  }
  .container-general-safety .container-safety p.text-safety {
    padding-left: 0.5rem;
  }
  .container-general-safety .container-safety h5.header-unsafe-activity {
    margin-left: unset;
  }
  .container-general-safety .container-select-and-textarea {
    width: 100%;
  }
  /**/
  .box-general-safety .box-help-mobil-safety {
    color: #64949a !important;
    display: block;
    margin-top: 14rem;
    margin-bottom: 2rem;
  }
  /* .break-mobil{
      display: inline;
  } */
  /* .container-general-safety .container-safety p.text-safety{
      padding: 0!important;
  } */
  /* .container-general-safety .container-safety{ 
      font-size: 1.82648401826484vh!important; // 12px
  } */
  .container-general-safety .textarea-safety::placeholder {
    color: rgba(152, 152, 152, 0.4);
    font-size: 1.8264840183vh;
  }
  .box-help-mobil-safety .help {
    left: 2rem;
  }
  .box-general-safety .box-help-mobil-safety .helpBox {
    margin-left: 0rem;
    margin-bottom: 0.5rem;
  }
}
/*estilos a contenedores de los campos de texto*/
.box-general-save-card-form .form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.box-general-save-card-form .form-row .form-group label {
  color: #64949a;
  font-family: Roboto, sans-serif !important;
  font-weight: 600 !important;
  font-size: 1.8264840183vh !important;
  opacity: 0.8;
}

.box-general-save-card-form .form-row .form-group .form-control {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #64949a;
  color: #fff;
  font-size: 2.2831050228vh;
}

.box-general-save-card-form .form-row .form-group .input-stripe-card-number {
  color: #000 !important;
}

.box-general-save-card-form .form-row .form-group .input-stripe-card-number::placeholder {
  color: #64949a !important;
  opacity: 0.15 !important;
}

.box-general-save-card-form .form-row .form-group:first-of-type {
  flex-basis: 70%;
}

.box-general-save-card-form .form-row .form-group:nth-child(2) {
  flex-basis: 25%;
}

.box-general-save-card-form .form-row .form-group:last-of-type {
  margin-top: -1.5rem;
}

.box-general-save-card-form .box-buttons {
  width: 100%;
}

.box-general-save-card-form .box-button-add-card {
  text-align: right;
}

.box-general-save-card-form .box-button-add-card button {
  background-color: rgba(107, 140, 149, 0.37);
  border: none;
  border-radius: 1.2176560122vh;
  color: rgba(107, 140, 149, 0.8);
  padding: 0.2rem 1.2rem;
  font-family: Roboto, sans-serif;
  font-weight: 600;
  font-style: normal;
}
@media (max-width: 768px) {
  /*estilos a los campos de texto del formulario mobil*/
  .save-card-form-mobil .box-button-add-card .link-add-card {
    color: #64949a;
    font-family: Roboto, sans-serif !important;
    font-size: 2.28311vh !important;
    font-weight: 600;
  }
  .box-general-save-card-form-mobil .form-control {
    border: none;
    border-bottom: 1px solid #e1e1e1;
    color: #949494;
    font-size: 1.8264840183vh;
    opacity: 0.5;
  }
  /*estilos para la primera caja de campos*/
  .box-general-save-card-form-mobil .save-card-form-mobil .form-row:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }
  /*estlos para definir ancho a las cajas que contienen ´los campos de texto de la tarjeta*/
  .box-general-save-card-form-mobil .save-card-form-mobil .form-row:nth-child(2) .box-input-card {
    flex-basis: 60%;
  }
  .box-general-save-card-form-mobil .save-card-form-mobil .form-row:nth-child(2) .box-input-date {
    flex-basis: 33%;
  }
  .box-general-save-card-form-mobil .save-card-form-mobil .form-row:nth-child(2) .box-input-cvv {
    flex-basis: 30%;
    margin-top: -1rem;
  }
  /*estilos para definir la disposicion de las cajas name, middlename y lastname*/
  .box-general-save-card-form-mobil .save-card-form-mobil .form-row:nth-child(5) {
    display: flex;
    flex-wrap: wrap;
    gap: 0.9rem;
  }
  .box-general-save-card-form-mobil .save-card-form-mobil .form-row.box-personal-info .form-group:nth-child(1),
  .box-general-save-card-form-mobil .save-card-form-mobil .form-row.box-personal-info .form-group:nth-child(2) {
    flex-basis: 47%;
  }
  .box-general-save-card-form-mobil .save-card-form-mobil .form-row.box-personal-info .form-group:nth-child(3) {
    flex-basis: 46%;
    margin-top: -2rem;
  }
  /*estilos para los campos de direccion, ciudad, estado, y zip code*/
  .box-general-save-card-form-mobil .save-card-form-mobil .form-row:nth-child(7) {
    display: flex;
    gap: 1rem;
  }
}
.schedule-bg {
  padding: 1.5rem 3rem 2rem 3rem;
  border-radius: 14px !important;
  overflow: auto;
  max-height: 650px;
  background-color: #FFFFFF !important;
}

.calendar-title {
  font: normal normal bold 20px/25px Roboto;
  color: rgba(107, 140, 149, 0.68);
  margin: 0;
}

.Cell-dayOfMonth {
  display: none;
}

.MainLayout-stickyElement.MainLayout-leftPanel.MainLayout-dayScaleEmptyCell.MainLayout-ordinaryLeftPanelBorder {
  border: none;
}

.MuiTable-root.Layout-timeScaleContainer.css-rqglhn-MuiTable-root {
  width: 100%;
}

.Label-label.css-1fbp63r {
  padding-right: 5px !important;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.Cell-cell.css-1675apn-MuiTableCell-root {
  background: rgba(142, 178, 188, 0.2196078431);
  padding-top: 16px;
  padding-bottom: 16px;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.Cell-cell.css-1675apn-MuiTableCell-root:first-child {
  border-radius: 16px 0 0 16px;
}

.MuiTableCell-root.MuiTableCell-body.MuiTableCell-sizeMedium.Cell-cell.css-1675apn-MuiTableCell-root:last-child {
  border-radius: 0 16px 16px 0;
}

.Cell-dayOfWeek {
  text-align: center;
  color: #64949a !important;
  opacity: 0.75;
  font-weight: bold;
}

.MuiTableRow-root.css-1q1u3t4-MuiTableRow-root {
  width: 40px !important;
  max-width: 40px !important;
}

@media (max-width: 768px) {
  .schedule-bg {
    padding: 10% !important;
  }
}
.form-check-input:checked {
  background-color: #c0d1d5 !important;
  border-color: #64949a !important;
}

.form-check-input:focus {
  border-color: #64949a !important;
  box-shadow: none !important;
}
.includedList {
  list-style-type: disc;
  padding-left: 1.5rem;
}

.includedItem {
  text-align: left;
  margin: 15px 0;
  opacity: 0.5;
  color: #A1A1A1;
  font-size: 20px;
  font-family: Roboto;
}

.staticInfo {
  margin: 15px 0;
  opacity: 0.5;
  color: #A1A1A1;
  font-weight: 100;
  font-size: 20px;
  font-family: Roboto;
}

.container-general-submit-document-instructor {
  border-radius: 4.2617960426vh;
}

/*para que se muestre el menu de pc*/
.box-general-submit-document-instructor .container-name-profile-instructor-pc {
  display: block;
}

/*para que se oculte el menu mobil*/
.box-general-submit-document-instructor .container-profile-contents {
  display: none;
}

/*elementos del menu de pc (estos estilos estan en el componente pero ahora los puse aqui por temas de comodidad)*/
.container-general-submit-document-instructor .hi-there {
  color: #48676F;
  opacity: 0.68;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 1.3rem !important;
  margin-bottom: 0.5rem;
}

.container-general-submit-document-instructor .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.3272450533vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.box-general-submit-document-instructor .myOrka-link {
  margin-left: 0.8rem;
  margin-right: 1rem;
}

.box-general-submit-document-instructor .modal-content {
  width: 100vw !important;
}


.container-general-submit-document-instructor .container-personal-documents {
  background-color: #fff;
  border-radius: 1.75rem !important;
  padding: 4rem;
}

/*texto de submit documents*/
.container-general-submit-document-instructor .container-personal-documents h4.titles-sections-info {
  color: #64949a !important;
  font-family: Roboto, sans-serif !important;
  font-size: 3.0441400304vh !important;
  font-weight: 700;
  opacity: 0.68;
}

/*texto de here you can*/
.container-general-submit-document-instructor .container-personal-documents p.text-submit-documents {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh !important;
  font-weight: 400;
  margin-top: 1rem;
  opacity: 0.5;
}

/*textos de TSA Check Report y Goverment I.D*/
h5.header-submit-documents {
  color: #949494;
  display: inline-block;
  font-family: Roboto, sans-serif !important;
  font-size: 20px !important;
  font-weight: 700;
  margin-top: 1.6rem;
  margin-right: 1rem;
}

/*margin right a texto de Goverment I.D*/
.container-general-submit-document-instructor .container-personal-documents h5.header-submit-documents.header-goverment {
  margin-right: 1.8rem;
}

/*ocultar los botones de take a photo vista*/
.container-general-submit-document-instructor .container-personal-documents .box-button-photo {
  display: block;
}

/* .box-general-submit-document-instructor .icon-user-header{
    background-color: #fff!important;
} */
.container-personal-documents .box-element-span-document-missing-warning {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

/*reajustar el width en la caja de document missing*/
.box-general-document-missing {
  display: flex;
  align-items: baseline;
}

.container-personal-documents .box-buttons-upload-photo {
  display: flex;
}

.container-personal-documents .box-buttons-upload-photo .box-button-upload {
  background-color: rgba(107, 140, 149, 0.2);
  border-radius: 0.9132420091vh;
  color: #64949a;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh;
  font-weight: 600;
  /* flex-basis: 7.0625rem; //113px; */
  flex-basis: 8.4187408492vw;
}

.container-personal-documents .box-buttons-upload-photo .box-button-upload #IconPadding {
  padding-right: 0.5rem !important;
}

/*estilos para el componente de help*/
.box-general-submit-document-instructor .box-help-mobil-submit-document-instructor {
  display: none;
}

/*mostrar y ocultar los menus*/
.container-profile-contents {
  display: none;
}

/*estilos para mostrar el componente generico*/
.container-generic {
  display: block;
}

@media (max-width: 768px) {
  .box-general-submit-document-instructor #Linkstaticob {
    padding-right: 0.375rem;
  }
  /*ocultar el menu de pc*/
  .box-general-submit-document-instructor .container-name-profile-instructor-pc {
    display: none;
  }
  /*mostrar el menu mobil*/
  .box-general-submit-document-instructor .container-profile-contents {
    display: block;
  }
  /*hacer mas pequeño el header el tamaño al header de submit documents*/
  .container-general-submit-document-instructor .container-personal-documents h4.titles-sections-info {
    display: none;
  }
  .container-general-submit-document-instructor .container-personal-documents p.text-submit-documents {
    padding-right: 2rem;
  }
  /*hacer mas pequeño los headers de TSA Check report y goverment I.D*/
  .container-general-submit-document-instructor .container-personal-documents h5.header-submit-documents {
    font-size: 16px !important;
  }
  /*==========================<estilos al navbar>==========================*/
  /*este estilo es para el color de fondo del navbar*/
  .box-general-submit-document-instructor .container-header-myOrka {
    background-color: #c0d1d5;
  }
  /*quitarle los margin a loa lados al navbar*/
  .box-general-submit-document-instructor .my-orka-nav-bar {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  .box-general-submit-document-instructor .container-name-profile-student-mobil {
    border-radius: 0 0 1.8rem 1.8rem;
    display: block;
  }
  /*color de fondo de  la imagen grande del usuario*/
  .box-general-submit-document-instructor .container-profile-contents .nav-user-icon {
    background-color: rgba(59, 107, 120, 0.2196078431) !important;
  }
  /*estilos para el color del usuario*/
  .box-general-submit-document-instructor .container-profile-contents .icon-user-main {
    color: #8db2bc !important;
    border-radius: 5rem;
    display: inline-block;
    padding: 0.1rem;
    height: 4.5rem !important;
    width: 4.5rem !important;
  }
  /*separacion arriba de l texto de hi there*/
  .box-general-submit-document-instructor .container-profile-contents .hi-there {
    margin-top: 0.8rem;
  }
  /*color al texto del nombre del usuario y separacion arriba*/
  .box-general-submit-document-instructor .container-profile-contents .full-name-person {
    color: #64949a !important;
    margin-top: -0.5rem;
  }
  /* estilo para tener mismo espacio entre elementos del navbar modo mobil */
  .box-general-submit-document-instructor .myOrka-link {
    margin-right: 1.2rem;
    margin-left: 0;
  }
  /*==========================</estilos al navbar>==========================*/
  .container-general-submit-document-instructor .container-personal-documents {
    padding: unset;
  }
  .container-general-submit-document-instructor {
    background-color: #fff !important;
  }
  /*modifique la propiedad col solo a mobil*/
  .container-general-submit-document-instructor .container-personal-documents .col {
    flex: 0 0 !important;
  }
  .container-personal-documents .box-buttons-upload-photo {
    justify-content: space-around;
  }
  .container-personal-documents .box-buttons-upload-photo .box-inside-button-upload-photo {
    flex-basis: calc(54% - 0.1rem);
    padding: 0rem 0.1rem 0 0.1rem !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  /*se le quito el padding a los campos de texto*/
  .container-personal-documents .box-buttons-upload-photo .box-inside-button-upload-photo#BtnSubmit {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .container-personal-documents .box-buttons-upload-photo .box-button-upload #IconPadding,
  .container-personal-documents .box-buttons-upload-photo .box-button-photo #IconPadding {
    padding-right: 5px !important;
  }
  .container-personal-documents .box-buttons-upload-photo span {
    font-size: 12px !important;
  }
  .container-personal-documents .box-buttons-upload-photo > div {
    /* flex-basis: 100%; */
    flex: 0 0 calc(100% - 1rem);
    /* padding: 0; */
  }
  .container-general-submit-document-instructor .container-personal-documents .box-button-photo {
    display: inline-block;
  }
  /*estilos para el componente de help*/
  .box-general-submit-document-instructor .box-help-mobil-submit-document-instructor {
    color: #64949a;
    display: block;
    margin-top: 8rem;
    margin-bottom: 2rem;
    margin-left: -1rem;
  }
  .box-general-submit-document-instructor .box-help-mobil-submit-document-instructor .helpBox {
    margin-left: -8px;
    margin-bottom: 0.5rem;
  }
  /*mostrar y ocultar los menus*/
  .container-profile-contents {
    display: block;
  }
  /*estilos para mostrar el componente generico*/
  .container-generic {
    display: none;
  }
}

p#text-drag-drop {
  color: #64949a !important;
}

.container-general-submit-document-student {
  border-radius: 4.2617960426vh;
}

.box-general-submit-document-student .container-name-profile-student-pc {
  display: block;
}

.box-general-submit-document-student .container-name-profile-student-mobil {
  display: none;
}

.box-name-profile-student-mobil .hi-there {
  color: #48676f;
  font-weight: 600;
}

.container-general-submit-document-student .full-name-person {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 5.3272450533vh !important;
  margin-top: -0.7rem;
  margin-bottom: 1.5rem;
}

.container-general-submit-document-student .hi-there {
  color: #48676f;
  opacity: 0.68;
  font-family: Roboto, sans-serif !important;
  font-weight: 700;
  font-size: 1.3rem;
  margin-bottom: 0.5rem;
}

.container-general-submit-document-student .container-personal-documents {
  background-color: #fff;
  border-radius: 1.75rem !important;
  padding: 4rem;
}

.container-general-submit-document-student .container-personal-documents h4.titles-sections-info {
  color: #64949a !important;
  font-family: Roboto, sans-serif !important;
  font-size: 3.0441400304vh !important;
  font-weight: 700;
  opacity: 0.68;
}

.container-general-submit-document-student .container-personal-documents p.text-submit-documents {
  color: #949494 !important;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh !important;
  font-weight: 400;
  margin-top: 1rem;
  opacity: 0.5;
}

h5.header-submit-documents {
  color: #949494;
  display: inline-block;
  font-family: Roboto, sans-serif !important;
  font-size: 20px !important;
  font-weight: 700;
  margin-top: 1.6rem;
  margin-right: 1rem;
}

.container-general-submit-document-student .container-personal-documents h5.header-submit-documents.header-goverment {
  margin-right: 1.8rem;
}

.container-general-submit-document-student .container-personal-documents .box-button-photo {
  display: block;
}

.box-general-submit-document-student .icon-user-header {
  background-color: #fff !important;
}

/* estilo para tener mismo espacio entre elementos del navbar modo pc */
.box-general-submit-document-student .myOrka-link {
  margin-left: 0.8rem;
  margin-right: 1rem;
}

.container-personal-documents .box-element-span-document-missing-warning {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}

/*reajustar el width en la caja de document missing*/
.box-general-document-missing {
  display: flex;
  align-items: baseline;
}

.box-image-warning {
  margin-right: 0.5rem;
}

.container-personal-documents .box-buttons-upload-photo {
  display: flex;
}

.container-personal-documents .box-buttons-upload-photo .box-button-upload {
  background-color: rgba(107, 140, 149, 0.2);
  border-radius: 0.9132420091vh;
  color: #64949a;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh;
  font-weight: 600;
  /* flex-basis: 7.0625rem; //113px; */
  flex-basis: 8.4187408492vw;
}

.container-personal-documents .box-buttons-upload-photo .box-button-upload #IconPadding {
  padding-right: 0.5rem !important;
  height: 15px !important;
  width: 18px !important;
}

/*estilos para el componente de help*/
.box-general-submit-document-student .box-help-mobil-submit-document-student {
  display: none;
}

/*
estilos para el template donde se muestra el archivo
*/
/* .containerGeneralFileUploaded{
    margin-top: 1.2rem;
    width: 25%;
} */
/* .containerFileUploaded{
    display: flex;
    justify-content: space-between;
} */
/*estilos para el componente generico que se muestre en vista pc*/
.container-generic {
  display: block;
}

/*estilos para que se oculte elm menu mobil*/
.container-name-profile-student-mobil {
  display: none;
}

@media (max-width: 768px) {
  .box-general-submit-document-student #Linkstaticob {
    padding-right: 0.375rem;
  }
  .box-general-submit-document-student .container-name-profile-student-pc {
    display: none;
  }
  .box-general-submit-document-student .container-name-profile-student-mobil {
    display: block;
  }
  /*cambiarle el tamaño al header h4*/
  .container-general-submit-document-student .container-personal-documents h4.titles-sections-info {
    display: none;
  }
  .container-general-submit-document-student .container-personal-documents p.text-submit-documents {
    padding-right: 2rem;
  }
  .container-general-submit-document-student .container-personal-documents h5.header-submit-documents {
    font-size: 16px !important;
  }
  .box-general-submit-document-student .container-header-myOrka {
    background-color: #c0d1d5;
  }
  /*cambiar el background de imagen de usuario del header*/
  .box-general-submit-document-student .icon-user-header {
    background-color: #c0d1d5 !important;
  }
  .box-general-submit-document-student .container-name-profile-student-mobil .nav-user-icon {
    background-color: rgba(142, 178, 188, 0.2196078431) !important;
  }
  /*quitarle los margin a loa lados al navbar*/
  .box-general-submit-document-student .my-orka-nav-bar {
    margin-left: unset !important;
    margin-right: unset !important;
  }
  /* estilo para tener mismo espacio entre elementos del navbar modo mobil */
  .box-general-submit-document-student .myOrka-link {
    margin-right: 1.2rem;
    margin-left: 0;
  }
  .box-general-submit-document-student .container-name-profile-student-mobil {
    border-radius: 0 0 1.8rem 1.8rem;
    display: block;
  }
  .container-general-submit-document-student .container-personal-documents {
    padding: unset;
  }
  .container-general-submit-document-student {
    background-color: #fff !important;
  }
  /*modifique la propiedad col solo a mobil*/
  .container-general-submit-document-student .container-personal-documents .col {
    flex: 0 0 !important;
  }
  .container-personal-documents .box-buttons-upload-photo {
    justify-content: space-around;
  }
  /*.container-personal-documents .box-buttons-upload-photo .box-button-upload
     {
         flex-basis: 45%;
         padding: 0.3rem!important;
     }*/
  .container-personal-documents .box-buttons-upload-photo .box-inside-button-upload-photo {
    flex-basis: calc(50% - 0.1rem);
    padding: 0rem 0.1rem 0 0.1rem !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
  }
  /*se le quito el padding a los campos de texto*/
  .container-personal-documents .box-buttons-upload-photo .box-inside-button-upload-photo#BtnSubmit {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }
  .container-personal-documents .box-buttons-upload-photo .box-button-upload #IconPadding,
  .container-personal-documents .box-buttons-upload-photo .box-button-photo #IconPadding {
    padding-right: 5px !important;
  }
  .container-personal-documents .box-buttons-upload-photo span {
    font-size: 12px;
  }
  .container-personal-documents .box-buttons-upload-photo > div {
    /* flex-basis: 100%; */
    flex: 0 0 calc(100% - 1rem);
    /* padding: 0; */
  }
  .container-general-submit-document-student .container-personal-documents .box-button-photo {
    display: inline-block;
  }
  /*estilos para el componente de help*/
  .box-general-submit-document-student .box-help-mobil-submit-document-student {
    color: #64949a;
    display: block;
    margin-top: 16rem;
    margin-bottom: 2rem;
    margin-left: -1rem;
  }
  .box-general-submit-document-student .box-help-mobil-submit-document-student .helpBox {
    margin-left: -8px;
    margin-bottom: 0.5rem;
  }
  /*
         estilos para el template donde se muestra el archivo
     */
  .containerGeneralFileUploaded {
    width: 90%;
  }
  /*estilos para el componente generico que se oculte en vista pc*/
  .container-generic {
    display: none;
  }
  /*estilos para que se muestre el menu mobil*/
  .container-name-profile-student-mobil {
    display: block;
  }
}
.main-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 85vh;
}

.success-container {
  align-items: center;
  background-color: #8db2bc;
  border-radius: 16px;
  display: flex;
  height: 65vh;
  flex-direction: column;
  justify-content: center;
  width: 90%;
}

.success-title {
  color: #64949a;
}

.success-text {
  color: #64949a;
  font-size: 20px;
  text-align: center;
  width: 40%;
}

@media (max-width: 1260px) {
  .success-text {
    font-size: 18px;
    width: 50%;
  }
}
@media (max-width: 992px) {
  .success-text {
    width: 60%;
  }
}
.success-link {
  color: #64949a;
  font-size: 16px;
  text-align: center;
  font-weight: 700;
  cursor: pointer;
}

@media (max-width: 576px) {
  .wall-success {
    background-color: #8db2bc !important;
    height: 100vh;
  }
  .success-title {
    font-size: 48px !important;
  }
  .success-text {
    width: 90%;
    font-size: 16px;
  }
  .success-link {
    font-size: 14px;
  }
}
.first-box-summary .box-almost-there p {
  color: #64949a;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 0;
}

.first-box-summary .box-tip p {
  color: rgba(71, 71, 71, 0.5882352941);
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 400;
  font-style: normal;
}

.first-box-summary .box-view-syllabus .link-view-syllabus {
  color: rgba(71, 71, 71, 0.5882352941);
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  font-style: normal;
  text-decoration: underline;
}

.second-box-summary .hask-link-see-available-classes {
  color: #64949a;
  font-family: Roboto, sans-serif;
  font-size: 0.75rem;
  font-weight: 700;
  font-style: normal;
}
.lesson-upcoming {
  background-color: #c0d1d5;
  border-radius: 1rem;
  display: flex;
  align-items: center;
  gap: 0.8rem;
  opacity: 0.7;
  padding: 0.2rem 1rem;
  margin-bottom: 1rem;
}

.lesson-upcoming p {
  margin-bottom: 0;
}

.lesson-upcoming .box-day-month p:nth-child(1) {
  color: #e9a535;
  font-family: Roboto, sans-serif;
  font-size: 1rem;
  font-weight: 900;
  font-style: normal;
}

.lesson-upcoming .box-day-month p:nth-child(2) {
  color: #64949a;
  font-family: Roboto, sans-serif;
  font-size: 0.625rem;
  font-weight: 700;
  font-style: normal;
}

.lesson-upcoming .box-name-lesson-and-hour p:nth-child(1) {
  color: #000;
  font-size: 12px;
  font-family: Roboto, sans-serif;
  font-weight: 900;
  font-style: normal;
  opacity: 0.68;
}

.lesson-upcoming .box-name-lesson-and-hour p:nth-child(2) {
  color: #000;
  font-family: Roboto, sans-serif;
  font-weight: 400;
  font-style: normal;
  opacity: 0.5;
}
.test {
  width: 30%;
}

.player__play-icon {
  color: #FF00FF;
}
.container-general-welcome {
  margin: 5vh auto auto;
  width: 75%;
}

.box-general-welcome .container-img-header {
  background-color: rgba(142, 178, 188, 0.61);
  border-radius: 35px;
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.sub-container-general-welcome {
  margin-top: 25%;
  transform: translateY(-25%);
}

.container-img-header img {
  margin-right: 2.2rem;
  width: 50%;
}

.container-img-header .img-welcome {
  color: rgba(255, 255, 255, 0.61);
  margin-right: 2.2rem;
  width: 50%;
}

/*estilos al componente de help*/
.box-general-welcome .boxHelpWelcome {
  color: #64949a;
}

.box-general-welcome .boxHelpWelcome .help {
  left: 2rem;
  bottom: 0rem;
}

.box-general-welcome .boxHelpWelcome .helpBox {
  margin-left: 5.2rem;
}

@media (max-width: 768px) {
  .box-general-welcome {
    background-color: #adbfc5 !important;
  }
  .box-general-welcome .container-img-header {
    background-color: unset;
    height: 99vh;
  }
  .box-general-welcome .container-img-header #WelcomeMember {
    font-size: 3.0441400304vh !important;
  }
  /*oculta la caja de la imagen*/
  .container-img-header .box-img {
    display: none;
  }
  /*estilos a componente de help*/
  .box-general-welcome .boxHelpWelcome .helpBox {
    margin-left: -0.5rem;
  }
  .box-general-welcome .boxHelpWelcome .help {
    left: 2rem;
    bottom: 0rem;
  }
}

.Amarillo {
  color: #e9a535;
}

.Aktiv-grotesk {
  font-family: aktiv-grotesk;
}

h1 {
  color: #949494 !important;
  font-family: Roboto Bl;
  font-size: 65px !important;
  text-transform: uppercase;
  font-weight: bolder !important;
}

h2 {
  color: #64949a;
  font-size: 70px !important;
  font-family: Roboto Bl !important;
  font-weight: bolder !important;
}

h3 {
  color: #858585 !important;
  font-family: Roboto Bl !important;
  font-size: 50px !important;
  text-transform: uppercase;
}

h4 {
  color: #858585 !important;
  font-family: Roboto !important;
  font-size: 20px !important;
  font-weight: bold !important;
}

h5 {
  color: #adbfc5 !important;
  font-family: Roboto !important;
  font-size: 20px !important;
}

h6 {
  color: #adbfc5 !important;
  font-family: Roboto !important;
  font-size: 15px !important;
  font-weight: bold !important;
}

.link {
  color: #e9a535;
  font-weight: bold;
  text-decoration: underline !important;
}

.link:hover {
  color: #e9a535;
}

ul {
  list-style: none;
}

ul li::before {
  color: #64949a;
  font-weight: 5px;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.yellowText {
  color: #e9a535;
  font-weight: bold;
  font-family: Roboto;
  font-size: 30px;
}

.box {
  width: fit-content;
  height: fit-content;
  padding: 2%;
  background-color: #f8f8f8;
  border-radius: 10px 10px 10px 10px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.my-orka-nav-bar {
  /*
   esto lo agregue para que se viera centrado verticalmente 
   para resolver lo de la diapositiva #1
   + ya no se deberia de ver mal
   */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding-left: 5%;
  padding-right: 5%;
  width: 100%;
}

@media (max-width: 768px) {
  .my-orka-nav-bar {
    padding-left: unset;
    padding-right: unset;
  }
}
.NavBackground {
  background: #F8F8F8;
}

.rightElements {
  justify-content: flex-end !important;
}

Nav {
  font-family: Roboto;
  font-size: 18px;
  letter-spacing: 1px;
}

#Linknormal {
  color: #64949a;
  padding-right: 30px;
}

#Linknormal:hover {
  font-weight: bold;
  color: #64949a;
  letter-spacing: 3px;
}

#Linkstaticon {
  color: #64949a;
  font-weight: bold;
  padding-right: 30px;
  font-size: 2.2831050228vh;
}

#Linkstaticob {
  color: #e9a535;
  font-weight: bold;
  opacity: 1 !important;
}

* {
  box-sizing: border-box;
}

#content {
  background-color: transparent;
  border: 0px;
  display: flex;
  height: auto;
  /* position: relative; */
  overflow: hidden;
}

#left {
  background-color: transparent;
  border: 0px;
  padding: 0 0.5rem 0 2rem;
  flex: 30%;
}

#right {
  border-radius: 18px !important;
  margin-bottom: 15vh;
  background-color: transparent;
  border: 0px;
  padding: 0;
  flex: 85%;
  height: 80vh !important;
  overflow: hidden;
}

/*---------------------VIDEOS------------------*/
.Videos {
  display: none;
}

.trailer {
  width: 1000px;
  height: 550px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  padding-bottom: 5%;
}

.freeRecom {
  width: 400px;
  height: 250px;
}

.contVideos {
  padding-bottom: 5%;
}

/*-----------CABINA HOME------------ */
.imgcab {
  background-position: center center;
  background-repeat: no-repeat;
  position: relative;
  background-image: linear-gradient(rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0.6)), url("./components/imagenes/cabina.png");
  height: 70vh;
}

.homedesc {
  background-color: white;
  border-radius: 5px;
  /* top: 5rem;
     right: 10rem;
     bottom: 10rem;
     left: 10rem; */
  height: auto;
  /* border-radius: 1rem;
     position: relative;
     left: 7.5%;
     width: 85%;
     height: 60%; */
}

.homedesc h1 {
  margin-bottom: 2rem;
}

.homedesc p {
  color: #a1a1a1;
  font-size: 1.2rem;
}

.homedesc .desc {
  margin-top: 0%;
  margin-left: 0%;
  margin-right: 0%;
  padding: 0vh 8vw;
}

.desc-mobileText {
  display: none;
}

/* .cont {
    width: 100%;
    height: 80%;
    padding-top: 3%;
    padding-bottom: 10%;
} */
.cursos {
  top: 120%;
}

/* .cabcont {
    width: 100%;
} */
.info {
  padding-top: 400px;
  padding-left: 20%;
}

/* -----------------cursos--------------------*/
.Cursos {
  padding-top: 5%;
}

.legend-experiences {
  width: 100px;
}

.Cursos .legend-experiences-span {
  font-weight: bold;
}

.box-experiences-pc,
.box-experiences-mobil {
  height: 14vh;
}

.box-experiences-pc {
  width: 45% !important;
}

.box-experience-pc p {
  font-weight: lighter;
  margin-bottom: 0;
}

.box-experience-pc {
  padding-left: 1.6rem;
  padding-right: 1.8rem;
}

.box-experiences-pc .label-Onsite,
.box-experiences-pc .label-Online {
  font-size: 1.1rem;
}

.box-experience-pc,
.box-experience-mobil {
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: 100%;
}

.box-experiences-mobil {
  display: none;
}

.Separator-box-experience {
  border: 1px solid #949494;
  height: 8vh;
}

.cadaCurso {
  padding-top: 5%;
  /* border: 1px solid black; */
  color: #adbfc5;
}

.cadaCurso .row {
  margin-bottom: 5rem;
}

.cadaCurso .row:last-of-type {
  margin-bottom: 0;
}

/*en el archivo custome.scss de la rama Ticket-X6-I188_V2 seguiria un estilo que en el custome.scss de la rama subrama no esta y es el siguiente (lo voy a comentar):*/
/* .inside-boxcadaCurso{
    display: flex;
    align-items: center;
} */
.carouselImageContainer {
  aspect-ratio: 1/1;
  width: 100%;
}

.carouselImage {
  object-fit: cover;
  aspect-ratio: 1/1;
  width: 100%;
}

.desc {
  text-align: center;
  margin-top: 5%;
  margin-left: 4%;
  margin-right: 4%;
  padding-top: 3%;
  padding-bottom: 5%;
}

.desc h1 {
  margin-bottom: 10px;
}

.desc p {
  padding: 0 40px;
  font-size: 1rem;
  font-weight: 300;
}

.cursos {
  top: 120%;
}

.cabcont {
  width: 100%;
}

.info {
  padding-top: 400px;
  padding-left: 20%;
}

/* -----------------Experiences--------------------*/
.expTitle {
  letter-spacing: 3px;
  margin: 0;
  margin-bottom: 0.5rem;
  font-size: 5vh !important;
}

.legend-experiences-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-right: 0px !important;
  padding-right: 1vw;
  padding-left: 1vw;
}

.legend-experiences p {
  width: 90%;
  font-size: 16px;
  font-weight: 300;
  line-height: 25px;
  text-align: left;
  letter-spacing: 1px;
  margin: 0;
}

/* -----------------cursos--------------------*/
.bolitas {
  color: #e9a535;
  font-size: 100px;
  margin-top: -10vh;
  letter-spacing: 2vw;
}

.cadaCurso {
  padding: 5%;
}

.cadaCurso-item {
  /* display: flex; */
  align-items: center;
}

.classDesc {
  padding-left: 5%;
  padding-right: 3%;
  padding-top: 10%;
}

.classInfo {
  width: 400px;
  height: 500px;
  padding-top: 10% !important;
  padding: 6%;
  background-color: #f8f8f8;
  border-radius: 10px 10px 10px 10px;
  text-align: center;
}

.descInfo {
  list-style: disc;
  text-align: left;
  padding-left: 0.7rem;
}

.descInfo li {
  width: max-content;
  font-size: 0.7rem;
}

.descInfo li::marker {
  color: #8db2bc;
  font-size: 1.5rem !important;
  line-height: 1.5rem;
}

/*en el archivo custom.scss de la rama subrama no esta el siguiente estilo que esta en el custome.scss de la rama:Ticket-x6-I188_V2 (lo dejare comentado)*/
/* #classDescLeft {
    text-align: right;

} */
/* .descInfo li::before {
  color: $Grisverde;
  content: "\2022";
  font-weight: bold;
  display: inline-block;
  margin-left: -1em;
} */
#classNameDescRight {
  text-align: left;
}

#classNameDescLeft {
  text-align: right;
  margin-left: 70px;
}

.classNameDesc {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.container-curso {
  display: flex;
  align-items: center;
  align-content: center;
}

.inside-boxcadaCurso {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 40px;
  background: #f8f8f8;
  border-radius: 20px;
}

.text-cadaCurso {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.text-cadaCurso h2 {
  line-height: 50px;
  margin: 0;
}

.cadaCurso-subtitle {
  font-size: 2rem;
}

.text-cadaCurso button {
  width: 100%;
}

.box-carousel {
  width: 55%;
  margin-left: 3rem !important;
}

.getInTouch {
  align-content: center !important;
}

.getInTouch h1 {
  text-align: center;
  margin-bottom: 5vh;
  font-size: 3.2rem !important;
}

.getInTouch Container {
  background-color: #f8f8f8;
}

.getInTouch .label-form-getInTouch-pc {
  letter-spacing: 0.01rem;
}

.googleMaps {
  padding-right: 300px;
}

.container-form {
  max-width: 1200px;
  width: 90%;
  margin: 0 auto;
}

.formulario {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 2rem;
}

.formulario form {
  width: 100%;
}

.form-group {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.form-group label {
  font-size: 17px;
  font-weight: 600;
  color: #949494;
}

.formGet {
  padding: 50px;
  padding: 3.8rem 4rem;
  width: 100% !important;
}

.getInTouch .box-first-two-inputs-getInTouch-pc {
  margin-bottom: 0.8rem;
}

.getInTouch-input {
  background: rgba(226, 226, 226, 0.4509803922);
  border: none;
  padding: 5px 10px;
  border-radius: 0.5rem;
}

.formulario button {
  color: #fff;
  width: 100%;
}

.guttonGet {
  width: 100px !important;
  background-color: #e9a535 !important;
}

.text-area {
  height: 15vh;
}

.orka-ubication {
  width: 30%;
}

#root > div > div:nth-child(3) > div.Curso.mt-1 > div > div.cadaCurso > div.getInTouch.mt-5 > div > div > div > div > div > div.leaflet-container.leaflet-touch.leaflet-fade-anim.leaflet-grab.leaflet-touch-drag.leaflet-touch-zoom {
  height: 35vh !important;
}

.orka-ubication_titlePc {
  margin: 10px 0 0 0;
}

.orka-ubication_title {
  display: none;
}

.orka-ubication p {
  font-size: 14px;
  margin: 0;
}

.orka-ubication span {
  color: #e9a535;
  font-weight: bold;
  letter-spacing: 2px;
}

/* -----------------FOOTER----------------------------*/
hr {
  color: #64949a;
  background-color: #64949a;
  height: 2%;
}

footer {
  display: flex;
  justify-content: center;
  /* color: $greenFooter; */
  padding-bottom: 2rem;
  /* align-content: center; */
  text-align: center !important;
  align-content: center !important;
  width: 100%;
}

.facebook {
  background-position: center center;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./components/imagenes/facebook.png");
  height: 20px;
  width: 20px;
  filter: #64949a;
}

.instagram {
  background-position: center center;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./components/imagenes/instagram.png");
  height: 20px;
  width: 20px;
}

.linkedin {
  position: relative;
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("./components/imagenes/linkedin.png");
  height: 20px;
  width: 20px;
}

/**/
.PieOrka {
  width: 80%;
}

.address {
  padding-top: 1rem;
}

.address .normal {
  font-size: 1rem;
  font-weight: 200;
  margin: 0;
}

.PieOrka .orkafoter-number {
  color: #64949a;
  font-family: Roboto, sans-serif;
  font-weight: 700;
  font-style: normal;
  letter-spacing: 0.28rem;
  margin-bottom: 0.5rem;
  opacity: 0.65;
}

/* .box__PieOrka-content{
  display: flex;
} */
.iconos {
  display: flex;
  width: 100% !important;
  justify-content: center;
  gap: 1vw;
}

.socialMedia .iconos {
  display: flex;
  gap: 1.6rem !important;
}

.socialMedia .iconos a {
  font-size: 1.8rem;
  color: #64949a;
}

.socialMedia .normal {
  color: #64949a;
  margin: 0;
  opacity: 0.65;
  margin-bottom: 1rem;
}

.PieOrka .iconos a:last-of-type {
  margin-right: unset;
}

.orkafooter {
  color: #64949a !important;
  text-transform: uppercase;
  font-size: 2.5rem;
  margin-bottom: 0.2rem;
}

.orkafooter-number {
  letter-spacing: 3px;
  color: #64949a;
}

/*-------------------------------- LOGIN --------------------------- */
#titleLogin {
  letter-spacing: 3px;
}

.buttonSlider {
  color: white;
  background-color: #e9a535;
  border-radius: 50px;
  border: none;
}

#BtnLogIn {
  border-radius: 10px;
  color: white;
  background-color: #a1a1a1;
  font-weight: bold;
  width: 100%;
}

/*Elementos usados en la ventana login */
#margen1-login {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#margen2-login {
  margin-top: 30px;
}

#margen3-login {
  margin-top: 20px;
}

#margen4-login {
  margin-top: 30px;
  margin-bottom: 40px;
  display: flex;
}

.redondeo-y-background-de-textbox {
  border-radius: 8px !important;
  background-color: #c0d1d5 !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 400;
  opacity: 0.5;
}

#SaltoDelUlimoBoton {
  border-radius: 20px;
  margin-top: 20px;
}

/*Elementos usados en la ventana login */
#ColorWelcome {
  background-color: #adbfc5;
}

#GreenOne {
  background-color: #c0d1d5;
}

#GreenTwo {
  color: #707070;
}

#MainGreenColorBackground h3 {
  /* font-size: 4.566210045662101vh!important; */
  color: #64949a !important;
}

/* #MainWitheColorBackground h3 {
    font-size: 4.566210045662101vh!important; // 30px
    color: $Gray;
} */
#MainGreenColorBackground p {
  color: #64949a;
  /* font-size: 16px !important; */
  padding-left: 10%;
  padding-right: 10%;
}

.Height80p {
  height: 80%;
}

#WelcomeCentrado {
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 60%;
  color: #64949a !important;
}

#MargenAbajo {
  margin-bottom: 60%;
  color: #64949a;
}

#TextoSingUp {
  margin-top: 50px;
}

#BotonRegistroInstructor {
  background-color: #c0d1d5;
  border: none;
  padding-left: 90px;
  padding-right: 90px;
  border-radius: 10px;
  color: #949494;
  margin-top: 30px;
  font-weight: bold;
}

#BotonRegistroEstudiantes {
  background-color: #c0d1d5;
  border: none;
  padding-left: 90px;
  padding-right: 90px;
  border-radius: 10px;
  color: #949494;
  margin-top: 30px;
  font-weight: bold;
}

#margen2-login {
  margin-top: 30px;
}

.box-login-guest {
  display: block !important;
}

#margen3-login {
  margin-top: 20px;
}

#MargenPrron {
  margin-bottom: 500px;
  margin-top: 20px;
}

/* Página 21*/
#ColorBackgroundPage21 {
  background-color: #c0d1d5;
}

#Margin1Page21 {
  margin-bottom: 25%;
  margin-top: 50%;
  color: #64949a !important;
}

#GrayUrlOne {
  color: #adbfc5;
  margin-top: 9px;
}

#GrayUrlTwo {
  text-decoration: none;
  color: #adbfc5;
  margin-top: 1.1rem;
  text-align: right !important;
  margin-right: 1rem;
}

#DarkGrayTxt {
  font-weight: bold;
  color: #949494;
  text-decoration: underline;
}

#BlancoPrueba {
  background-color: white;
}

.edicion-text-box {
  display: block !important;
  border-width: 0 0 2px 0 !important;
  border-color: #adbfc5 !important;
}

.edicion-text-box:focus {
  border-color: inherit !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

#EdicionTextBox2 {
  display: block;
  border-width: 0 0 2px 0;
  border-color: #adbfc5;
  background-color: #c0d1d5;
}

#PaymentLabel {
  margin-top: 20px;
  color: #707070;
}

#AjusteTxtRegisInstrucYEstudi {
  margin-top: 35px;
}

#Prueba {
  border-radius: 50px;
  background-color: #c0d1d5;
  margin-top: 70px;
}

#Prueba2 {
  border-radius: 50px;
  background-color: #c0d1d5;
  margin-top: 15px;
  display: block;
  size: 20;
}

#BtnContinueSingIn {
  border-radius: 15px;
  padding-left: 40px;
  padding-right: 40px;
  color: #707070;
  font-weight: bold;
  background-color: #c0d1d5;
  display: block;
  margin-top: 50px;
  margin-left: 810px;
}

#TxtSingIn {
  margin-top: 40px;
  margin-bottom: 450px;
}

.box-section-welcome-and-section-signup {
  border: 1px solid green;
  border-radius: 1rem;
  padding: 2rem;
}

#MainGreenColorBackground {
  background-color: #8db2bc;
  /* border-radius: 35px 0px 0px 35px; */
}

#MainGreenColorBackgroundV2 {
  background-color: #adbfc5;
}

#MainWitheColorBackground {
  background-color: white;
  /* border-radius: 0px 35px 35px 0px; */
}

#AlinearAbajo {
  margin-top: 300px;
}

#BtnCreateAccount {
  /* margin-top: 15%;
     margin-left: 280px; */
  border-radius: 1.3698630137vh;
  padding-left: 40px;
  padding-right: 40px;
  color: #64949a;
  font-size: 1.8264840183vh;
  font-weight: bold;
  background-color: rgba(107, 140, 149, 0.4392156863);
  display: inline-block;
  opacity: 0.76;
}

#BtnRegistration {
  margin-top: 17%;
  margin-left: 280px;
  border-radius: 15px;
  padding-left: 40px;
  padding-right: 40px;
  color: #707070;
  font-weight: bold;
  background-color: #c0d1d5;
  display: block;
}

#TextGrayColor {
  color: #949494;
}

.GrayText {
  color: #adbfc5;
}

.flightTitle {
  font-size: 3.8vh !important;
  font-weight: 300;
}

.list-group-item {
  background-color: #F8F8F8 !important;
  border-color: #F8F8F8 !important;
}

.account-info-page-active {
  text-decoration: none !important;
  color: #64949a !important;
  opacity: 0.68 !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700 !important;
  /* font-size: 3.0441400304vh !important; */
}

@media (max-width: 1200px) {
  .account-info-page-active {
    font-size: unset !important;
  }
}
.account-info-page-gray-color {
  text-decoration: none !important;
  color: #a1a1a1 !important;
  opacity: 0.68 !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 400 !important;
  /* font-size: 3.0441400304vh !important; */
}

#LabelFinances {
  margin-top: px;
}

#TextBoxAjust {
  border-radius: 50px;
  background-color: #c0d1d5;
  margin-top: 15px;
  display: block;
}

#Prueba3 {
  border-radius: 50px;
  background-color: #c0d1d5;
  margin-left: 175px;
  position: relative;
  bottom: 45px;
  display: block;
}

#Prueba4 {
  border-radius: 50px;
  background-color: #c0d1d5;
  margin-left: 400px;
  display: block;
}

#PadingTop {
  background-color: white;
  padding-top: 48px;
  padding-right: 48px;
  padding-left: 48px;
  padding-bottom: 0px;
}

#WarningIcon {
  width: 24px;
  height: 24px;
}

#WarningTxtPaddingAndColor {
  /* margin-top: 10px; */
  font-family: Roboto, sans-serif !important;
  font-size: 16px !important;
  font-weight: 400;
  margin-top: 4.2617960426vh;
  color: #e9a535;
}

#PaddingLeftAndTopWhite {
  background-color: white;
  padding-top: 20px;
  padding-left: 10px;
}

#WhitePaddingCenter {
  background-color: white;
  padding-top: 0px;
  padding-right: 70px;
  padding-left: 70px;
  padding-bottom: 0px;
}

#WhitePaddingCenterMiniBottom {
  background-color: white;
  padding-top: 0px;
  padding-right: 70px;
  padding-left: 70px;
  padding-bottom: 20px;
}

#WhitePaddingCenter2 {
  background-color: white;
  padding-top: 40px;
  padding-right: 70px;
  padding-left: 70px;
  padding-bottom: 40px;
}

#WhitePaddingCenterBigBottom {
  background-color: white;
  padding-top: 0px;
  padding-right: 70px;
  padding-left: 70px;
  padding-bottom: 100px;
}

#WhitePaddingCenter3 {
  background-color: white;
  padding-top: 20px;
  padding-right: 70px;
  padding-left: 70px;
  padding-bottom: 0px;
}

#BtnMinMargin {
  margin-left: 10px;
  color: #adbfc5;
}

#PadingCenter {
  background-color: white;
  padding-top: 0px;
  padding-right: 48px;
  padding-left: 48px;
  padding-bottom: 0px;
}

#ButtonGreen1 {
  border-radius: 15px;
  padding-left: 30px;
  padding-right: 30px;
  color: #707070;
  font-weight: bold;
  background-color: #c0d1d5;
  display: block;
}

#ButtonGreen2 {
  border-radius: 15px;
  padding-left: 30px;
  padding-right: 30px;
  color: #707070;
  font-weight: bold;
  background-color: #c0d1d5;
  display: block;
}

#WhitePaddingCenterBigTop {
  background-color: white;
  padding-top: 50px;
  padding-right: 70px;
  padding-left: 70px;
  padding-bottom: 50px;
}

#PadingCenterMiniTop {
  background-color: white;
  padding-top: 20px;
  padding-right: 48px;
  padding-left: 48px;
  padding-bottom: 0px;
}

#SafetyBtnSubmit {
  border-radius: 0.9132420091vh;
  padding-left: 40px;
  padding-right: 40px;
  color: #64949a;
  font-family: Roboto, sans-serif !important;
  font-size: 1.8264840183vh;
  font-weight: 600 !important;
  background-color: rgba(107, 140, 149, 0.37);
  display: inline-block;
  margin-top: 0px;
  margin-right: 0px;
  margin-bottom: 0px;
}

#Padding4x4 {
  background-color: white;
  padding-top: 48px;
  padding-right: 48px;
  padding-left: 48px;
  padding-bottom: 48px;
}

.TextboxGeneral {
  border-radius: 8px !important;
  background-color: #c0d1d5 !important;
  color: #adbfc5 !important;
  border: none !important;
}

.TextboxGeneral:focus {
  color: #64949a !important;
}

.TextboxGeneral::placeholder {
  color: #adbfc5 !important;
}

#TextbRight {
  border-radius: 50px;
  background-color: #c0d1d5;
  align-self: flex-end;
}

#CompressedPadding {
  background-color: white;
  padding-top: 10px;
  padding-right: 110px;
  padding-left: 110px;
  padding-bottom: 10px;
}

#CompressedPadding2 {
  padding-top: 10px;
  padding-right: 110px;
  padding-left: 110px;
  padding-bottom: 50px;
} /*este id lo borre del archivo BasicInfoRgistration.js*/
#CompressedPadding2 h3 {
  padding-top: 5%;
}

#BtnFinances {
  border-radius: 15px;
  padding-left: 40px;
  padding-right: 40px;
  color: #707070;
  font-weight: bold;
  background-color: #c0d1d5;
  display: block;
}

#LabelFinances {
  display: block;
  margin-top: 70px;
  margin-left: 710px;
}

#BtnSubmit {
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  color: #707070;
  font-weight: bold;
  background-color: #c0d1d5;
  display: block;
}

#TxtSbmitDocuments {
  margin-top: 10px;
}

#IconPadding {
  padding-right: 20px;
}

#WhitePaddingCenter4 {
  background-color: white;
  padding-top: 40px;
  padding-right: 70px;
  padding-left: 70px;
  padding-bottom: 40px;
}

#WhitePaddingCenterBigTop2 {
  background-color: white;
  padding-top: 0px;
  padding-right: 70px;
  padding-left: 70px;
  padding-bottom: 50px;
}

.Verde {
  color: #64949a !important;
}

.PaddingLateral {
  padding-left: 12%;
  padding-right: 12%;
}

.Grismenosclaro {
  color: #adbfc5;
}

.Bold {
  font-weight: bold !important;
}

.Paddingtop15 {
  padding-top: 10%;
}

.Paddingtop5 {
  padding-top: 5%;
}

.Paddingtop2 {
  padding-top: 2%;
}

#MainDarkGreenColorBackground {
  background-color: #64949a;
  border-radius: 35px 35px 35px 35px;
}

#WelcomeMember {
  display: block;
  font-size: 3.8051750381vh !important;
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
  margin-left: auto;
  margin-right: auto;
  margin-top: 5%;
  color: #64949a !important;
}

#WelcomeMemberDesc {
  margin-bottom: 5%;
  color: white;
}

.Aviation {
  text-align: center;
  font: normal normal 900 60px/77px aktiv-grotesk;
  letter-spacing: 0px;
  color: #949494;
  text-transform: uppercase;
  opacity: 1;
}

.global-container {
  background-color: #ffffff;
}

.App {
  height: 100%;
  background-color: #ffffff;
}

a {
  text-decoration: none !important;
  padding: none !important;
  color: #858585;
  font-family: Roboto;
}

.titulosDePagina {
  padding-top: 6%;
  padding-bottom: 5%;
}

.box2 {
  width: 35%;
  height: fit-content;
  padding: 2%;
  background-color: #c0d1d5;
  border-radius: 10px 10px 10px 10px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  text-align: center;
}

.box3 {
  width: fit-content;
  height: fit-content;
  padding: 5%;
  background-color: #c0d1d5;
  border-radius: 20px 20px 20px 20px;
  position: relative;
  text-align: center;
}

.box4 {
  width: auto;
  height: fit-content;
  padding: 2%;
  background-color: #c0d1d5;
  border-radius: 20px 20px 20px 20px;
  position: relative;
  text-align: center;
}

.boxBlanco {
  width: 335px;
  height: 334px;
  padding: 2%;
  background-color: white;
  border-radius: 20px 20px 20px 20px;
  position: relative;
  text-align: center;
  left: 15%;
}

/*-------- Acordeon--------*/
#book-background {
  width: 80%;
  height: auto;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
}

.button:selected {
  border: none !important;
}

.accordion-header.accordionCourseItem {
  border-radius: 10px 10px 10px 10px;
  padding: unset;
}

.accordion-button:not(.collapsed)::after {
  background-image: url("./components/imagenes/pencil-icon.png") !important;
  transform: none !important;
  display: none;
  padding: unset;
}

.accordion-button::after {
  background-image: url("./components/imagenes/pencil-icon.png") !important;
  display: none;
  padding: unset;
}

.accordion-button {
  background-color: #f8f8f8 !important;
  color: #adbfc5 !important;
  min-height: 3rem;
  padding-right: 1rem;
  border-radius: 10px 10px 10px 10px;
  font-family: Roboto !important;
  font-size: 40% !important;
  padding-left: 2rem;
  text-align: center;
  text-transform: uppercase;
  margin-bottom: unset;
}

.accordion-button p {
  padding-left: 2rem;
  margin-bottom: unset;
}

.accordion-button:not(.collapsed) {
  color: #e9a535 !important;
  background-color: none !important;
  box-shadow: none !important;
}

.accordion-button:focus {
  border-color: none !important;
  box-shadow: none !important;
  margin-bottom: unset;
}

.accordion-item {
  border: 1px solid white !important;
}

.accordion-collapse.collapse.show {
  background-color: #f8f8f8;
}

/*------------Botones ------- */
.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.btn-primary {
  color: #858585 !important;
  background-color: #a1a1a1 !important;
  border-color: #c0d1d5 !important;
  font-family: Roboto;
}

.accordion-body .container .row .col {
  text-align: center;
}

.accordion-body .container .row .col .btn-primary {
  text-transform: uppercase;
  width: 60%;
  height: 120%;
  font-family: Roboto;
  border-radius: 20px 20px 20px 20px;
}

/*------------Boton de Ayuda --------- */
.circle-help {
  color: #64949a;
  margin-bottom: 2rem;
  /* height: 1.82648401826484vh; // 12px
   width: 1.82648401826484vh; // 12px */
  font-size: 1.4rem;
}

.helpImg {
  /* background-image: url("./components/imagenes/circle-question-regular.svg"); */
  background-size: contain;
  height: 1.8264840183vh;
  width: 1.8264840183vh;
  /* width: 2.2831050228310503vh;
   height: 2.2831050228310503vh; */
}

/*--------checkout----------------*/
.form-check-input:checked {
  background-color: #e9a535 !important;
  border-color: #e9a535 !important;
}

.form-check-input:focus {
  border-color: #e9a535 !important;
  box-shadow: 0 0 0 0.25rem rgba(253, 237, 13, 0.175) !important;
}

.indicacion {
  color: #adbfc5;
  font-size: 10px;
  text-align: left;
}

.box2 .indicacion {
  text-align: right !important;
}

.container .box2 {
  border-radius: 20px 20px 20px 20px;
}

.container .box2 .row .col {
  text-align: right;
  color: #adbfc5 !important;
  font-family: Roboto;
}

.container .box2 .row #buttonCheck {
  text-align: left !important;
}

.buttonCheck .col {
  text-align: left !important;
}

/*-------- BOOK CHECKOUT Activo--------*/
.bookCheckout .activo .container .row .col {
  background-color: #c0d1d5;
  padding: 1.2%;
  padding-left: 4%;
  color: #adbfc5;
  font-family: Roboto;
  text-transform: uppercase;
  border-radius: 20px 20px 20px 20px;
  font-size: 120%;
  text-decoration: none;
}

/*-------- BOOK CHECKOUT Activo--------*/
.bookCheckout .inactivo {
  padding-left: 1%;
  padding-right: 1%;
}

.bookCheckout .activo .container {
  padding-bottom: 3%;
}

.bookCheckout .inactivo .container .row .col {
  background-color: #f8f8f8;
  padding: 1.2%;
  padding-left: 4%;
  color: #adbfc5;
  font-family: Roboto;
  text-transform: uppercase;
  border-radius: 20px 20px 20px 20px;
  font-size: 120%;
  text-decoration: none;
}

.bookCheckout .inactivo .container {
  padding-bottom: 3%;
}

.bookCheckout {
  padding-left: 10%;
  padding-right: 10%;
}

/*-------- BOOK CHECKOUT Abierto-------*/
.bookTitle {
  color: #e9a535;
  font-family: Roboto;
  text-transform: uppercase;
  font-size: 120%;
}

.bookCheckout .abierto .container {
  background-color: #f8f8f8;
  padding: 1.2%;
  padding-left: 4%;
  color: #adbfc5;
  font-family: Roboto;
  text-transform: uppercase;
  border-radius: 20px 20px 20px 20px;
  font-size: 120%;
  text-decoration: none;
}

.bookCheckout .abierto {
  padding-bottom: 3% !important;
}

.box3 .indicacion {
  text-align: right !important;
}

.container .box3 {
  border-radius: 20px 20px 20px 20px;
}

.container .box3 .row .col {
  text-align: right;
  color: #adbfc5 !important;
  font-family: Roboto;
  display: inline-block;
}

.container .box3 .row #buttonCheck {
  text-align: left !important;
}

.bodyBook {
  padding-top: 3%;
}

/*----- CheckBox active---*/
.box3Active {
  width: fit-content;
  height: fit-content;
  padding: 2%;
  background-color: #e9a535;
  border-radius: 20px 20px 20px 20px;
  position: relative;
  text-align: center;
  color: #e9a535;
}

.container .box3Active {
  border-radius: 20px 20px 20px 20px;
}

.box3Active .indicacion {
  text-align: right !important;
}

.container .box3Active {
  border-radius: 20px 20px 20px 20px;
}

.container .box3Active .row .col {
  text-align: right;
  color: #e9a535 !important;
  font-family: Roboto;
  display: inline-block;
}

.container .box3Active .row #buttonCheck {
  text-align: left !important;
}

.indicacion2 {
  color: #e9a535;
  font-size: 10px;
  text-align: right !important;
}

.col-sm-auto {
  width: auto;
  height: 3% !important;
}

.point {
  color: #64949a !important;
  text-align: right;
  padding: -20%;
  display: flex;
}

.lttlprice {
  font-size: xx-small !important;
}

.izquierda {
  text-align: left;
}

.flechaSiguiente {
  font-size: 30px;
  text-align: right;
  padding-top: 15%;
}

.flechaSiguiente a {
  color: #adbfc5;
}

.line {
  border-left: solid #c0d1d5;
}

.espacio {
  padding: 2%;
}

.row h6 {
  padding-top: 5%;
}

.flechaSiguiente2 {
  font-size: 30px;
  text-align: right;
  padding-top: 5%;
}

.flechaSiguiente2 a {
  color: #adbfc5;
}

.box4 .indicacion {
  text-align: right !important;
}

.container .box4 {
  border-radius: 20px 20px 20px 20px;
}

.container .box4 .row .col {
  text-align: right;
  color: #adbfc5 !important;
  font-family: Roboto;
  display: inline-block;
}

.container .box4 .row #buttonCheck {
  text-align: left !important;
}

.overflow-auto .box4 .col .indicacion {
  text-align: left !important;
  display: inline;
}

.col-md-auto {
  flex: 0 0 auto;
  width: auto;
  display: inline-block;
}

.container .box4 .row .col .indicacion {
  text-align: left !important;
}

.bookPrice .container {
  background-color: #c0d1d5 !important;
}

.indicacion3 {
  font-size: x-small;
  display: inline-block;
  padding-right: 70%;
}

.indicacion4 {
  font-size: small;
  display: inline-block;
}

.izquierda {
  font-size: large;
  display: inline;
}

.boxH {
  height: 400px;
  overflow-y: auto;
  overflow-x: hidden;
}

.btn.btn-secondary {
  background-color: #c0d1d5;
  opacity: 64%;
  color: #64949a;
  text-align: center;
  border: none !important;
  height: 50px;
  border-radius: 10px 10px 10px 10px;
}

.buttonW {
  width: 600px;
  text-align: center;
}

.buttonW .container .row {
  padding-top: 4%;
}

.btn.btn-secondary:hover {
  background-color: #c0d1d5;
}

.buttonW .btn.btn-secondary:focus {
  background-color: #e9a535;
  color: #e9a535;
  border: none !important;
  box-shadow: none !important;
}

/*----------- PAYMENT --------------*/
.paymentBook .form-label {
  color: #64949a;
  font-size: small;
  font-family: Roboto;
  text-transform: capitalize;
}

.paymentBook .form-control {
  background-color: #f8f8f8 !important;
  font-size: small;
  font-family: Roboto;
  text-transform: capitalize;
  border: 1px solid #64949a;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  color: #64949a !important;
}

.paymentBook .form-control:focus {
  border: 1px solid #64949a;
  box-shadow: none !important;
}

.paymentBook .form-select {
  background-color: #f8f8f8 !important;
  font-size: small;
  font-family: Roboto;
  text-transform: capitalize;
  border: 1px solid #64949a;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  color: #64949a !important;
}

.paymentBook .form-select:focus {
  border: 1px solid #64949a;
  box-shadow: none !important;
}

.paymentBook h5 {
  padding-top: 5%;
}

/*----------book finish ---------*/
.Finish h5 {
  color: #858585 !important;
  text-transform: capitalize !important;
  padding-top: 10%;
  font-family: Roboto;
  text-align: left !important;
}

.Finish input {
  color: #858585 !important;
  text-transform: capitalize !important;
  padding-top: 10%;
  font-family: Roboto;
  padding: 5%;
  text-align: left !important;
}

.Finish li {
  color: #adbfc5 !important;
  text-transform: capitalize !important;
  padding-top: 10%;
  font-family: Roboto;
  padding: 5%;
  font-size: medium;
  text-align: left !important;
}

.Finish li::marker {
  color: #e9a535;
  font-weight: bolder;
  font-size: x-large;
}

.Finish {
  text-align: right;
  font-family: Roboto;
  font-size: small;
}

.precioF {
  text-align: center !important;
  color: #64949a;
  font-family: Roboto;
}

.btn-close {
  box-sizing: 40px;
}

.close {
  background-color: transparent;
  border: 2px solid #adbfc5;
  border-radius: 50px 50px 50px 50px;
  width: 15px;
  height: 15px;
  font-size: x-small;
  text-align: center !important;
}

.Finish b {
  color: #858585;
  text-transform: capitalize !important;
  font-family: Roboto;
  font-size: large;
}

.Finish .b2 {
  color: #858585;
  text-transform: capitalize !important;
  font-family: Roboto;
  font-size: larger;
  font-weight: bold;
}

.precioFF {
  text-align: center !important;
  color: #64949a;
  font-family: Roboto;
  font-size: x-large;
}

.Finish .btn-primary {
  width: 500px !important;
  background-color: #e9a535 !important;
  color: white !important;
}

.Finish .col {
  border-left: 0.5px solid #c0d1d5;
}

.termAndCon .form-check-label {
  font-size: xx-small !important;
}

.termAndCon {
  padding: 10%;
}

.alinear .col {
  text-align: center;
}

.alinear {
  padding-bottom: 5%;
  padding-top: 5%;
}

.alinear .btn {
  width: 200px;
  height: auto;
  border-radius: 20px 20px 20px 20px;
}

/*---------- My Orka------------*/
.myORka {
  padding-top: 3%;
  text-align: left !important;
}

.myORka .container {
  background-color: #f8f8f8;
  border-radius: 20px 20px 20px 20px;
  padding: 3%;
}

.myORka h5 {
  color: #64949a !important;
  font-family: Roboto;
  text-transform: uppercase;
  font-size: large !important;
  padding-top: 5%;
  text-align: left !important;
}

.currentClasses .container {
  padding: 5%;
  margin-top: 5%;
}

.currentClasses .Active .container {
  background-color: #c0d1d5 !important;
  width: 300px;
}

.currentClasses .Inactive .container {
  background-color: white !important;
  width: 300px;
}

.tituloBox {
  color: #64949a;
  font-family: Roboto;
  font-size: large;
}

.currentClasses .Inactive .tituloBox {
  color: #f8f8f8 !important;
}

.currentClasses .Active .circulo {
  background-color: #8db2bc;
  width: 40px;
  height: 40px;
  color: white;
  text-align: center !important;
  border-radius: 45px;
  padding: 10%;
  padding-top: 15%;
}

.currentClasses .Inactive .circulo {
  background-color: #f8f8f8;
  width: 40px;
  height: 40px;
  color: #c0d1d5;
  text-align: center !important;
  border-radius: 45px;
  padding: 10%;
  padding-top: 15%;
}

.myORka a {
  color: #adbfc5;
  text-decoration: underline !important;
}

.currentClasses .classesDesc {
  color: white !important;
  font-size: x-small !important;
}

.currentClasses .classesNum {
  color: white !important;
  font-size: medium !important;
  font-weight: bold;
}

.currentClasses .classesDesc2 {
  color: white !important;
  font-size: xx-small !important;
}

.currentClasses .Inactive .myOrkaDesc {
  color: white;
}

.myORka .padding {
  padding: -5%;
  margin-top: -3.5%;
}

.myORka .box .container {
  background-color: white;
}

.myORka .box .container .row {
  border-right: 1px solid #f8f8f8;
}

.myORka h6 {
  color: #64949a !important;
  font-size: x-large !important;
}

.myORka h2 {
  color: #c0d1d5 !important;
}

.myORka .subTittle {
  color: #64949a !important;
  font-size: medium !important;
}

.contInt .container {
  padding: 0% !important;
}

.myORka iframe {
  border-radius: 20px 20px 20px 20px;
  filter: sepia(100%) hue-rotate(100deg) saturate(40%);
}

.azul {
  color: #c0d1d5 !important;
}

.myORka .contDesc {
  font-size: xx-small !important;
}

.myORka .Hrs {
  background-color: #f8f8f8 !important;
  border-radius: 20px 20px 20px 20px;
  width: 80px;
  height: 80px;
  font-size: xx-large;
  color: #c0d1d5 !important;
  padding: 15%;
  text-align: center;
  font-family: Roboto;
}

.myORka .HrsDesc {
  color: #c0d1d5;
  font-size: xx-small !important;
  font-family: Roboto;
  text-align: center;
}

.myORka .Hrs2 {
  background-color: #f8f8f8 !important;
  border-radius: 20px 20px 20px 20px;
  width: 180px;
  height: 80px;
  font-size: xx-large;
  color: #c0d1d5 !important;
  padding: 7%;
  left: 300px;
  text-align: center;
  font-family: Roboto;
}

.upComing .container {
  background-color: #f8f8f8 !important;
  padding-bottom: 3% !important;
}

.upComing .day {
  color: #e9a535;
  font-size: x-large !important;
  text-align: center;
  width: 30px;
}

.upComing .month {
  color: #64949a;
  text-align: center;
  font-size: x-small !important;
}

/*------------- MY ORKA CALENDARIO --------------*/
.myORka .calendario .react-calendar {
  width: 350px;
  height: 230px;
  background-color: transparent;
  text-align: center;
  align-self: center;
  padding-left: 0% !important;
  padding-top: 0% !important;
}

.myORka .calendario .row .col {
  padding: 0% !important;
}

.myORka .calendario .react-calendar__month-view__weekdays {
  width: 280px;
}

.myORka .calendario .react-calendar__navigation__arrow {
  display: none !important;
}

.myORka .calendario .react-calendar__month-view__days {
  height: 190px;
  width: 280px;
}

.myORka .calendario .react-calendar__navigation {
  text-align: left !important;
}

.calendarInd {
  font-size: xx-small;
  color: #adbfc5;
  padding-bottom: 8% !important;
}

.cuadro1 {
  width: 10px;
  height: 10px;
  background-color: #858585;
}

.cuadro2 {
  width: 10px;
  height: 10px;
  background-color: #64949a;
}

.Hithere .container {
  background-color: #f8f8f8;
  border-radius: 20px 20px 20px 20px;
  padding: 2%;
  padding-left: 3.5%;
  padding-top: 0%;
}

.Hithere .row {
  padding: 0% !important;
  margin-top: 0% !important;
}

.Hithere .col {
  padding: 0 !important;
  margin-top: 0% !important;
}

.Containers2 .row {
  padding: 0;
}

.Hithere h6 {
  color: #64949a !important;
  font-size: large !important;
  font-weight: bold;
}

.Hithere h2 {
  color: #c0d1d5 !important;
  font-size: 48px !important;
  padding-top: 0%;
  top: -3%;
}

.Subti {
  color: #64949a !important;
  font-size: large !important;
  font-weight: bold;
}

.espacio {
  padding-top: 40px;
}

.Hithere .btn.btn-primary {
  background-color: #f8f8f8 !important;
  border-left: #f8f8f8 !important;
  border-right: #f8f8f8 !important;
  text-align: left !important;
  font-size: large !important;
  font-weight: bold;
  width: 500px;
}

.Hithere .btn:hover {
  color: #e9a535 !important;
}

.Hithere .btn:focus {
  color: #e9a535 !important;
}

.paddingTop {
  padding-top: 70px;
}

.center {
  text-align: center;
  align-self: center;
  align-items: center;
}

.amarillo {
  color: #e9a535;
}

.paddingRight {
  margin-right: 20px;
}

.help a {
  color: #858585;
}

.help a:hover {
  color: #e9a535;
}

.pantalla38 {
  padding-top: 15%;
  padding-left: 5%;
}

.videoUp .modal-content {
  width: 920px !important;
  margin: 0 !important;
  max-width: 920px;
}

.videoUp .modal-body {
  padding: 0% !important;
}

.modal-body .button {
  padding-bottom: 100px !important;
}

@media (min-width: 576px) {
  /* .modal-dialog {
    max-width: max-content !important;
    margin: 1.75rem auto;
  } */
  .forgotPass .modal-dialog {
    max-width: 500px !important;
    margin: 1.75rem auto;
  }
}
.modal-title h4 {
  color: #c0d1d5 !important;
}

.btn-close:hover {
  color: #e9a535 !important;
}

.btn-close {
  color: #e9a535 !important;
}

.btn-close::before {
  border: none !important;
}

.botonGrisAmarillo .btn:hover {
  background-color: #e9a535 !important;
}

.botonGrisAmarillo .btn {
  color: white !important;
  font-family: Roboto Bl;
  font-size: 1.8264840183vh;
  width: 150px;
  margin-bottom: 15%;
}

.botonGrisAmarilloV2 .btn {
  border-radius: 1.2176560122vh;
  color: white !important;
  font-family: Roboto, Bold;
  font-weight: bold;
  background: #a1a1a1 0% 0% no-repeat padding-box;
  font-size: 1.8264840183vh;
  width: 150px;
  margin-bottom: 15%;
}

.botonGrisAmarilloV2 .btn:hover {
  background-color: #e9a535 !important;
  background: #a1a1a1 0% 0% no-repeat padding-box;
}

.botonAzulAmarillo .btn {
  color: #8db2bc !important;
  font-family: Roboto;
  font-weight: bold;
  width: 120px;
  margin-bottom: 5%;
  margin-top: 2%;
}

.botonAzulAmarillo .btn:hover {
  background-color: #e9a535 !important;
  color: white !important;
}

.Ancho900 {
  width: 923px;
  height: 350px;
  padding-top: 3%;
}

.Alto {
  height: 330px;
}

#my-modal .modal-content {
  background-image: url("./components/imagenes/Register.JPG") !important;
  background-color: #000 !important;
  width: 400px;
  height: 400px;
  border-radius: 20px 20px 20px 20px;
}

.botonAmarillo .btn {
  background-color: #e9a535 !important;
  color: white !important;
  margin: 0% !important;
  font-family: Roboto;
  font-weight: bold;
  width: 150px;
}

.blanco {
  font-family: Roboto !important;
  font-weight: bold;
  color: white !important;
}

.Letrasblancas {
  color: white !important;
  padding: 2%;
  font-family: Roboto;
}

/* .modal-content {
  border-radius: 20px 20px 20px 20px;
} */

#redondo .modal-content {
  border-radius: 20px 20px 20px 20px;
}

.modal-title {
  color: #64949a !important;
}

.logo {
  font-family: reross-quadratic !important;
  color: #64949a;
  font-size: 5.3272450533vh;
}

.Login #img-login {
  display: inline-block;
}

.Login .container {
  /* margin-right: 0% !important;
   margin-bottom: 0% !important;
   margin-left: 2% !important; */
  max-width: unset !important;
  margin: 0 auto 0 0.9rem;
  height: 85vh;
}

.box-general-login .img-botones-2 {
  background-image: url(../public/imagenes/botones2.png);
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
}

.Login input {
  align-self: center;
}

.Login button {
  width: 300px;
}

.margintop15 {
  margin-top: 5%;
}

.margintop2 {
  margin-top: 2%;
}

.FondoGris body {
  background-color: #c0d1d5;
}

.FondoAllSet {
  background-color: #c0d1d5;
}

.paddingBottom body {
  padding-bottom: 10%;
}

#paddingLeft0 {
  padding-left: -10% !important;
  left: 50%;
  color: #e9a535 !important;
}

#ancho {
  width: 85%;
  padding-top: 0.5%;
}

.Fontsize40 {
  font-size: 40px !important;
}

.Width400 {
  width: 400px;
}

.HithereOptions .container {
  background-color: white !important;
  padding: 10%;
}

.HithereOptions h5 {
  color: #64949a !important;
  font-weight: bold;
  text-align: left !important;
  padding-bottom: -5% !important;
}

.HithereOptions button::before {
  border-radius: 20px 20px 0px 0px !important;
  background-color: #c0d1d5 !important;
  color: #64949a !important;
}

.HithereOptions .accordion-collapse.collapse.show {
  border-radius: 0px 0px 20px 20px !important;
  background-color: rgba(107, 140, 149, 0.2392156863) !important;
  opacity: 1;
  margin: unset;
}

.HithereOptions .accordion-header {
  border-radius: 20px 20px 20px 20px;
}

.HithereOptions .accordion-button:not(.collapsed) {
  color: #64949a !important;
  box-shadow: none !important;
  border-radius: 20px 20px 0px 0px !important;
  background-color: rgba(107, 140, 149, 0.2392156863) !important;
  opacity: 1;
}

.HithereOptions .accordion-button {
  border-radius: 20px 20px 20px 20px !important;
  background-color: #f8f8f8 !important;
  color: #adbfc5 !important;
  font-size: medium !important;
  font-family: Roboto !important;
  font-weight: bold;
}

.HithereOptions .infoCurso {
  font-family: Roboto;
  color: #64949a;
  font-size: small;
}

.Cursseinfo {
  color: #64949a !important;
  font-family: Roboto;
  font-weight: bold;
  text-decoration: underline;
  font-size: small;
}

.HithereOptions h4 {
  text-align: left;
  color: #c0d1d5 !important;
  padding-bottom: 6%;
  text-transform: uppercase;
  font-size: 25px !important;
}

.Cursos .accordion {
  padding-top: 0% !important;
}

.absolute {
  position: absolute;
}

.HithereOptions a {
  color: #c0d1d5;
  text-decoration: underline !important;
}

.HithereOptions a:hover {
  color: #c0d1d5;
}

.help {
  bottom: 1rem;
  position: relative;
  left: 3rem;
  bottom: 0.8rem;
}

.helpButton:hover {
  cursor: pointer;
}

.helpBox {
  padding: 18px 32px;
  background: #dae2e4;
  position: absolute;
  width: 320px;
  border: 1px solid #cbdee0;
  border-radius: 16px 16px 16px 0px;
  line-height: 1.5;
  bottom: 20px;
  left: 18px;
  margin-left: 160px;
  z-index: 10;
}

.titleHelpBox {
  color: #7b98a0 !important;
  font-family: Roboto Bl;
  font-weight: bold;
}

.closeButtonHelp {
  position: absolute;
  top: 5px;
  right: 0;
  width: 24px;
  cursor: pointer;
  color: #7b98a0;
}

.textHelpBox {
  color: #7b98a0;
  font-family: Roboto;
  font-size: small;
  margin-bottom: 0;
}

.linkHelpBox {
  color: #7b98a0;
  font-family: Roboto;
  font-size: small;
  font-weight: bolder;
}

.nav-user-icon {
  width: 24px !important;
  height: 24px !important;
  margin-top: -10px;
}

#root > div > div.d-flex.justify-content-center > div > div > div.col-sm-8 > div.row.BackgroundWhite.ps-4.py-4.Rounded4 > div.col-sm-5 > div.d-flex.justify-content-center > div > div > div > div:nth-child(2) > div > div > div:nth-child(1) {
  background-color: rgba(142, 178, 188, 0.2196078431) !important;
  border-radius: 12px !important;
  color: #8db2bc !important;
}

#root > div > div.d-flex.justify-content-center > div > div > div.col-sm-8 > div.row.BackgroundWhite.ps-4.py-4.Rounded4 > div.col-sm-5 > div.d-flex.justify-content-center > div > div > div > div:nth-child(2) > div > div > div:nth-child(1) > .rmdp-week-day {
  color: #64949a !important;
  font-weight: bold;
  opacity: 68% !important;
}

.rmdp-day.rmdp-selected span:not(.highlight) {
  background-color: rgba(107, 140, 149, 0.0784313725) !important;
  color: #64949a !important;
  font-size: 93.75% !important;
  font-weight: 800;
  border-radius: 37.5% !important;
  box-shadow: none !important;
  opacity: none !important;
}

.SelectedDay {
  background-color: rgba(107, 140, 149, 0.0784313725) !important;
  color: #64949a !important;
  font-size: 93.75% !important;
  font-weight: 800;
  border-radius: 37.5% !important;
  box-shadow: none !important;
  opacity: none !important;
}

.rmdp-day.rmdp-today span {
  background-color: transparent !important;
  color: #000 !important;
}
.desc-pcText {
  padding-right: 1vw;
  padding-left: 1vw;
}

.container-experiences-favorite-cources .container {
  margin: 0 auto;
  max-width: unset !important;
  width: 90%;
}

.legend-experiences-container {
  margin-top: 100px !important;
}

.slogan-container {
  margin-top: 50px;
  margin-bottom: 50px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

@media (max-width: 768px) {
  .slogan-container {
    margin-top: 75px;
    margin-bottom: 30px;
    align-items: flex-start;
    display: flex;
  }
}
@media (max-width: 576px) {
  .slogan-container {
    margin-top: 55px;
    margin-bottom: 30px;
    align-items: flex-start;
    display: flex;
  }
}

.slogan-texts {
  display: flex;
  flex-direction: column;
  z-index: 20;
  gap: 16px;
  margin-right: -350px;
  margin-left: 40px;
}

@media (max-width: 768px) {
  .slogan-texts {
    margin-right: -90%;
    margin-left: 10px;
  }
}
@media (max-width: 576px) {
  .slogan-texts {
    padding-top: 35px;
    margin-top: 35px;
    margin-right: -90%;
    margin-left: 10px;
  }
}

.slogan-title {
  font-size: 54px;
  width: 700px;
  max-width: 700px;
  line-height: 65px;
  font-family: Montserrat, sans-serif;
  font-weight: 900 !important;
  font-style: normal;
  color: #a1a1a1 !important;
}

@media (max-width: 768px) {
  .slogan-title {
    font-size: 36px;
    max-width: 500px;
    line-height: 40px;
  }
}
@media (max-width: 576px) {
  .slogan-title {
    font-size: 24px;
    max-width: 300px;
    line-height: 28px;
    /* color: #F8F8F8 !important; */
  }
}
.slogan-desc {
  max-width: 340px;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 400 !important;
  font-style: normal;
  color: #a1a1a1 !important;
}

@media (max-width: 768px) {
  .slogan-desc {
    max-width: 280px;
    font-size: 13px;
  }
}
.slogan-link {
  text-transform: uppercase;
  font-weight: bold;
  color: #e9a535;
}

@media (max-width: 768px) {
  .slogan-link {
    margin-left: 20vw;
  }
}
.slogan-link:hover {
  color: #e9a535;
}

.image-slogan-container {
  height: 100%;
  flex-basis: content;
}

.image-slogan {
  width: 100%;
  height: 100%;
  border-radius: 16px 0px;
  float: right;
}

@media (max-width: 768px) {
  .image-slogan {
    width: 85%;
  }
}

.home-texts {
  font-size: 16px;
  text-align: center;
  font-family: Roboto, sans-serif;
  font-weight: 400 !important;
  font-style: normal;
}

@media (max-width: 768px) {
  .home-texts {
    font-size: 13px;
  }
}

.home-titles {
  font-size: 46px !important;
  font-family: Montserrat, sans-serif;
  font-weight: 900 !important;
  text-align: center;
  font-style: normal;
}

@media (max-width: 768px) {
  .home-titles {
    font-size: 32px !important;
  }
}
@media (max-width: 768px) {
  .home-titles {
    font-size: 24px !important;
  }
}
@media (max-width: 576px) {
  .experiencesText {
    font-size: 0.8rem !important;
  }
  .orka-ubication {
    display: none;
  }
  .formGet {
    padding-top: 10px;
    padding-left: 0;
    padding-right: 0;
  }
  html,
  body {
    max-width: 100% !important;
    overflow-x: hidden !important;
  }
  .Aviation {
    text-align: center;
    letter-spacing: 0px;
    color: #949494;
    text-transform: uppercase;
    opacity: 1;
  }
  #right {
    padding: 0;
    height: 70vh;
    margin-top: 6vh;
    flex: 100% 1;
    align-self: normal;
  }
  .mobileAviation {
    font-size: 2vh !important;
    line-height: 2;
  }
  .Width35p {
    width: 100% !important;
    max-width: none !important;
  }
  .FontSize20px {
    font-size: 15px !important;
  }
  .planeTextMobile {
    line-height: normal;
    width: 100% !important;
    font-size: 0.8rem !important;
  }
  .desc-mobileText {
    display: block;
    font-size: 3.5vw;
  }
  .homedesc .desc {
    margin-top: 0%;
    margin-left: 0%;
    margin-right: 0%;
    padding: 0vh 3vw;
  }
  .bolitas {
    line-height: 0px;
    margin-top: 0vw;
  }
  .expTitle {
    letter-spacing: 0px;
    margin-top: 20vw;
    font-size: 7vw !important;
    /* margin: 0; */
  }
  .getInTouch {
    display: block;
  }
}
.title-course {
  font-size: 28px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-align: center;
}

.courses-desc {
  width: 100%;
  font-size: 16px;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 9;
  -webkit-box-orient: vertical;
  text-align: left;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
}

/**/
#margen1-login > div {
  /* margin: 0 auto; */
  margin: 0 auto 0 0;
  width: 100%;
}

#margen1-login > div:nth-child(2) {
  margin-bottom: 0;
}

#margen1-login #margen3-login button {
  background-color: #48676f;
  opacity: 0.5;
  font-family: Roboto, Bold;
  margin: 0 auto;
}

#GrayUrlTwo {
  text-align: center;
}

@media (max-width: 576px) {
  .navbar-brand {
    margin-left: 10px;
  }
  #content {
    height: 90vh !important;
    width: 100vw;
  }
  #left {
    flex: 13%;
  }
  #right {
    padding: 0;
    height: 70vh;
    flex: 100%;
    align-self: flex-end;
  }
  #right img {
    object-fit: cover;
  }
  .Cursos {
    padding-top: 80px;
  }
  .imgcab {
    height: 80vh;
  }
  .homedesc {
    top: 10vh;
    right: 2.5rem;
    bottom: 10vh;
    left: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
  }
  .desc {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }
  .desc h1 {
    font-size: 1.4rem !important;
    letter-spacing: 1px;
    margin-bottom: 0;
  }
  .desc-pcText {
    display: none;
  }
  .desc p {
    font-size: 0.8rem;
    padding: 0;
    margin-bottom: 0;
  }
  .legend-experiences-container {
    padding: 0 10%;
  }
  .legend-experiences p {
    font-size: 0.7rem;
    letter-spacing: 1px;
    line-height: 0.9rem;
    margin: 0;
  }
  .legend-experiences-span {
    font-size: 0.7rem;
    font-weight: 300;
    letter-spacing: normal;
    line-height: 0.9rem;
    margin: 0;
    color: #e9a535;
  }
  .box-experiences-mobil {
    border: 1px solid rgba(71, 71, 71, 0.5882352941);
    height: fit-content;
    padding: 10px 0;
    margin: 30px 0;
  }
  .subtitle-experience-mobil {
    font-weight: 200;
    font-size: 12px;
    line-height: 12px;
  }
  .box-experience-mobil {
    justify-content: space-evenly;
  }
  .box-experience-mobil_p {
    margin: 0;
    font-weight: normal;
    font-size: 15px;
  }
  .cadaCurso {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }

  .text-cadaCurso {
    text-align: center;
    padding: 0;
  }
  .flightTitle {
    font-size: 18px !important;
    letter-spacing: 1.5px;
    margin-bottom: 20px;
  }
  .text-cadaCurso h2 {
    font-size: 40px !important;
  }
  .text-cadaCurso h5 {
    font-size: 15px !important;
    margin-bottom: 25px;
  }
  .text-cadaCurso h4 {
    font-size: 16px !important;
    letter-spacing: 0.7px;
  }
  .btn.btn-secondary {
    display: none;
  }
  .btn.btn-secondary.mobile {
    display: block;
    color: #64949a;
    background: #adbfc5;
  }
  .mobile-CadaCurso_Btn {
    font-size: 12px;
    letter-spacing: 1px;
    font-weight: bold;
    color: #e9a535;
  }
  .descInfo {
    color: #949494;
    width: 65%;
    padding: 0;
  }
  .descInfo li {
    font-size: 13px;
    padding-bottom: 5px;
  }

  #message {
    height: 15vh;
  }
  .btn.btn-warning {
    border-radius: 1rem;
  }
  .formulario {
    flex-direction: column-reverse;
    margin: 0;
  }
  .formulario form {
    width: 100%;
    margin-bottom: 40px;
  }
  .orka-ubication {
    height: 40vh;
    padding: 0;
    width: 100%;
  }
  .orka-ubication_titlePc {
    display: none;
  }
  .orka-ubication_title {
    display: block;
  }
  .text-cadaCurso {
    text-align: center;
    padding: 0;
  }
}
@media (min-width: 768px) {
  .cadaCursoMobile {
    display: none;
  }
}
@media (max-width: 768px) {
  #left h1 {
    font-size: 1.3rem !important;
  }
  #left p {
    font-size: 0.8rem;
  }
}
@media (max-width: 768px) {
  .desc {
    height: auto;
  }
}
@media (max-width: 768px) {
  .homedesc p {
    font-size: unset;
  }
}
@media (max-width: 768px) {
  .homedesc .desc h1 {
    font-size: 1.6rem !important;
    margin-bottom: 1rem;
  }
}
@media (max-width: 768px) {
  .legend-experiences {
    margin-bottom: 2rem;
  }
}
@media (max-width: 768px) {
  .box-experiences-pc {
    display: none;
  }
  .box-experiences-mobil {
    display: block;
  }
  .box-experiences-mobil span {
    font-weight: 100;
    font-size: 0.8rem;
  }
  .box-experiences-mobil > * {
    column-gap: 1rem;
  }
}


@media (max-width: 768px) {
  .getInTouch h1 {
    font-size: 1.6rem !important;
  }
}
@media (max-width: 768px) {
  .formulario {
    background-color: #fff;
  }
}
/* @media (max-width: 768px) {
  .formulario input,
  .formulario textarea {
    background-color: #f8f8f8;
  }
} */
/*media queries para seccion de footer*/
@media (max-width: 768px) {
  .PieOrka .iconos {
    display: flex;
    /* justify-content: space-evenly; */
  }
}
/*media queries para la pagina de login*/
@media (max-width: 768px) {
  #BotonRegistroInstructor,
  #BotonRegistroEstudiantes {
    background-color: #949494 !important;
    border-radius: 1.9786910198vh;
    color: #64949a !important; /*agregado*/
  }
}
@media (max-width: 768px) {
  .box-general-login #margen1-login div:nth-child(4) {
    /* margin-left: 6.7rem; */
    width: 100% !important;
  }
  .box-general-login .box-login-guest .box-paragraph-box-login-guest p::before,
  .box-general-login .box-login-guest .box-paragraph-box-login-guest p::after {
    top: 1.5rem;
    width: 85%;
  }
  .box-general-login .box-login-guest .box-paragraph-box-login-guest p::before {
    right: 110%;
  }
  .box-general-login .box-login-guest .box-paragraph-box-login-guest p::after {
    left: 110%;
  }
}
@media (max-width: 576px) {
  .Login #img-login {
    display: none;
  }
}
.box-signup-pc .container-fluid {
  width: 90% !important;
}

@media (max-width: 768px) {
  .box-signup-pc {
    display: none;
  }
  .box-signup-mobil {
    display: block;
  }
  .section-signup-mobil a {
    display: inline-block;
    width: 80%;
  }
  .section-signup-mobil a button {
    width: 100%;
  }
}
/*media queries para la pagina de login como estudiante */
@media (max-width: 768px) {
  .box-general-form-student .container {
    padding-right: 0;
  }
  .box-general-form-student,
  .box-form,
  .box-general-form-address-emergency-contact,
  .box-general-form-create-account,
  .box-general-form-review-your-info {
    background-color: #adbfc5 !important;
  }
  .box-form {
    margin-top: 1.5rem !important;
  }
  .box-balls-pc {
    display: none;
  }
}
@media (max-width: 768px) {
  .box-arrow-back {
    position: absolute;
    left: 0rem;
  }
}
@media (max-width: 768px) {
  .box-arrow-back-box-balls-mobil {
    display: block;
    margin-top: 4rem;
  }
  .box-arrow-back-box-balls-mobil .box-balls {
    padding-left: 1rem;
  }
}
@media (max-width: 768px) {
  .box-form .arrow-pc {
    display: none;
  }
}
@media (max-width: 768px) {
  .box-general-form-student .header-profile,
  .box-general-form-student .paragraph-profile,
  .box-general-form-address-emergency-contact .header-profile,
  .box-general-form-address-emergency-contact .paragraph-profile,
  .box-general-form-create-account .header-profile,
  .box-general-form-create-account .paragraph-profile,
  .box-general-form-review-your-info .header-profile,
  .box-general-form-review-your-info .paragraph-profile {
    color: #64949a !important;
    text-align: left;
  }
}
/* media queries para el formulario*/
@media (max-width: 768px) {
  .field {
    width: 100%;
  }
  .field input[type=tel] {
    color: #fff !important;
  }
  .TextboxGeneral {
    background-color: transparent !important;
    border-radius: unset !important;
    border: none !important;
    border-bottom: 1px solid #949494 !important;
    color: #fff !important;
  }
  .container-inputs-review-personal-info,
  .container-inputs-review-account-info,
  .container-inputs-review-emergency-contact {
    width: unset;
    margin: unset;
  }
  .container-inputs,
  .container-inputs-review-personal-info,
  .container-inputs-review-account-info,
  .container-inputs-review-emergency-contact {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 768px) {
  .container-inputs .field:last-of-type {
    grid-column: unset;
  }
}
@media (max-width: 768px) {
  .container-inputs .field button {
    background-color: #adbfc5 !important;
    border: none;
    margin-top: 1rem;
    padding: 0 0 0 3.75rem !important;
    text-align: right;
    width: 100%;
  }
  .BackgroundWhite {
    background-color: transparent;
  }
}
/*media querie para button de la pagina
de review you info
*/
@media (max-width: 768px) {
  .box-general-form-review-your-info button {
    background-color: transparent !important;
    border: none;
    padding: 0 0 0 3.75rem !important;
    text-align: right;
    width: 100%;
  }
}
/* ------- media queries para la pagina principal de /book ------*/
@media (max-width: 768px) {
  .container-book-title,
  .container-book-checkout,
  .container-book-25 {
    margin-top: 2rem;
    padding-left: 10% !important;
    padding-right: 10% !important;
  }
}
@media (max-width: 768px) {
  .container-book-title .book-title,
  .container-book-checkout .book-title,
  .container-book-25 .book-25-title {
    font-size: 1.8rem !important;
  }
}
/*mediaqueries para la seccion de select a service*/
@media (max-width: 768px) {
  .bookCheckout .abierto .container {
    background-color: transparent;
  }
}
@media (max-width: 768px) {
  .bookCheckout .abierto .bookTitle {
    font-size: unset;
  }
}
@media (max-width: 768px) {
  .bookCheckout .abierto .alinear {
    margin-top: 1.2rem;
  }
}
@media (max-width: 768px) {
  .bookCheckout .abierto .button {
    margin-bottom: 1rem;
    width: 100% !important;
  }
}
@media (max-width: 768px) {
  .bookCheckout .inactivo {
    display: none;
  }
}
/**/
@media (max-width: 768px) {
  .box-help-book .helpBox {
    margin-left: -0.5rem !important;
  }
}
@media (max-width: 768px) {
  .box-help-book .help {
    left: 1rem;
    bottom: 2rem;
  }
}
@media (max-width: 768px) {
  .footer-book {
    margin-top: 20rem;
    padding-bottom: 0;
    padding-top: 0;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .footer-book .footerOrka {
    display: none;
  }
}
/*estilos de la pagina courses*/
@media (max-width: 768px) {
  .container-book-title .arrow-pc {
    display: inline-block;
    margin-left: -2rem;
  }
}
@media (max-width: 768px) {
  .bookNormal .bodyBook .row-col-item {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .bookNormal .bodyBook .col-item,
  .bookCheckout .book-checkout .col-item {
    padding-left: 0;
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 768px) {
  .bodyBook .col-item .box-check-col-item {
    justify-content: space-between;
    /* text-align: right; */
  }
  .bodyBook .col-item .box-check-col-item div:last-of-type {
    font-size: 1.1rem;
  }
  .col-item .box-check-col-item .check-col-item {
    font-size: 1.4rem !important;
  }
}
/*media queries para la pagina de book-checkout*/
@media (max-width: 768px) {
  .container-book-checkout .arrow-pc {
    display: inline-block;
    margin-left: -2rem;
  }
}
@media (max-width: 768px) {
  .box2 {
    padding: 5%;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .book-checkout .row-col-item {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .container-book-25 .arrow-pc {
    display: inline-block;
    margin-left: -2rem;
  }
}
/*madia queries para la pagina de book-25*/
@media (max-width: 768px) {
  .box3 {
    padding: 5%;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .Book-25-general .bodyBook .row-col-item {
    flex-direction: column;
  }
}
@media (max-width: 768px) {
  .Book-25-general .bodyBook .col-item {
    padding-left: 0;
    margin-bottom: 0.5rem;
  }
}
@media (max-width: 768px) {
  .Book-25-general .bodyBook .box3Active,
  .Book-25-general .bodyBook .box3 {
    padding: 5%;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .line {
    border-left: none;
  }
}
@media (max-width: 768px) {
  .bookCheckout .abierto .container {
    padding-left: 0;
  }
}
@media (max-width: 768px) {
  .Book-25-general .row-price-course {
    padding: 5%;
  }
}
@media (max-width: 768px) {
  .Book-25-general .row-price-course > * {
    flex: 0 0 30%;
  }
}
@media (max-width: 768px) {
  .Book-25-general .row-price-course .form-check-inline {
    font-size: 1.1rem;
  }
}
@media (max-width: 768px) {
  .Book-25-general .row-price-course p {
    font-size: 1.1rem;
    margin-right: 0.5rem;
  }
}
@media (max-width: 768px) {
  .Book-25-general.arrow-pc {
    display: none;
  }
}
@media (max-width: 768px) {
  .Book-25-general.arrow-mobil {
    display: block;
  }
}
@media (max-width: 768px) {
  .Book-25-general.arrow-mobil a {
    color: #64949a;
    font-size: 1.2rem;
  }
}
@media (max-width: 768px) {
  .box-general-form-student .boxHelpSignInOne .helpBox {
    margin-left: -0.5rem;
    padding: 1.12rem;
    margin-bottom: 0.5rem;
  }
}
/* estilos modal y media queries*/
.modal-upload-files-dialog.modal-dialog {
  margin: 0 auto;
  width: 90% !important;
}

.modal-upload-files-dialog-instructor.modal-dialog {
  margin: 0 auto;
  width: 90% !important;
}

.modal-upload-files.modal-content {
  width: 100vw !important;
}

.modal-upload-images-dialog.modal-dialog {
  margin: 0 auto;
  width: 90% !important;
}

.modal-upload-images-dialog-instructor.modal-dialog {
  margin: 0 auto;
  width: 90% !important;
}

.modal-body .center h4 {
  font-family: Roboto, sans-serif !important;
  font-weight: 700 !important;
  font-size: 3.8051750381vh !important;
}

.modal-body .center > * {
  margin: 0 auto 0.5rem auto;
  width: 80%;
}

.modal-body .center .thanks {
  padding-top: 0;
}

.modal-body .center > *:nth-child(2) {
  font-family: Roboto, sans-serif !important;
  margin-top: -0.5rem;
  margin-bottom: 1.1rem;
  width: 56%;
}

.recovery-modal .modal-body {
  padding: 0 1rem 1rem 1rem;
}

.recovery-modal .modal-body .center > * {
  margin: 0 auto 2.7rem auto;
  width: 80%;
}

/*estilos al texto de thanks*/
.recovery-modal .modal-body .center > *:nth-child(1) {
  color: #8db2bc !important;
  font-family: Roboto, sans-serif !important;
  font-weight: 700 !important;
  font-style: normal;
}

.recovery-modal .modal-body .modal-text-modal {
  display: none;
}

/*estilos a la imagen*/
.recovery-modal .modal-body .center > *:nth-child(2) {
  margin-top: -0.5rem;
  margin-bottom: 1.1rem;
  width: 28%;
}

.recovery-modal .box-general-text-recovery-modal {
  margin: 0 auto 3rem auto;
  width: 40%;
}

/*media query para hacer mas ancha la caja del texto del modal*/
@media (min-width: 929px) {
  .recovery-modal .modal-body .modal-text-modal {
    display: inline-block;
  }
  .recovery-modal .box-general-text-recovery-modal {
    margin: 0 auto 3rem auto;
    width: 55% !important;
  }
  .recovery-modal .modal-content {
    height: 49vh !important;
  }
  .recovery-modal .modal-dialog {
    width: 43% !important;
  }
}
@media (min-width: 992px) {
  .recovery-modal .modal-content {
    height: 48vh !important;
  }
  .recovery-modal .modal-dialog {
    width: 41% !important;
  }
}
@media (min-width: 1200px) {
  .recovery-modal .modal-content {
    height: 48vh !important;
  }
  .recovery-modal .modal-dialog {
    width: 28% !important;
  }
}
@media (min-width: 1400px) {
  .recovery-modal .modal-content {
    height: 49vh !important;
  }
  .recovery-modal .modal-dialog {
    width: 28% !important;
  }
}
.recovery-modal .box-general-text-recovery-modal .box-text-recovery-modal {
  color: #949494;
  font-family: Roboto, Regular;
}

/* .recovery-modal .modal-body .box-text-recovery-modal{
    width: 40%;
} */
/* NO SE APLICAN ESTOS ESTILOS*/
/* .modal-login-forgot-pass .field-modal-login{
    display: block;
    border: 1px solid #ced4da;
    border-radius: 13px!important; // 13px
    font-size:1.82648401826484vh; // 12px
    padding: 0.375rem 0.375rem;
    width: 100%;
} */
/* .modal-login-forgot-pass input[type='email']{
    font-size: 12px!important;
} */
.modal-body .field-modal-login::placeholder {
  text-align: center;
}

.modal-body .field-modal-login::-webkit-input-placeholder {
  text-align: center;
}

.modal-body .field-modal-login:-moz-placeholder {
  /* Firefox 18- */
  text-align: center;
}

.modal-body .field-modal-login::-moz-placeholder {
  /* Firefox 19+ */
  text-align: center;
}

.modal-body .field-modal-login:-ms-input-placeholder {
  text-align: center;
}

.modal-body .center .modal-text-mobil {
  display: none;
}

.modal-body .center > *:nth-child(3) {
  margin-bottom: 1rem;
}

.modal-body .center .modal-text-secondary-mobil {
  display: none;
}

@media (max-width: 768px) {
  /* .modal-body .center > *:nth-child(2){
        display: none;
    } */
  /*estilo para ocultar el texto de modo pc*/
  .modal-body .center .modal-text-pc {
    display: none;
  }
  /*mostrar el texto secundario el que dice(Need help for your password?)*/
  .modal-body .center .modal-text-secondary-mobil {
    display: inline-block;
    color: #949494;
    font-size: 12px;
    margin-top: 0.8rem;
    margin-bottom: 1.5rem;
  }
  .modal-body .center .box-modal-text-mobil {
    width: 90%;
  }
  .modal-body .center .modal-text-mobil {
    color: #949494;
    display: inline-block;
    font-size: 12px;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }
  /* .modal-body .center .btn-account-recovery-next{
        border-radius: 8px;
    } */
  /* .modal-content {
    width: 100vw !important;
  } */
  .modal-body .center h4 {
    font-size: 2.1rem !important;
  }
  .modal-body .center > * {
    width: 80%;
  }
}
.boxHelpLogin {
  color: #64949a;
  margin-top: -5vh;
  margin-left: -1rem;
  font-size: 1.8264840183vh;
}

@media (max-width: 768px) {
  .boxHelpLogin {
    font-size: 1.8264840183vh;
  }
}
/*media queries ajustes*/
@media (max-width: 768px) {
  .boxHelpLogin {
    margin-top: 1rem;
    margin-left: unset;
  }
  .box-general-login .boxHelpLogin .help {
    left: 2rem;
    bottom: 2rem;
  }
  .box-general-login .boxHelpLogin .helpBox {
    margin-left: -0.5rem;
    margin-bottom: 0.5rem;
  }
  .box-general-login .box-login-guest .box-paragraph-box-login-guest {
    margin-bottom: 0.8rem;
  }
  /*estilos a logo de orka*/
  .box-top-general-login .logo {
    font-size: 3.8051750381vh;
  }
  /*ajuste de pagina de signup*/
  .box-general-signup .box-signup-mobil#MainGreenColorBackground {
    background-color: #adbfc5;
  }
  .box-signup-mobil .helpBox {
    margin-left: -0.5rem;
    margin-bottom: 0.5rem;
  }
  .box-signup-mobil .boxGeneralHelpMobil .helpBox .textHelpBox {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .section-welcome-mobil {
    padding-top: 4.5rem;
    text-align: left;
  }
  .section-welcome-mobil h3 {
    padding-left: 10%;
    font-size: 3.8051750381vh !important;
  }
  /*ajuste a link de log en el header*/
  #Linkstaticon {
    padding-right: 0;
    font-size: 1.8264840183vh;
  }
  .boxGeneralHelpMobil .help {
    left: 2rem;
    bottom: -12rem;
  }
  .box-general-form-student .header-profile {
    font-size: 3.8051750381vh !important;
  }
  .box-general-form-student .box-arrow-back-box-balls-mobil #MainGreenColorBackground {
    background-color: #adbfc5;
  }
  /*ajuste a la posicion de la opcion help*/
  .box-general-form-student .help {
    left: 2rem;
    margin-top: -0.5rem;
  }
  .box-student-profile .boxMust {
    margin-top: 1rem !important;
    margin-left: unset;
    padding-bottom: unset;
    width: 100%;
    color: white;
  }
  .icon-arrow {
    margin-left: 8px;
    display: inline;
  }
  /*ajustes de la pagina EmergencyContactStudent*/
  .box-general-form-address-emergency-contact .box-arrow-back-box-balls-mobil #MainGreenColorBackground {
    background-color: #adbfc5;
  }
  .box-general-form-address-emergency-contact .header-profile {
    font-size: 3.8051750381vh !important;
  }
  .boxHelpSignInTwo .help {
    left: 2rem;
  }
  .box-general-form-address-emergency-contact .boxHelpSignInTwo .helpBox {
    margin-left: -0.5rem;
    margin-bottom: 0.5rem;
  }
  .box-emergency-contact .boxMust {
    margin-top: 6rem !important;
    margin-left: unset;
    padding-bottom: unset;
    width: 100%;
  }
  .wrapper-container-inputs-emergency-contact-one {
    border-bottom: none;
    padding-bottom: unset;
    margin: none;
    width: unset;
  }
  .wrapper-container-inputs-emergency-contact-two {
    margin: none;
    width: unset;
  }
  /*ajustes a pagina de create account*/
  .box-general-form-create-account .header-profile {
    font-size: 3.8051750381vh !important;
  }
  .box-general-form-create-account .box-arrow-back-box-balls-mobil #MainGreenColorBackground {
    background-color: #adbfc5;
  }
  .box-sign-create-account,
  .box-create-account {
    height: unset;
  }
  .box-general-form-create-account .header-profile,
  .box-general-form-create-account .paragraph-profile {
    text-align: left;
  }
  .box-create-account .container-inputs .field:nth-child(3) {
    grid-row: unset;
    grid-column: unset;
  }
  .wrapper-container-inputs-create-account {
    margin: unset;
    width: unset;
  }
  .box-create-account .field-button-create-account {
    margin-top: unset;
  }
  .box-create-account .boxMust {
    margin-top: 10rem !important;
    margin-bottom: 1.8rem;
    margin-left: unset;
    width: 100%;
  }
  .box-general-form-create-account .paddingBottom390 {
    padding-bottom: 2rem;
  }
  .box-general-form-create-account .padding-help-create-account {
    padding-bottom: 2rem;
  }
  .boxHelpSignInThree .help {
    left: 2rem;
    bottom: 1rem;
  }
  .box-general-form-create-account .boxHelpSignInThree .helpBox {
    margin-left: -0.5rem;
    margin-bottom: 0.5rem;
  }
  /*ajustes a pagina de reviewInfoStudent que esta dentro de createAccountStudent*/
  .box-general-form-review-your-info .header-profile-title {
    color: #64949a !important;
    font-size: 3.8051750381vh !important;
    text-align: left;
  }
  .box-general-form-review-your-info .header-profile {
    margin-left: unset;
    margin-bottom: unset;
  }
  .box-general-form-review-your-info .pencil-icon {
    display: none;
  }
  .box-general-form-review-your-info .box-arrow-back-box-balls-mobil #MainGreenColorBackground {
    background-color: #adbfc5;
  }
  .box-general-form-review-your-info .container-inputs-review-account-info #password {
    color: #fff !important;
  }
  .box-general-form-review-your-info .container-inputs-review-account-info {
    display: unset;
  }
  .box-general-form-review-your-info .container-inputs-review-account-info .field:last-of-type .eyePassword {
    color: #64949a;
  }
  .boxHelpSignInFour .help {
    left: 2rem;
    bottom: -1rem;
  }
  .box-general-form-review-your-info .boxHelpSignInFour .helpBox {
    margin-left: -0.5rem;
    margin-bottom: 0.5rem;
  }
  /*ajustes a pagina de login*/
  .Login .container {
    margin: unset !important;
  }
  .box-top-general-login .link-login {
    display: none;
  }
  .logo {
    font-size: 5.3272450533vh;
  }
  .Login-title {
    text-align: center;
  }
  .box-general-login input[type=email] {
    font-size: 16px;
    margin-left: unset;
  }
  .box-general-login input[type=password] {
    font-size: 16px;
    margin-left: unset;
  }
  .box-general-login .box-login-guest .box-paragraph-box-login-guest .paragraph-login-guest-lines {
    display: none;
  }
  .box-general-login .box-login-guest .box-paragraph-box-login-guest .span-login-guest-lines {
    display: inline-block;
  }
  .box-general-login .box-login-guest .box-paragraph-box-login-guest .span-login-guest-lines {
    position: relative;
  }
  .box-general-login .box-login-guest .box-paragraph-box-login-guest .span-login-guest-lines::before {
    right: 210%;
  }
  .box-general-login .box-login-guest .box-paragraph-box-login-guest .span-login-guest-lines::after {
    left: 210%;
  }
  .box-general-login .box-login-guest .box-paragraph-box-login-guest .span-login-guest-lines::before,
  .box-general-login .box-login-guest .box-paragraph-box-login-guest .span-login-guest-lines::after {
    content: "";
    position: absolute;
    top: 0.8rem;
    height: 2px;
    background: #c0d1d5;
    width: 700%;
  }
  .textForgotPassword {
    font-size: 16px !important;
  }
  #TextGrayColor {
    font-size: 16px !important;
  }
  .box-general-login .form-logIn,
  .modal-login-forgot-pass .form-logIn {
    font-size: 1.8264840183vh;
  }
  /*ajustes a la pagina de EmergencyContactStudent*/
  .box-general-form-address-emergency-contact .help {
    bottom: 0rem;
  }
}

/* 
  estilos para posicionar el cuadro de help con respecto a la altura
  de la pantalla
  */
.box-general-login .boxHelpLogin .helpBox {
  margin-left: -1rem !important;
}

.box-top-top-general-login {
 background-color: #ffffff;
}

@media (min-height: 768px) {
  .box-top-top-general-login .my-orka-nav-bar {
    padding-top: 0rem !important;
  }
  /* 
  estilos para posicionar el cuadro de help con respecto a la altura
  de la pantalla
  */
  .box-general-login .boxHelpLogin .helpBox {
    margin-left: 7rem;
  }
}
@media (max-width: 1200px) {
  .account-info-page-gray-color {
    font-size: 1.6877637131vh !important;
  }
}
/*ajuste al width del modal especial donde se sube la media*/
@media (max-width: 768px) {
  .special-modal-for-uploading-lessons-media .modal-dialog {
    width: 100% !important;
  }
}
.header-spent-money {
  justify-content: "space-between";
  align-items: center;
  padding: 16px 24px !important;
  font-size: 24px;
}

.x-modal {
  color: #e9a535 !important;
  cursor: pointer;
}

.x-modal:hover {
  color: #e9a535 !important;
}

.modal-title {
  font-size: 20px;
  font-weight: 700;
}

@media (max-width: 768px) {
  .modal-title {
    font-size: 16px;
  }
}
.content-modal-spent-money {
  padding: 0 8px !important;
}

.modal-message {
  font-size: 20px;
  margin-left: 8px;
}

@media (max-width: 768px) {
  .modal-message {
    font-size: 16px;
  }
}
.modal-reward {
  font-size: 18px;
  margin-left: 8px;
  font-weight: 500;
}

.modal-reward span {
  font-size: 18px;
  margin-left: 8px;
  background-color: #858585;
  border-radius: 8px;
  padding: 4px 8px;
  color: white;
}

.modal-submessage {
  font-size: 14px;
  margin-left: 8px;
}

@media (min-width: 768px) {
  .my-orka-nav-bar .navbar-brand.FontSize13px {
    font-size: 1rem !important;
  }
}
.my-orka-nav-bar .navbar-brand.FontSize13px .img-moderador {
  width: 1.6rem;
}

@media (min-height: 768px) {
  .my-orka-nav-bar {
    padding-top: 0.2rem;
  }
}
@media (min-height: 800px) {
  .my-orka-nav-bar {
    padding-top: 0.3rem;
  }
}
@media (min-height: 824px) {
  .my-orka-nav-bar {
    padding-top: 0.4rem;
  }
}
@media (min-height: 870px) {
  .my-orka-nav-bar {
    padding-top: 0.6rem;
  }
}
@media (min-height: 911px) {
  .my-orka-nav-bar {
    padding-top: 0.7rem;
  }
}
/* @media (min-height: 928px){
  .my-orka-nav-bar{
    padding-top: .7rem;
  }
} */
@media (min-height: 943px) {
  .my-orka-nav-bar {
    padding-top: 0.8rem;
  }
}
@media (min-height: 980px) {
  .my-orka-nav-bar {
    padding-top: 0.9rem;
  }
}
@media (min-height: 1006px) {
  .my-orka-nav-bar {
    padding-top: 1rem;
  }
}
.logo {
  font-size: 40px;
}

.navbar-icon-equality {
  margin: 0 2vw;
}

@media (max-width: 576px) {
  .logo {
    font-family: reross-quadratic !important;
    color: #64949a;
    font-size: 20px;
  }
  /*esto se agrego para quitar los espacios de los lados porque
     se de color blanco y en modo responsive tiene que ir todo el navbar de color gris
     */
  .my-orka-nav-bar {
    margin-left: 0vw !important;
    margin-right: 0vw !important;
  }
  .fontMobileNav {
    margin: 0 !important;
    padding: 0.5rem;
    font-size: 1.5vh !important;
  }
}
/*este media querie se agrego para quitar los espacios de los lados porque
se de color blanco y en modo responsive tiene que ir todo el navbar de color gris
*/
@media (max-width: 768px) {
  .logo {
    font-family: reross-quadratic !important;
    color: #64949a;
    font-size: 20px;
  }
  .my-orka-nav-bar {
    margin-left: 0vw !important;
    margin-right: 0vw !important;
  }
}
.not-found-container {
  display: flex;
  justify-content: center;
}
.not-found-container .not-found-text {
  font-size: 40px;
}

@media (max-width: 768px) {
  .not-found-container .not-found-text {
    font-size: 20px;
  }
}

.AktivGroteskEx-Medium {
  font-family: Montserrat, sans-serif;
  font-weight: 500 !important;
  font-style: normal;
}

.AktivGroteskEx-Black {
  font-family: Montserrat, sans-serif;
  font-weight: 900 !important;
  font-style: normal;
}

.AktivGroteskEx-Bold {
  font-family: Montserrat, sans-serif;
  font-weight: 700 !important;
  font-style: normal;
}

.ProximaNova-Regular {
  font-family: Roboto, sans-serif;
  font-weight: 400 !important;
  font-style: normal;
}

.ProximaNova-Bold {
  font-family: Roboto, sans-serif;
  font-weight: 700 !important;
  font-style: normal;
}

.ProximaNova-Semibold {
  font-family: Roboto, sans-serif;
  font-weight: 600 !important;
  font-style: normal;
}

.ProximaNova-Black {
  font-family: Roboto, sans-serif;
  font-weight: 900 !important;
  font-style: normal;
}

/*------- COLORES ----------*/
.BlueLink {
  color: #48676f;
}

.BlackFileName {
  color: #000;
}

.Yellow {
  color: #e9a535 !important;
}

.YellowIcon {
  color: #e9a535;
}

.LightYellow {
  color: #e9a535 !important;
  opacity: 65%;
}

.Green {
  color: #64949a;
}

.DarkGreen {
  color: #48676f;
}

.LightGreen {
  color: #8db2bc !important;
}

.LightGreen2 {
  color: #949494;
}

.White {
  color: white;
}

.DarkWhite {
  color: #f8f8f8;
}

.Gray {
  color: #949494;
}

.LowGray {
  color: #adbfc5;
}

.GrayUpload {
  color: #c0d1d5;
}

.DarkGray {
  color: #707070;
}

.LightGray {
  color: #a1a1a1;
}

.Mustard {
  color: #e9a535;
}

.GrayTitle {
  color: #858585;
}

/*------------BACKGROUND -----------4--------*/
.BackgroundGreen {
  background-color: #64949a;
}

.BackgroundGreen50Opacity {
  background-color: #7b98a0;
  opacity: 0.5;
}

.BackgroundCabina {
  background-image: "./public/imagenes/blanco.JPG";
}

.BackgroundCenter {
  background-position: center center;
}

.BackgroundDarkWhite {
  background-color: #f8f8f8;
}

.BackgroundLightGray {
  background-color: #a1a1a1;
}

.BackgroundGrayUpload {
  background-color: #c0d1d5;
}

.BackgroundYellow {
  background-color: #e9a535 !important;
}

.BackgroundWhite {
  background-color: white;
}

.BackgroundGrayRegister {
  /* background-color: #c0d1d5;
   */
   background-color: #F8F8F8;
}

.BackgroundBABABA14 {
  background-color: rgba(186, 186, 186, 0.0784313725) !important;
}

.BackgroundBlueGray {
  background-color: rgba(142, 178, 188, 0.5882352941);
}

/*------------FLEX--------------*/
.Flex {
  display: flex;
}

.FlexWrap {
  flex: wrap;
}

.FlexDirectionColumn {
  flex-direction: column;
}

.JustifyContentSpaceEvenly {
  justify-content: space-evenly !important;
}

.JustifyContentSpaceBtw {
  justify-content: space-between !important;
}

.JustifyContentCenter {
  justify-content: center;
}

.JustifyContentRight {
  justify-content: right;
}

.AlignItemsCenter {
  align-items: center;
}

/*------------FONTS-------------*/
.ProximaNova {
  font-family: Roboto;
}

.ProximaNovaBL {
  font-family: Roboto Bl;
}

/*------------FONT SIZE-----------*/
.FontSize65 {
  font-size: 65px !important;
}

.FontSize60 {
  font-size: 60px !important;
}

.FontSize50 {
  font-size: 50px !important;
}

.FontSize40 {
  font-size: 40px !important;
}

.FontSize30 {
  font-size: 30px !important;
}

.FontSize28 {
  font-size: 28px !important;
}

.FontSize25 {
  font-size: 25px !important;
}

.FontSize24 {
  font-size: 24px !important;
}

.FontSize0px {
  font-size: 0% !important;
}

.FontSize1px {
  font-size: 6.25% !important;
}

.FontSize2px {
  font-size: 12.5% !important;
}

.FontSize3px {
  font-size: 18.75% !important;
}

.FontSize4px {
  font-size: 25% !important;
}

.FontSize5px {
  font-size: 31.25% !important;
}

.FontSize6px {
  font-size: 37.5% !important;
}

.FontSize7px {
  font-size: 43.75% !important;
}

.FontSize8px {
  font-size: 50% !important;
}

.FontSize9px {
  font-size: 56.25% !important;
}

.FontSize10px {
  font-size: 62.5% !important;
}

.FontSize11px {
  font-size: 68.75% !important;
}

.FontSize12px {
  font-size: 1.82vh !important;
}

.FontSize125px {
  font-size: 70% !important;
}

.FontSize13px {
  font-size: 81.25% !important;
}

.FontSize14px {
  font-size: 87.5% !important;
}

.FontSize15px {
  font-size: 1.28vw !important;
}

.FontSize16px {
  font-size: 100% !important;
}

.FontSize17px {
  font-size: 106.25% !important;
}

.FontSize18px {
  font-size: 112.5% !important;
}

.FontSize19px {
  font-size: 118.75% !important;
}

.FontSize20px {
  font-size: 0.95vw !important;
}

.FontSize21px {
  font-size: 131.25% !important;
}

.FontSize22px {
  font-size: 137.5% !important;
}

.FontSize23px {
  font-size: 143.75% !important;
}

.FontSize24px {
  font-size: 150% !important;
}

.FontSize25px {
  font-size: 1.83vw !important;
}

.FontSize26px {
  font-size: 162.5% !important;
}

.FontSize27px {
  font-size: 168.75% !important;
}

.FontSize28px {
  font-size: 175% !important;
}

.FontSize29px {
  font-size: 181.25% !important;
}

.FontSize30px {
  font-size: 3.3vh !important;
}

.FontSize31px {
  font-size: 193.75% !important;
}

.FontSize32px {
  font-size: 200% !important;
}

.FontSize33px {
  font-size: 206.25% !important;
}

.FontSize34px {
  font-size: 212.5% !important;
}

.FontSize35px {
  font-size: 3.8051750381vh !important;
}

.FontSize36px {
  font-size: 225% !important;
}

.FontSize37px {
  font-size: 231.25% !important;
}

.FontSize38px {
  font-size: 237.5% !important;
}

.FontSize39px {
  font-size: 243.75% !important;
}

.FontSize40px {
  font-size: 250% !important;
}

.FontSize41px {
  font-size: 256.25% !important;
}

.FontSize42px {
  font-size: 262.5% !important;
}

.FontSize43px {
  font-size: 268.75% !important;
}

.FontSize44px {
  font-size: 275% !important;
}

.FontSize45px {
  font-size: 281.25% !important;
}

.FontSize46px {
  font-size: 287.5% !important;
}

.FontSize47px {
  font-size: 293.75% !important;
}

.FontSize48px {
  font-size: 300% !important;
}

.FontSize49px {
  font-size: 306.25% !important;
}

.FontSize50px {
  font-size: 312.5% !important;
}

.FontSize51px {
  font-size: 318.75% !important;
}

.FontSize52px {
  font-size: 325% !important;
}

.FontSize53px {
  font-size: 331.25% !important;
}

.FontSize54px {
  font-size: 337.5% !important;
}

.FontSize55px {
  font-size: 4.0263543192vw !important;
}

.FontSize56px {
  font-size: 350% !important;
}

.FontSize57px {
  font-size: 356.25% !important;
}

.FontSize58px {
  font-size: 362.5% !important;
}

.FontSize59px {
  font-size: 368.75% !important;
}

.FontSize60px {
  font-size: 2.7vw !important;
}

.FontSize61px {
  font-size: 381.25% !important;
}

.FontSize62px {
  font-size: 387.5% !important;
}

.FontSize63px {
  font-size: 393.75% !important;
}

.FontSize64px {
  font-size: 400% !important;
}

.FontSize65px {
  font-size: 406.25% !important;
}

.FontSize66px {
  font-size: 412.5% !important;
}

.FontSize67px {
  font-size: 418.75% !important;
}

.FontSize68px {
  font-size: 425% !important;
}

.FontSize69px {
  font-size: 431.25% !important;
}

.FontSize70px {
  font-size: 437.5% !important;
}

.FontSize71px {
  font-size: 443.75% !important;
}

.FontSize72px {
  font-size: 450% !important;
}

.FontSize73px {
  font-size: 456.25% !important;
}

.FontSize74px {
  font-size: 462.5% !important;
}

.FontSize75px {
  font-size: 468.75% !important;
}

.FontSize76px {
  font-size: 475% !important;
}

.FontSize77px {
  font-size: 481.25% !important;
}

.FontSize78px {
  font-size: 487.5% !important;
}

.FontSize79px {
  font-size: 493.75% !important;
}

.FontSize80px {
  font-size: 500% !important;
}

.FontSize81px {
  font-size: 506.25% !important;
}

.FontSize82px {
  font-size: 512.5% !important;
}

.FontSize83px {
  font-size: 518.75% !important;
}

.FontSize84px {
  font-size: 525% !important;
}

.FontSize85px {
  font-size: 531.25% !important;
}

.FontSize86px {
  font-size: 537.5% !important;
}

.FontSize87px {
  font-size: 543.75% !important;
}

.FontSize88px {
  font-size: 550% !important;
}

.FontSize89px {
  font-size: 556.25% !important;
}

.FontSize90px {
  font-size: 562.5% !important;
}

.FontSize91px {
  font-size: 568.75% !important;
}

.FontSize92px {
  font-size: 575% !important;
}

.FontSize93px {
  font-size: 581.25% !important;
}

.FontSize94px {
  font-size: 587.5% !important;
}

.FontSize95px {
  font-size: 593.75% !important;
}

.FontSize96px {
  font-size: 600% !important;
}

.FontSize97px {
  font-size: 606.25% !important;
}

.FontSize98px {
  font-size: 612.5% !important;
}

.FontSize99px {
  font-size: 618.75% !important;
}

.FontSize100px {
  font-size: 625% !important;
}

/*----------FONTWEIGHT --------*/
.FontWeightBold {
  font-weight: bold;
}

.FontWeight500 {
  font-weight: 500;
}

.FontWeight600 {
  font-weight: 600;
}

.FontWeight700 {
  font-weight: 700;
}

.FontWeight900 {
  font-weight: 900;
}

/*----------DECORATIONS--------*/
.Uppercase {
  text-transform: uppercase;
}

.Bolder {
  font-weight: bolder !important;
}

.Underline {
  text-decoration: underline !important;
}

.Bold {
  font-weight: bold;
}

.Opacity1 {
  opacity: 1 !important;
}

.Opacity5 {
  opacity: 0.5;
}

.Opacity68p {
  opacity: 68%;
}

.Opacity85p {
  opacity: 85%;
}

.Opacity46p {
  opacity: 46%;
}

.Cover {
  background-size: cover;
}

.NoRepeat {
  background-repeat: no-repeat;
}

/*---------- POSITIONS -----------*/
.Top-35 {
  top: -35px;
}

.Absolute {
  position: absolute;
}

.Relative {
  position: relative;
}

/*---------- BORDERS -----------*/
.Rounded1 {
  border-radius: 8px 8px 8px 8px;
}

.Rounded3 {
  border-radius: 20px 20px 20px 20px;
}

.Rounded4 {
  border-radius: 25px;
}

.BorderNone {
  border: none !important;
}

/*--------- PADDINGS -----------*/
.PaddingLeft5 {
  padding-left: 5%;
}

.PaddingLeft8 {
  padding-left: 8%;
}

.Padding5 {
  padding: 5%;
}

.PaddingRight5 {
  padding-right: 5%;
}

.PaddingTop5 {
  padding-top: 5%;
}

.PaddingTop10 {
  padding-top: 10%;
}

.PaddingTop24 {
  padding-top: 24%;
}

.PaddingTop28 {
  padding-top: 28%;
}

.paddingBottom2 {
  padding-bottom: 3rem;
}

.paddingBottom390 {
  padding-bottom: 3.9rem;
}

.paddingBottom350 {
  padding-bottom: 3.5rem;
}

.paddingBottom500 {
  padding-bottom: 5rem;
}

.PaddingBottom5 {
  padding-bottom: 5%;
}

.LineHeight1 {
  line-height: 1em;
}

.LineHeight1p2 {
  line-height: 1.2em;
}

/*-----Margin-------*/
.MarginAuto {
  margin: 0 auto;
}

.MarginLeft10px {
  margin-left: 10px;
}

.MarginRight10px {
  margin-right: 10px;
}

.MarginRightAuto {
  margin-right: auto;
}

/* .MarginTopNegative{margin-top: 5rem;} */
.GrayBorder {
  border: 2px solid #949494;
}

a .LightYellow:hover {
  color: #e9a535 !important;
  font-weight: bold;
  letter-spacing: 3px;
}

.Hover {
  cursor: pointer;
}

.NoClick {
  pointer-events: none;
}

/*--------Titulos--------*/
.Title {
  font-family: Roboto, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 120%;
  opacity: 68%;
  text-transform: uppercase;
}

.GreenSubtitle {
  color: #64949a;
  font-family: Roboto;
  font-size: 95%;
  opacity: 50%;
  line-height: normal;
}

.GraySubtitle {
  color: #a1a1a1;
  font-family: Roboto, sans-serif;
  font-weight: 100;
  opacity: 50%;
  line-height: normal;
}

.Pointer {
  cursor: pointer;
}

/*------BORDERS----------*/
.BorderRadius2 {
  border-radius: 2%;
}

.BorderRadius15 {
  border-radius: 15px;
}

.BorderRadius28 {
  border-radius: 28px !important;
}

.BorderRadius30 {
  border-radius: 30px;
}

.BorderRadius50 {
  border-radius: 50px;
}

.BorderNone {
  border: none;
}

/*-----------WIDTH---------*/
.Width10 {
  width: 10px !important;
  max-width: none !important;
}

.Width16 {
  width: 16px !important;
  max-width: none !important;
}

.Width70 {
  width: 70px !important;
  max-width: none !important;
}

.Width75 {
  width: 75px !important;
  max-width: none !important;
}

.width1000 {
  width: 1000px !important;
  max-width: none !important;
}

.Width400 {
  width: 400px !important;
  max-width: none !important;
}

.Width30p {
  width: 30% !important;
  max-width: none !important;
}

.Width35p {
  width: 35% !important;
  max-width: none !important;
}

.Width50p {
  width: 50% !important;
  max-width: none !important;
}

.Width55p {
  width: 55% !important;
  max-width: none !important;
}

.Width60p {
  width: 60% !important;
  max-width: none !important;
}

.Width65p {
  width: 65% !important;
  max-width: none !important;
}

.Width70p {
  width: 70% !important;
  max-width: none !important;
}

.Width75p {
  width: 75% !important;
  max-width: none !important;
}

.Width80p {
  width: 80% !important;
  max-width: none !important;
}

.Width85p {
  width: 85% !important;
  max-width: none !important;
}

.Width90p {
  width: 90% !important;
  max-width: none !important;
}

.Width95p {
  width: 95% !important;
  max-width: none !important;
}

.Width75p {
  width: 75% !important;
  max-width: none !important;
}

.Width100p {
  width: 100% !important;
  max-width: none !important;
}

/*----------Minwidth-----------*/
.MinWidth90 {
  min-width: 90% !important;
}

/*-------- height --------*/
.Height10 {
  height: 10px;
}

.Height16 {
  height: 16px;
}

.Height140 {
  height: 140px;
}

.height550 {
  height: 550px;
}

.Height250 {
  height: 250px;
}

.height550 {
  height: 550px;
}

.Height40p {
  height: 40%;
}

.Height60p {
  height: 60% !important;
}

.Height80p {
  height: 80%;
}

.Height100p {
  height: 100%;
}

.Height50vh {
  height: 50vh !important;
}

.Height60vh {
  height: 60vh !important;
}

.Height70vh {
  height: 70vh !important;
}

.Height75vh {
  height: 75vh !important;
}

.Height80vh {
  height: 80vh !important;
}

.Height85vh {
  height: 85vh !important;
}

.Height100vh {
  height: 100vh !important;
}

.HeightFitContent {
  height: fit-content;
}

.Height23 {
  height: 23rem;
}

.Height30 {
  height: 30rem;
}

.Height25r {
  height: 25rem;
}

/*-------------Left--------*/
.Left50 {
  left: 50%;
}

.Transform {
  transform: translate(-50%, 0);
}

/*-------Icons---------*/
.Icon {
  width: 24px;
}

.IconbtnPlus {
  background-image: url("./components/imagenes/plus-icon.png");
}

/*To fix menu*/
.Main {
  margin-top: 100px;
}

/*-------Input-------*/
.CountryFlags {
  width: 9% !important;
  position: relative;
  background-color: #f8f8f8 !important;
  font-size: small;
  font-family: Roboto;
  text-transform: capitalize;
  border: 1px solid #64949a;
  border-top: none !important;
  border-left: none !important;
  border-right: none !important;
  color: #64949a !important;
  border-radius: 15px !important;
  background-color: #c0d1d5 !important;
}

.PhoneInputCountrySelect {
  position: absolute;
  left: 0%;
  width: 150%;
  opacity: 0;
  border: none;
  background-color: transparent;
}

.PhoneInputInput {
  position: absolute;
  top: 0;
  left: 150%;
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 1rem;
  font-weight: 400;
  color: #adbfc5 !important;
}

.PhoneInputInput.PhoneInputInput::placeholder {
  color: #adbfc5;
}

.planeTextMobile {
  margin-bottom: 0.5rem;
  width: 35%;
}

.Lesson {
  background-color: rgba(107, 140, 149, 0.2);
  height: 45px;
  border-radius: 14px;
  padding: 5%;
  border: none;
  text-transform: uppercase;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 95%;
  font-weight: 500;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  transition: all 0.5s linear;
  border: none;
}

.Lesson:focus {
  height: 95px;
  transition-timing-function: linear;
  background-color: #e9a535;
  border: none;
}

.Lesson::placeholder {
  color: #a1a1a1;
  opacity: 76%;
}

.CourseInput {
  background-color: transparent;
  border-bottom: 1px solid #c0d1d5;
  border-left: none;
  border-right: none;
  border-top: none;
  margin-bottom: 1px;
  padding-bottom: 5px;
  width: 100%;
  resize: none;
}

.CourseInput:focus {
  outline: none;
}

.CourseInput::placeholder {
  color: #000;
  font-family: Roboto;
  font-size: 95%;
  opacity: 50%;
}

.CourseInputIncompleted::placeholder {
  color: #d10000;
  font-family: Roboto;
  font-size: 95%;
  opacity: 50%;
}

.RadioStatus {
  width: 18px;
  height: 18px;
  accent-color: #64949a;
  vertical-align: middle;
}

.RadioLabel {
  vertical-align: middle;
  color: #64949a;
  font-family: Roboto;
  font-size: 100%;
}

.TitleInput,
.CostInput {
  width: 25%;
  float: right;
  border-radius: 10px;
  border: none;
  text-align: center;
}

.TitleInput:focus,
.CostInput:focus {
  outline: none;
}

.TitleInput::placeholder {
  text-align: center;
  color: #a1a1a1;
}

.CostInput {
  background-image: url("./components/imagenes/dollar-sign-solid.svg");
  background-repeat: no-repeat;
  background-size: 10px;
  background-position: 10%;
  color: #a1a1a1;
}

/*--------Imagenes--------*/
/*--------Separator------*/
.Separator {
  /*Solo 1 borde porque si generalizamos el borde se hace de 2px*/
  border-bottom: 1px solid #a1a1a1;
}

.SeparatorExperiencesSearchBar {
  position: relative;
}

.SeparatorExperiencesSearchBar::before {
  content: "";
  position: absolute;
  width: 1px;
  height: 100%;
  background-color: #949494;
  left: -1.8rem;
}

/**********/
.classInfo {
  height: fit-content;
}

.LessonContainer {
  width: 100%;
  background-color: #e9a535;
  height: 121px;
  border-radius: 15px;
}

.LessonInfo {
  background-color: #c0d1d5;
  border-radius: 14px;
  border: none;
  padding: 3% 5%;
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: 95%;
  font-weight: 500;
  -webkit-transition: all 0.5s linear;
  -moz-transition: all 0.5s linear;
  transition: all 0.5s linear;
  border: none;
}

.LessonTitle {
  color: #a1a1a1;
  opacity: 76%;
  margin: 6px 0;
  font-size: 120%;
}

.LessonIcon {
  float: right;
  cursor: pointer;
}

.LessonDetails {
  background-color: #c0d1d5;
  height: auto;
  transition-timing-function: linear;
  border: none;
}

/*---------Buttons---------*/
.ButtonExperiencesSearchBar {
  width: 50px;
  height: 50px;
  background-color: #e9a535;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 1.5vw;
}

.ButtonSaveContainer {
  width: 100%;
}

.ButtonSave {
  border: none;
  background-color: #e9a535;
  color: white;
  font-weight: 500;
}

.ButtonSave:hover {
  border: none;
  background-color: #e9a535;
}

.ButtonSave:active {
  background-color: #e9a535;
}

/*--------------*/
.WelcomePortrait:hover {
  cursor: pointer;
}

/*  */
.box-signup-mobil {
  display: none;
}

.container-signupPc {
  /* border: 1px solid #000; */
  padding-top: 0.5rem;
  padding-bottom: 3rem;
  height: 85%;
}

.container-signupPc h3 {
  margin: 0;
}

.container-signupMobil {
  padding-top: 2rem;
  padding-bottom: 4rem;
}

.section-sign-l {
  flex: 0 0 35%;
  border-bottom-left-radius: 2rem;
  border-top-left-radius: 2rem;
  padding: 2rem;
  background-color: rgba(142, 178, 188, 0.46) !important;
}

.section-sign-l .container-signupPc .box-title-welcome-signup {
  color: #64949a;
}

.section-sign-l .box-title-welcome-signup {
  margin-bottom: 0.6rem;
}

.section-signup {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 0 0 calc(66.6% - 1rem);
  border-top-right-radius: 2rem;
  border-bottom-right-radius: 2rem;
  padding: 4rem 2rem;
}

.box-buttons-signup {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

/* .box-buttons-signup a {
  margin-right: 2rem;
}

.box-buttons-signup a:last-of-type {
  margin-right: 0;
} */
/*estilos para la pagina de login como estudiante*/
.box-arrow-back-box-balls-mobil {
  display: none;
}

.box-form {
  padding-top: 1.5rem !important;
}

.box-general-form-student .header-profile,
.box-general-form-student .paragraph-profile,
.box-general-form-student .header-signin,
.box-general-form-address-emergency-contact .header-profile,
.box-general-form-address-emergency-contact .paragraph-profile,
.box-general-form-address-emergency-contact .header-signin,
.box-general-form-create-account .header-profile,
.box-general-form-create-account .paragraph-profile,
.box-general-form-create-account .header-signin,
.box-general-form-review-personal-info .header-profile,
.box-general-form-review-personal-info .paragraph-profile,
.box-general-form-review-your-info .header-profile,
.box-general-form-review-your-info .paragraph-profile,
.box-general-form-review-your-info .header-signin {
  font-size: 1.8264840183vh !important;
  text-align: center;
}

.box-general-form-student .paragraph-profile,
.box-general-form-address-emergency-contact .paragraph-profile,
.box-general-form-create-account .paragraph-profile,
.box-general-form-review-personal-info .paragraph-profile,
.box-general-form-review-your-info .paragraph-profile {
  font-family: Roboto, Regular;
}

/*
estilos para el formulario de la pagina
de login estudiante
*/
.container-inputs {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 1rem;
  justify-items: center;
}

/* .box-general-form-review-your-info .container-inputs{
    display: grid;
    grid-template-columns: repeat(3 1fr);
    gap: 1rem;
    justify-items: center;
} */
.field {
  width: 80%;
}

.container-inputs .field:last-of-type {
  grid-column: 2/3;
  text-align: right;
}

.container-inputs-date-birthday,
.container-inputs-review-personal-info,
.container-inputs-review-emergency-contact {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
}

.container-inputs-review-personal-info,
.container-inputs-review-account-info,
.container-inputs-review-emergency-contact {
  margin: 0 auto;
  width: 90%;
}

/*estilos para el button de la pagina de
review your info*/
/* .box-general-form-review-your-info{
    width: 100%;
} */
.box-general-form-review-your-info button {
  text-align: right;
}

/*estilos de la pagina de Booknormal*/
.container-book-title .arrow-pc {
  display: none;
}

.bookNormal .bodyBook .col-item {
  flex: 0 0 calc(33.3% - 1rem);
}

.bookNormal .col-item .box3:first-of-type {
  width: 100%;
}

.bodyBook .col-item .box-check-col-item,
.book-checkout .col-item .box-check-col-item {
  display: flex;
  justify-content: space-between;
  text-align: right;
}

.box2 {
  width: 40%;
}

/*estilos de la pagina bookcheckout*/
.container-book-checkout .arrow-pc,
.container-book-25 .arrow-pc {
  display: none;
}

/*estilos para la pagina de book-25 (puse algunos estilos arriba)*/
.Book-25-general .bodyBook .col-item {
  flex: 0 0 calc(33.3% - 1rem);
}

.Book-25-general .col-item .box3Active {
  height: 100%;
  padding: 5%;
  width: 100%;
}

.Book-25-general .col-item .container {
  height: 100%;
}

.Book-25-general ul {
  padding: 0;
}

.Book-25-general ul li {
  list-style: disc;
}

.box-price {
  height: auto;
  width: 70%;
}

.Book-25-general .row-price-course > * {
  flex: 0 0 33.3%;
  flex-wrap: nowrap;
  align-items: center;
  padding: 0;
}

.Book-25-general .row-price-course .form-check-inline {
  padding-left: 0;
  margin-right: 0;
}

.Book-25-general .row-price-course p {
  font-size: 1.5rem;
  margin-bottom: 0;
}

.Book-25-general .arrow-mobil {
  display: none;
}

.d-flex.flex-column.align-items-center > .RedondeoYBackgroundDeTextboxV2 {
  border-radius: 13px !important;
  background-color: #c0d1d5;
  opacity: 0.5;
  margin: 10px 0;
}

.textForgotPassword {
  color: #949494 !important;
  font-size: 16px !important;
  margin-top: 12px;
  text-align: end;
}

.box-general-login .box-login-guest .box-paragraph-box-login-guest {
  text-align: center;
}

.box-general-login .box-login-guest .box-paragraph-box-login-guest .paragraph-login-guest-lines {
  font-size: 1.8264840183vh !important;
  position: relative;
}

.box-general-login .box-login-guest .box-paragraph-box-login-guest .paragraph-login-guest-lines::before,
.box-general-login .box-login-guest .box-paragraph-box-login-guest .paragraph-login-guest-lines::after {
  content: "";
  position: absolute;
  top: 0.8rem;
  height: 2px;
  background: #c0d1d5;
  width: 25%;
}

.box-general-login .box-login-guest .box-paragraph-box-login-guest .paragraph-login-guest-lines::before {
  right: 80%;
}

.box-general-login .box-login-guest .box-paragraph-box-login-guest .paragraph-login-guest-lines::after {
  left: 80%;
}

/*ajustes al modal de login*/
/* .modal-login-forgot-pass .modal-header{
    justify-content: flex-end!important;
} */
.modal-login-forgot-pass .modal-content {
  border-radius: 28px !important;
}

.modal-login-forgot-pass .modal-text {
  margin-bottom: 1rem;
}

.modal-login-forgot-pass .field-modal-login {
  border-radius: 13px !important;
}

.modal-login-forgot-pass .botonGrisAmarillo .btn {
  background-color: #a1a1a1 !important;
  font-family: Roboto, Bold !important;
}

.modal-login-forgot-pass .btn-account-recovery-next {
  border-radius: 1.9786910198vh;
  background: #a1a1a1 0% 0% no-repeat padding-box;
}

.recovery-modal .modal-content {
  border-radius: 1.1rem !important;
  margin: 0 auto !important;
  width: 100% !important;
}

.recovery-modal .modal-dialog {
  margin: 0 auto !important;
  width: 85% !important;
}

.recovery-modal .img-recovery-modal {
  display: inline-block;
}

.box-general-login .box-login-guest .box-paragraph-box-login-guest span {
  font-weight: normal;
}

.box-general-login .boxHelpLogin .helpBox {
  margin-left: 6.6rem;
}

/*ajustes a sign up*/
.box-signup-pc .container {
  display: flex;
  padding-left: unset !important;
  padding-right: unset !important;
  width: 85%;
}

.box-signup-pc .section-signup h3 {
  color: #949494 !important;
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
  font-size: 3.8051750381vh !important;
  margin-bottom: 2.1rem;
}

.box-signup-pc .first-paragraph {
  font-family: Roboto, Regular;
  font-size: 1.8264840183vh;
}

.box-signup-pc .second-paragraph {
  font-family: Roboto, Regular;
  font-size: 1.8264840183vh;
  margin-top: 2.8rem !important;
}

.box-general-signup .help {
  color: #64949a;
  /* font-size: 1.82648401826484vh; // 12px */
}

.box-signup-pc .helpBox {
  margin-left: 7.3rem;
}

.box-signup-pc .title-welcome-signup {
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
  font-size: 3.8051750381vh !important;
}

.box-signup-pc .text-welcome-signup {
  font-family: Roboto, Regular;
  font-size: 1.8264840183vh;
}

/*estilos boton estudieante*/
.box-signup-pc #BotonRegistroEstudiantes,
.box-signup-pc #BotonRegistroInstructor {
  border-radius: 1.9786910198vh;
  height: 6.697108067vh;
  font-family: Roboto, Semibold;
  font-size: 1.8264840183vh;
  padding-left: unset;
  padding-right: unset;
  text-align: center;
  width: 37.899543379vh;
}

.box-helpImgPc {
  display: flex;
  gap: 0.5rem;
}

.box-helpImgPc .help-text {
  margin-top: -2px;
}

/*ajustes a primero pagina de basic info*/
.box-general-form-student .header-signin {
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
  font-size: 3.8051750381vh !important;
}

.box-general-form-student .boxHelpSignInOne .helpBox {
  margin-left: 7.3rem;
}

.paragraph-profile {
  margin-bottom: 3.5rem;
}

.box-balls-pc {
  border-radius: 35px 0 0 35px;
  background-color: rgba(142, 178, 188, 0.46) !important;
}

.box-student-profile {
  border-radius: 0 35px 35px 0;
}

.container-signInOne {
  height: 85% !important;
}

/*ajustes a pagina de BasicInfoRegistration*/
.box-general-form-student .header-profile {
  font-size: 3.8051750381vh !important;
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
  margin-top: -1rem;
  margin-bottom: 0.9rem;
}

.box-general-form-student .field .form-control,
.box-general-form-student .field .form-select {
  font-size: 1.8264840183vh;
  font-family: Roboto, sans-serif !important;
  font-weight: 400;
}

/* .box-general-form-student .field #select-gender{

    -webkit-appearance: none!important;
    -moz-appearance: none!important;
    appearance:none!important;

} */
/* .box-general-form-student .field .box-select-input select{
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance:none;
} */
.box-general-form-student .field .input-tel {
  border: none;
  background-color: transparent;
  color: #adbfc5;
  font-size: 1.8264840183vh;
  width: 100%;
}

.box-student-profile .boxMust {
  font-family: Roboto, Regular;
  font-size: 1.2176560122vh;
  margin-left: 5%;
  padding-bottom: 1.2rem;
  width: 40%;
}

.container-signInOne .modal-dialog .modal-terms-conditions {
  margin: 0px auto !important;
  width: 95% !important;
}

.container-signInOne .modal-content .modal-terms-conditions {
  width: 87% !important;
  margin: 0 auto !important;
}

.boxHelpSignInOne {
  color: #64949a;
}

.boxHelpSignInOne .help {
  left: 2rem;
  bottom: -1.5rem;
  /* font-size:1.82648401826484vh!important; */
}

.icon-arrow {
  display: none;
}

/*ajustes a la pagina de emergencyContactStudent*/
.box-general-form-address-emergency-contact .header-signin {
  /* font-size: Aktiv Grotesk Ex, Black!important; */
  font-size: 3.8051750381vh !important;
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
}

.box-general-form-address-emergency-contact .header-profile {
  font-size: 3.8051750381vh !important;
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
  margin-bottom: 0.9rem;
}

.box-general-form-address-emergency-contact .field .form-control,
.box-general-form-address-emergency-contact .field .form-select {
  font-size: 1.8264840183vh;
  font-family: Roboto, sans-serif !important;
  font-weight: 400;
}

.box-general-form-address-emergency-contact .last-field {
  margin-top: 1.5rem;
}

.boxHelpSignInTwo {
  color: #64949a;
  /* font-size:1.82648401826484vh!important; */
}

.box-general-form-address-emergency-contact .boxHelpSignInTwo .helpBox {
  margin-left: 6.4rem;
}

.box-general-form-address-emergency-contact .boxMust {
  font-family: Roboto, Regular;
  font-size: 1.2176560122vh;
}

.box-emergency-contact {
  border-radius: 0 35px 35px 0;
}

.box-emergency-contact .boxMust {
  margin-left: 6.5rem;
  padding-bottom: 1.2rem;
  width: 40%;
}

.wrapper-container-inputs-emergency-contact-one {
  border-bottom: 2px solid #c0d1d5;
  padding-bottom: 2.5rem;
  margin: 0 auto 2.5rem auto;
  width: 85%;
}

.wrapper-container-inputs-emergency-contact-two {
  margin: 0 auto 2.5rem auto;
  width: 85%;
}

/*ajustes de pagina createAccount*/
.box-sign-create-account,
.box-create-account {
  height: 31rem;
}

.box-create-account {
  border-radius: 0 35px 35px 0;
}

.box-general-form-create-account .header-signin {
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
  font-size: 3.8051750381vh !important;
}

.box-general-form-create-account .header-profile {
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
  color: #949494 !important;
  font-size: 3.8051750381vh !important;
  margin-bottom: 0.9rem;
}

.box-general-form-create-account .field .form-control,
.box-general-form-create-account .field .form-select {
  font-size: 1.8264840183vh;
  font-family: Roboto, sans-serif !important;
  font-weight: 400;
}

.wrapper-container-inputs-create-account {
  margin: 0 auto 2.5rem auto;
  width: 90%;
}

.box-general-form-create-account .wrapper-container-inputs-create-account .suggestion {
  font-size: 1.8264840183vh;
}

.box-general-form-student .suggestion {
  font-size: 0.625rem;
  margin-bottom: 0;
}

.box-create-account .container-inputs .field:nth-child(3) {
  grid-row: 2/3;
  grid-column: 2/3;
}

.box-general-form-create-account .box-create-account .field-button-create-account {
  margin-top: 2rem;
}

.box-create-account .boxMust {
  color: #949494;
  opacity: 0.5;
  font-family: Roboto, Regular;
  font-size: 1.2176560122vh;
  margin-top: -0.8rem;
  margin-left: 5.5rem;
  width: 40%;
}

.box-general-form-create-account .padding-help-create-account {
  padding-bottom: 4.5rem;
}

.boxHelpSignInThree {
  color: #64949a;
  /* font-size:1.82648401826484vh; */
}

.boxHelpSignInThree .help {
  left: 4.3rem;
  bottom: -2rem;
}

.box-general-form-create-account .boxHelpSignInThree .helpBox {
  margin-left: 6.4rem;
}

/*ajustes a la pagina de create account student*/
.box-review-your-info {
  border-radius: 0 35px 35px 0;
}

.box-general-form-review-your-info .container-inputs-review-personal-info-date {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin: 0 auto;
  width: 100%;
}

.box-general-form-review-your-info .container-inputs-review-personal-info .field {
  width: 95%;
}

.box-general-form-review-your-info .header-signin {
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
  font-size: 3.8051750381vh !important;
}

.box-general-form-review-your-info .header-profile-title {
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
  font-size: 3.8051750381vh !important;
  text-align: center;
}

.box-general-form-review-your-info .header-profile {
  display: inline-block;
  color: #64949a !important;
  text-align: left;
  font-family: Roboto, Bold !important;
  font-size: 1.8264840183vh !important;
  margin-left: 2.6rem;
  margin-bottom: 1rem;
}

/*estilos para el pencil del reviewInfoStudent*/
.box-general-form-review-your-info .pencil-icon {
  color: #64949a;
  margin-left: 1rem;
  font-size: 1.5220700152vh !important;
}

.box-general-form-review-your-info .container-inputs-review-personal-info label,
.box-general-form-review-your-info .container-inputs-review-account-info label,
.box-general-form-review-your-info .container-inputs-review-emergency-contact label {
  color: #949494 !important;
  opacity: 0.5;
  font-family: Roboto, Regular;
  font-size: 1.8264840183vh !important;
}

.box-general-form-review-your-info .container-inputs-review-account-info {
  display: flex;
}

.box-general-form-review-your-info .container-inputs-review-account-info .field {
  flex-basis: 31%;
  margin-right: 1rem;
}

.box-general-form-review-your-info .container-inputs-review-account-info .field:last-of-type {
  margin-right: unset;
  position: relative;
}

.box-general-form-review-your-info .container-inputs-review-account-info .field:last-of-type .eyePassword {
  color: #949494;
  position: absolute;
  right: 0.5rem;
  bottom: 0.3rem;
}

.box-general-form-review-your-info .container-inputs-review-personal-info input,
.box-general-form-review-your-info .container-inputs-review-account-info input,
.box-general-form-review-your-info .container-inputs-review-emergency-contact input {
  background-color: transparent !important;
  border: none;
  border-bottom: 1px solid #949494;
  border-radius: unset !important;
  color: #949494;
  font-size: 2.4353120244vh !important;
}

/*estilos solo para el campo de password*/
.box-general-form-review-your-info .container-inputs-review-account-info #password {
  color: #a1a1a1 !important;
  font-size: 1.5220700152vh !important;
  padding: 12px 14px 9px 14px;
}

.box-general-form-review-your-info .container-inputs-review-emergency-contact .field {
  width: 96%;
}

.box-general-form-review-your-info .container-inputs-review-personal-info .TextboxGeneral,
.box-general-form-review-your-info .container-inputs-review-account-info .TextboxGeneral,
.box-general-form-review-your-info .container-inputs-review-emergency-contact .TextboxGeneral {
  font-family: Roboto, Regular;
  border-bottom: 1px solid #949494 !important;
}

.boxHelpSignInFour {
  color: #64949a;
}

.boxHelpSignInFour .help {
  left: 4.3rem;
  bottom: -1rem;
}

.box-general-form-review-your-info .boxHelpSignInFour .helpBox {
  margin-left: 6.4rem;
}

/*ajustes a la pagina de login*/
.box-general-login .box-do-not-have-account-register-here #DarkGrayTxt {
  font-weight: bold;
  color: #949494;
  font-size: 16px;
}

.Login-title {
  font-family: Montserrat, sans-serif !important;
  font-weight: 900;
  font-size: 32px !important;
}

.box-general-login input[type=email] {
  font-size: 20px;
}

.box-general-login input[type=password] {
  font-size: 20px;
}

.box-general-login .box-inputs-email-password-login-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* d-flex flex-column justify-content-between align-items-center */
.box-top-general-login .container-fluid {
  width: 93% !important;
}

.box-general-login .box-login-guest .box-paragraph-box-login-guest .span-login-guest-lines {
  display: none;
}

.box-top-general-login .modal-body .Grismenosclaro {
  font-size: 4.5662100457vh !important;
}

.box-general-login .box-do-not-have-account-register-here {
  margin-top: 0.8rem;
  text-align: center;
}

/*ajustes a la pagina de basciInfo student profile*/
/*modificacion del ancho del container fluid*/
.box-general-form-student .container-fluid {
  width: 95% !important;
}

/*ajustes a la pagina de EmergencyContactStudent*/
.box-general-form-address-emergency-contact .container-fluid {
  width: 95% !important;
}

.box-general-form-address-emergency-contact .help {
  bottom: -1rem;
  left: 4.3rem;
}

/*ajustes a la pagina de createAccount (escribir usuario y contraseña)*/
.box-general-form-create-account .container-fluid {
  width: 95% !important;
}

/*ajustes a la pagina de CreateAccountStudent (review)*/
.box-general-form-review-your-info .container-fluid {
  width: 95% !important;
}

/*ajuste al width del modal especial donde se sube la media*/
.special-modal-for-uploading-lessons-media .modal-dialog {
  width: 70% !important;
}

.special-modal-for-uploading-lessons-media .accordion-header {
  padding: 0.5rem !important;
}

.special-modal-for-uploading-lessons-media .accordion-header button {
  padding: 1rem !important;
}

/*estilos al modal de newCourseOnline dondese sube la multimedia de los cursos*/
.modal-course-files .modal-dialog {
  width: 75% !important;
}
.view-media-modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.view-media-modal-container .transform-wrapper {
  height: 100%;
  width: 100%;
}
.view-media-modal-container .image-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.view-media-modal-container .video-container {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.view-media-modal-container .image {
  max-width: 100%;
  max-height: 100%;
}
.view-media-modal-container .change-media-button {
  font-size: 32px;
  position: absolute;
  z-index: 1;
}
.view-media-modal-container .change-media-button-left {
  left: 0px;
}
.view-media-modal-container .change-media-button-right {
  right: 0px;
}
.view-media-modal-container .change-media-button-close-button {
  right: 0px;
  top: 0px;
}

@media (max-width: 768px) {
  .view-media-modal-container .change-media-button {
    font-size: 24px;
  }
}

.svg-logo {
	width: 200px;
}

@media (max-width: 768px) {
	.svg-logo {
		width: 120px;
	}
}

@media (max-width: 1024px) {
  .inside-boxcadaCurso {
    display: block;
    width: 100%;
    padding: 10px;
    flex-direction: column;
  }
  .box-carousel {
    display: block;
    align-items: center;
    margin-left: 0 !important;
    width: 100%;
    margin-top: 20px;
  }
}
@media (max-width: 576px) {
  .container-experiences-favorite-cources .container {
    margin: 0 auto;
    max-width: unset !important;
    width: 100%;
  }
  .cadaCurso {
    display: block;
    align-items: center;
    justify-content: center;
    padding: 1px;
  }

  /* .cadaCurso-item {
    width: 100%;
  } */

  .inside-boxcadaCurso {
    width: 100%;
    padding: 10px;
    flex-direction: column;
  }

  .box-carousel {
    display: block;
    align-items: center;
    margin-left: 0 !important;
    width: 100%;
    margin-top: 20px;
  }
}

.phamHomeCourse {
  color: #adbfc5;
}