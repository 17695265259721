@import "./size";

.normal-text {
	font-size: 16px !important;
	word-break: break-word;
	@media (max-width: $breakpoint-md) {
		font-size: 12px !important;
	}
}

.bold {
	font-weight: 600 !important;
	font-family: unset !important;
}