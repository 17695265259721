@import '../../../styles/colors';
@import '../../../styles/fonts';
@import '../../../styles/size';

.home-course-item-container {
	// margin-top: 30px;
  padding-top: 30px;

	.course-title {
		font-family: $Montserrat;
		font-weight: 900;
		font-size: 30px;
	}

	.course-description {
		color: $gray-green;
	}

	.learn-more {
		color: $yellow;
		text-decoration: underline !important;
	}

	.course-box {
		padding: 20px;
		background: $sub-background-color;
		border-radius: 20px;
	}

	.course-box-description {
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
	}

	.course-media {
		object-fit: cover;
		aspect-ratio: 1;
		width: 100%;
	}

	.what-is-included {
		font-size: 32px;
		line-height: 32px;
	}

	.price {
		font-size: 48px;
		color: $main-text-color;
		font-weight: 900;
		line-height: 48px;
	}

	.hour {
		font-size: 18px;
		font-weight: 400;
	}

	.what-is-included-list {
		list-style: disc;
		text-align: left;
	}
	  
	.what-is-included-list li {
		width: max-content;
		font-size: 12px;
		line-height: 24px;
	}
	
	.what-is-included-list li::marker {
		color: $main-text-color;
		font-size: 24px;
		line-height: 24px;
	}
}

@media (max-width: $breakpoint-md) {
	.home-course-item-container {
		.course-title {
			font-size: 22px;
		}

		.what-is-included {
			font-size: 24px;
			line-height: 24px;
		}

		.price {
			font-size: 36px;
			line-height: 36px;
		}

		.hour {
			font-size: 14px;
		}
	}
}