@import '../../../styles/colors';
@import '../../../styles/size';
@import '../../../styles/fonts';

.login-container {
	background: $sub-background-color;
	padding: 110px 5% 0 5%;
	min-height: 100vh;

	.login-form {
		padding-top: 20%;
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: center;
		width: 100%;
		gap: 16px;
	}

	.login-text {
		font-family: $Montserrat;
	  font-weight: 900;
	  font-size: 32px;
		color: $light-gray;
	}

	.login-button {
		width: 150px;
	}

	.forgot-password-text {
		color: $light-gray;
		align-self: flex-end;
		margin-top: -8px;
		cursor: pointer;
	}

	.sign-up-text {
		color: $light-gray;
	}

	.sign-up-button {
		color: $dark-gray;
		font-weight: 600;
	}
}

.modal-login-forgot-password {
	.MuiDialog-paper {
		width: 50%;
		margin: 0;
	}

	.MuiDialogContent-root {
		padding: 16px;
	}

	.dialog-header {
		display: flex;
		justify-content: flex-end;
	}

	.close-icon {
		color: $yellow;
	}

	.forgot-password-form {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}

	.account-recovery {
		color: $gray-blue;
		font-size: 24px;
		font-weight: 600;
		text-align: center;
	}

	.enter-email-text {
		color: $light-gray;
		text-align: center;
	}

	.submit-email-recovery-button {
		align-self: center;
		width: 150px;
	}
}

@media (max-width: $breakpoint-md) {
	.login-container {
		padding: 60px 5% 80px 5%;
	}

	.modal-login-forgot-password {
		.MuiDialog-paper {
			width: 90%;
		}
	
		.MuiDialogContent-root {
			padding: 16px;
		}

		.forgot-password-form {
			display: flex;
			flex-direction: column;
			gap: 12px;
		}

		.account-recovery {
			font-size: 18px;
		}

		.submit-email-recovery-button {
			align-self: center;
			width: 100px;
		}
	}
}