@import '../../../../styles/colors';
@import '../../../../styles/fonts';
@import '../../../../styles/size';

.my-course-information-container {
	padding-bottom: 30px;
	background-color: $white;

	.course-aviation-story {
		margin-left: 5vw;
		margin-right: 2.5vw;

		.course-aviation-story-subtitle {
			color: $green;
		}

		.media-container {
			position: relative;
			height: 200px;
			border-radius: 8px;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $sub-background-color;
			border: $gray-blue solid 1px;
			margin-top: 8px;
			margin-right: 8px;
		}

		.remove-button {
			position: absolute;
			right: 0px;
			top: 0px;
		}

		.MuiCircularProgress-svg {
			width: 60px;
			height: 60px;
			transform: translate(-10px, -10px);
		}

		.MuiCircularProgress-circle {
			color: $gray-blue;
			width: 60px;
			height: 60px;
		}

		.media {
			height: 198px;
			width: 100%;
			object-fit: cover;
			border-radius: 8px;
			user-select: none;
			-drag: none;
			-moz-user-select: none;
			-webkit-user-drag: none;
			-webkit-user-select: none;
		}
	}

	.course-basic-info-container {
		margin-left: 5vw;
		margin-right: 2.5vw;

		.input-normal-label {
			color: $main-text-color;
			font-size: 18px;
			font-weight: 600;
			margin-top: 15px;
		}

		.input-description {
			color: $light-gray;
		}

		.input-field {
			color: #8DA6AD !important;
			font-weight: 600;
		}

		.input-field-inner {
			&::-webkit-input-placeholder {
				color: $placeholder;
				font-weight: normal;
			}

			&::-moz-placeholder {
				color: $placeholder;
				font-weight: normal;
			}

			&::-ms-placeholder {
				color: $placeholder;
				font-weight: normal;
			}

			&::placeholder {
				color: $placeholder;
				font-weight: normal;
			}
		}

		.MuiInput-root,
		.MuiInput-input {
			padding: 0;
		}

		.note {
			font-size: 14px;
			color: $placeholder;
			margin-top: 15px;
		}

		.update-button-container {
			margin-top: 30px;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: center;
			gap: 30px;
		}

		.update-button {
			width: 100px;
		}

		.what-is-included-item-container {
			display: flex;
			flex-direction: row;
			align-items: center;
			color: $light-gray;
			margin-top: 4px;
		}

		.what-is-included-item-left {
			flex: 1;
		}

		.what-is-included-item-right {
			width: 60px;
			display: flex;
			justify-content: flex-end;
			color: $main-text-color;
		}

		.MuiCheckbox-root {
			padding: 0px;
			color: $main-text-color;
		}
	}

	.course-lessons-container {
		margin-left: 2.5vw;
		margin-right: 5vw;

		.add-lesson-button {
			position: fixed !important;
			bottom: 16px;
			right: 16px;
			background-color: $main-text-color;

			&:hover {
				background-color: $main-text-color;
			}
		}

		.update-lessons-note {
			color: $red;
			font-size: 14px;
		}

		.no-lessons {
			color: $light-gray;
			text-align: center;
			padding: 10px 0;
		}

		.create-lesson-container {
			margin-top: 10px;
			padding: 20px;
			border-radius: 16px;
			background-color: $sub-background-color;
		}

		.lesson-title {
			flex: 1;
			word-break: break-word;
		}

		.lesson-input-title {
			color: $light-gray;
		}

		.lesson-input-description {
			font-size: 14px;
			color: $light-gray;
		}

		.lesson-input-field {
			background-color: $white;
			padding: 8px !important;
			border-radius: 8px;
		}

		.MuiInput-root,
		.MuiInput-input {
			padding: 0;
		}

		.lesson-buttons-container {
			margin-top: 10px;
			display: flex;
			justify-content: center;
			gap: 30px
		}

		.lesson-button {
			width: 100px;
		}

		.course-lesson-item-container {
			border-radius: 8px;
			margin-top: 16px;

			.MuiAccordion-root {
				background-color: $sub-background-color;
			}

			.MuiAccordionSummary-root,
			.MuiAccordionSummary-root.Mui-expanded {
				min-height: unset;
				padding-top: 12px;
				padding-bottom: 12px;
			}

			.MuiAccordionSummary-content,
			.MuiAccordionSummary-content.Mui-expanded {
				margin: 0px;
			}

			.MuiAccordionDetails-root {
				padding: 12px 16px !important;
			}
		}

		.helper-document-container {
			background-color: $white;
			padding: 10px 20px;
			border-radius: 8px;
			display: flex;
			margin-top: 8px;
			align-items: center;
		}

		.file-name {
			flex: 1;
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;

			&:hover {
				text-decoration: underline;
				cursor: pointer;
			}
		}

		.alert-icon {
			color: $yellow;

			&:hover {
				background-color: unset;
			}
		}

		.upload-film-container {
			background-color: $white;
			border-radius: 8px;
			margin-top: 8px;
			padding: 10px 20px;
		}

		.film-container {
			margin-top: 8px;
			position: relative;
		}
		
		.film {
			width: 100%;
		}

		.film-remove-button {
			position: absolute;
			top: 0px;
			right: 0px;
		}
	}

	.course-danger-zone-container {
		margin-left: 5vw;
		margin-right: 2.5vw;

		.course-visibility-item {
			display: flex;
		}

		.course-visibility-item-left {
			flex: 1;
		}

		.course-visibility-item-right {
			width: 60px;
			display: flex;
			justify-content: flex-end;
			align-items: center;
		}

		.course-visibility-item-title {
			color: $main-text-color;
			font-size: 18px;
			font-weight: 600;
		}

		.course-visibility-item-description {
			color: $light-gray;
		}

		.MuiCheckbox-root {
			padding: 0px;
			color: $main-text-color;
		}
	}

	.input-yellow-label {
		color: $yellow;
		font-size: 18px;
		font-weight: 600;
		margin-top: 15px;
	}
}

@media (max-width: $breakpoint-md) {
	.my-course-information-container {
		padding-bottom: 50px;

		.course-aviation-story {
			margin-left: 5%;
			margin-right: 5%;

			.media-container {
				height: 150px;
				margin-top: 6px;
				margin-right: 6px;
			}

			.MuiCircularProgress-svg {
				width: 40px;
				height: 40px;
				transform: unset;
			}

			.MuiCircularProgress-circle {
				width: 40px;
				height: 40px;
			}

			.media {
				height: 148px;
			}
		}

		.course-basic-info-container {
			margin-left: 5%;
			margin-right: 5%;

			.input-normal-label {
				font-size: 14px;
			}

			.input-field {
				font-size: 12px;
			}

			.note {
				font-size: 12px;
			}

			.update-button-container {
				margin-top: 20px;
				gap: 20px;
			}
	
			.what-is-included-item-right {
				width: 40px;
			}
		}

		.course-lessons-container {
			margin-left: 5%;
			margin-right: 5%;

			.add-lesson-button {
				bottom: 8px;
				right: 8px;
				width: 40px;
				height: 40px;
			}

			.update-lessons-note {
				font-size: 10px;
			}

			.lesson-input-description {
				font-size: 10px;
			}

			.lesson-buttons-container {
				gap: 20px;
			}
			
			.course-lesson-item-container {
				.MuiAccordionSummary-root,
				.MuiAccordionSummary-root.Mui-expanded {
					padding-top: 8px;
					padding-bottom: 8px;
				}
			}
		}

		.course-danger-zone-container {
			margin-left: 5%;
			margin-right: 2%;

			.course-visibility-item-title {
				font-size: 14px;
			}
		}

		.input-yellow-label {
			font-size: 14px;
		}
	}
}

@media (min-width: $breakpoint-xl) {
	.my-course-information-container {
		margin: auto;
		width: 80%;
	}
}
