$background-color: #FFFFFF;
$sub-background-color: #F8F8F8;
$sub-text-color: rgba(72, 103, 111, 0.68);
$main-text-color: #8EB2BC;
$yellow: #F7C26D;
$white: #FFFFFF;
$green: #6B8C95;
$gray-blue: #64949A;
$light-gray: #A1A1A1;
$red: #FF0000;
$background-color-green: rgba(142, 178, 188, 0.61);
$placeholder: #989898;
$black: #000000;
$gray-green: #ADBFC5;
$dark-gray: #949494;