@import '../../../../styles/colors';
@import '../../../../styles/size';
@import '../../../../styles/fonts';

.my-orka-instructor-content-container {
	background: $sub-background-color;
	padding: 110px 5% 80px 5%;
	min-height: 100vh;

	.my-orka-left-column {
		position: fixed;
		width: calc(90vw * 0.24);
	}
	
	.hi-there {
		font-size: 18px;
		font-family: $Roboto;
		font-weight: 600;
		color: $sub-text-color;
	}
	
	.user-name {
		font-size: 48px;
		font-family: $Roboto;
		font-weight: 700;
		color: $main-text-color;
	}
	
	.greeting {
		font-size: 24px;
		font-family: $Roboto;
		font-weight: 500;
		color: $sub-text-color;
	}
	
	.my-orka-menu-button {
		background-color: transparent;
		border-top: 1px solid $main-text-color;
		border-bottom: 1px solid $main-text-color;
		width: 100%;
		padding: 16px 0;
		cursor: pointer;
	}
	
	.selected {
		color: #f6aa3a;
	}
	
	.not-selected {
		color: #64949a;
	}

	.course-item {
		background-color: #6B8C951A !important;
		box-shadow: unset !important;
		position: unset !important;
		border-radius: 16px !important;
		margin-top: 16px !important;
	
		.course-title {
			color: #6b8c95;
			font-size: 1.1rem;
		}
	
		.course-information-container {
			display: flex;
			gap: 24px;
		}
	
		.course-information {
			font-family: $Roboto;
			color: $gray-blue;
		}
	
		.view-syllabus-button {
			font-family: $Roboto;
			color: $gray-blue;
			font-weight: 600;
			text-decoration: underline !important;
			cursor: pointer;
		}
		
		.course-item-summary.MuiAccordionSummary-root,
		.course-item-summary.MuiAccordionSummary-root.Mui-expanded {
			min-height: unset !important;
			padding-top: 8px !important;
			padding-bottom: 8px !important;
			padding-left: 2rem !important;
			padding-right: 2rem !important;
		}
	
		.MuiAccordionSummary-content,
		.MuiAccordionSummary-content.Mui-expanded {
			margin: 0px;
		}
	
		.MuiAccordionDetails-root {
			padding-left: 2rem !important;
			padding-right: 2rem !important;
		}
	}

	.schedule {
		background-color: $white;
		padding: 1.5rem 3rem 2rem 3rem;
		border-radius: 18px;

		.reschedule-text {
			color: $main-text-color;
			text-decoration: underline !important;
		}
	}
}

@media (max-width: $breakpoint-lg) {
	.my-orka-instructor-content-container {
		.my-orka-left-column {
			position: relative;
			width: 100%;
		}

		.my-orka-right-column {
			margin-top: 32px;
		}
	}
}

@media (max-width: $breakpoint-md) {
	.my-orka-instructor-content-container {
		padding: 60px 5% 80px 5%;

		.my-orka-left-column {
			position: relative;
			width: 100%;
		}

		.hi-there {
			font-size: 14px;
		}

		.user-name {
			font-size: 36px;
		}

		.greeting {
			font-size: 18px;
		}

		.my-orka-menu-button {
			padding: 12px 0;
		}

		.course-item {
			.course-title {
				font-size: 14px;
			}
	
			.course-information {
				font-size: 12px;
			}
	
			.view-syllabus-button {
				font-size: 12px;
			}
	
			.course-item-summary.MuiAccordionSummary-root,
			.course-item-summary.MuiAccordionSummary-root.Mui-expanded {
				padding-top: 6px !important;
				padding-bottom: 6px !important;
				padding-left: 1.5rem !important;
				padding-right: 1.5rem !important;
			}
	
			.MuiAccordionDetails-root {
				padding-left: 1.5rem !important;
				padding-right: 1.5rem !important;
			}
		}
	}
}