@import '../../../styles/colors';
@import '../../../styles/size';
@import '../../../styles/fonts';

.reset-password-container {
	background: $sub-background-color;
	padding: 110px 5% 0 5%;
	min-height: 100vh;

	.reset-password-form {
		padding-top: 20%;
		display: flex;
		flex: 1;
		flex-direction: column;
		align-items: center;
		width: 100%;
		gap: 16px;
	}

	.reset-password-text {
		font-family: $Montserrat;
	  font-weight: 900;
	  font-size: 32px;
		color: $light-gray;
	}

	.reset-password-button {
		width: 150px;
	}
}

@media (max-width: $breakpoint-md) {
	.login-container {
		padding: 60px 5% 80px 5%;
	}
}