@import './size';
@import './colors';

.drop-zone {
	padding: 20px;
	margin-top: 8px;
	border: 2px dashed #EEEEEE;
	border-radius: 2px;
	background-color: $white;
	color: #BDBDBD;
	outline: none;
	cursor: pointer;
	@media (max-width: $breakpoint-md) {
		padding: 10px;
	}
}