@import '../../../styles/colors';
@import '../../../styles/size';
@import '../../../styles/fonts';

.create-account-container {
	background: $sub-background-color;
	padding: calc(max((100vh - 600px) / 2, 110px)) 15%;
	min-height: 100vh;

	.create-account-content-container {
		background-color: $white;
		border-radius: 32px;
		height: 600px;
	}

	.create-account-box {
		height: 600px;
	}

	.left-column {
		background-color: $background-color-green;
		border-radius: 32px 0 0 32px;
		display: flex;
		padding: 20px;
		flex-direction: column;
		align-items: center;
		justify-content: center;

		.sign-up-text {
			text-align: center;
			color: $gray-blue;
			font-size: 32px;
			font-weight: 600;
		}
	}
	
	.right-column {
		padding: 20px;
		border-radius: 0 32px 32px 0;

		.sign-up-form {
			padding: 0 48px;
		}

		.back-icon {
			color: $gray-blue;
		}

		.header-profile-text {
			color: $gray-blue;
			font-size: 24px;
			font-weight: 600;
			text-align: center;
		}

		.create-account-description-text {
			color: $light-gray;
			text-align: center;
			padding: 0 8px;
		}

		.create-account-button {
			background-color: $gray-blue;
			text-transform: none;
			width: 150px;
		}

		.note {
			margin-top: 16px;
			color: $light-gray;
			padding: 0 48px;
		}
	}
}

@media (max-width: $breakpoint-lg) {
	.create-account-container {
		padding: 80px 5% 80px 5%;
	}
}

@media (max-width: $breakpoint-md) {
	.create-account-container {
		padding: 60px 5% 80px 5%;

		.create-account-content-container {
			background-color: $sub-background-color;
			border-radius: unset;
		}

		.right-column {
			padding: unset;

			.sign-up-form {
				padding: 0 8px;
			}

			.header-profile-text {
				font-size: 24px;
			}

			.create-account-button {
				width: 100px;
			}

			.note {
				padding: 0 8px;
			}
		}
	}
}